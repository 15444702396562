import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'navbar',
    template: require('./navbar.html'),
    styles: [require('./navbar.scss')]
})
export class NavbarComponent {
    isCollapsed = true;
    menu = [];
    homeMenu = [];
    
    adminMenu = [
    //     {
    //     title: 'Calendar',
    //     link: '/calendar',
    //     type: 'all'
    // },
     {
        title: 'Children',
        link: '/children',
        type: 'all'
    },{
        title: 'Programs',
        link: '/programs',
        type: 'all'
    }, {
        title: 'Users',
        link: '/admin',
        type: 'all'
    }, 
    // {
    //     title: 'Schools',
    //     link: '/schools',
    //     type: 'all'
    // }, 
    {
        title: 'Add/Edit Class',
        link: '/classes',
        type: 'all'
    }
    // , {
    //     title: 'Forms',
    //     link: '/allforms',
    //     type: 'all'
    // }
    // , {
    //     title: 'Invoices',
    //     link: '/runinvoices',
    //     type: 'all'
    // }
    ];

    teacherMenu = [{
        title: 'Settings',
        link: '/settings',
        type: 'teacher'
    }
    // , {
    //     title: 'Calendar',
    //     link: '/calendar',
    //     type: 'teacher'
    // }
    , {
        title: 'Children',
        link: '/children',
        type: 'teacher'
    }
    // , {
    //     title: 'Forms',
    //     link: '/allforms',
    //     type: 'teacher'
    // }
    ,{
        title: 'Programs',
        link: '/programs',
        type: 'teacher'
    }, {
        title: 'Classes',
        link: '/classes',
        type: 'teacher'
    }];

    parentMenu = [{
        title: 'Settings',
        link: '/settings',
        type: 'parent'
    }, {
        title: 'Calendar',
        link: '/calendar',
        type: 'parent'
    }, {
        title: 'Children',
        link: '/children',
        type: 'parent'
    },{
        title: 'Programs',
        link: '/programs',
        type: 'parent'
    }];

    notLoggedInMenu = [{
        title: 'Login',
        link: 'https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgID=550369'
    }]

    loggedInMenu = [
    //     {
    //     title: 'Exhibits',
    //     link: '/exhibits'
    // }, 
    {
        title: 'Logout',
        link: '/logout'
    } ]
    
    mainMenu = [].concat(this.adminMenu, this.teacherMenu, this.parentMenu);

    Router;
    isAdmin;
    isLoggedIn;
    currentUser = {};
    AuthService;

    static parameters = [AuthService, Router];
    constructor(authService: AuthService, router: Router) {
        this.AuthService = authService;
        this.Router = router;
        this.AuthService.currentUserChanged.subscribe(user => {
            this.currentUser = user;
        });
    }

    logout() {
        return this.AuthService.logout().then(() => {
            this.Router.navigateByUrl('/home');
        });
    }
}
