import {
  NgModule,
  ApplicationRef,
} from '@angular/core';
import { GetAge } from './getAge';
import { GetSafeUrl } from './getSafeUrl';
import { GetSelectedChild } from './getSelectedChild';
import { GetNormalTime } from './getNormalTime';
import { GetTimeLength } from './getTimeLength';
import { GetSelectedItem } from './getSelectedItem'

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    GetAge,
    GetSafeUrl,
    GetSelectedChild,
    GetNormalTime,
    GetTimeLength,
    GetSelectedItem
  ],
  exports: [
    GetAge,
    GetSafeUrl,
    GetSelectedChild,
    GetNormalTime,
    GetTimeLength,
    GetSelectedItem
  ]
})
export class PipesModule {}