import { Component } from '@angular/core';

@Component({
    selector: 'footer',
    template: require('./footer.html'),
    styles: [require('./footer.scss')]
})
 
export class FooterComponent {
    year = new Date().getFullYear();
}
