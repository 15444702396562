import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


import { RouterModule, Routes } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap';

import { ChildComponent } from './child.component';
import { SocketService } from '../../../components/socket/socket.service';
import { UploadImageModule } from '../../account/uploadimage/uploadimage.module';
import { PipesModule } from '../../../components/pipe/pipesModule';
import { LazyLoadImageModule } from 'ng-lazyload-image';

export const ROUTES: Routes = [
    { path: 'children', component: ChildComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        UploadImageModule,
        PipesModule,
        LazyLoadImageModule,
    ],
    declarations: [
        ChildComponent,
    ],
    providers: [
        SocketService,
    ],
    exports: [
        ChildComponent
    ],
})
export class ChildModule {}
