import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'allpickups',
    template: require('./allpickups.html'),
    styles: [require('./allpickups.scss')],
})
export class AllPickupsComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allPickups = [];
    allSocketPickups = [];
    allpickuplists = [];
    allpickedup = [];
    allFilteredPickupLists = [];
    allClasses = [];
    newPickup = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    loading = true;
    loadingbuild = true;
    loadingget = true;
    pickupImagesPickupData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";
    showPickup = false;
    showClasses = false;
    selectedPickupObject = [];
    today = new Date(new Date().toDateString());

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        this.getPickupData();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('pickup');
        this.SocketService.unsyncUpdates('socketPickupObject');
    }

    getPickupData() {
        this.http.get('/api/pickups/').subscribe((pickupObject) => {
            this.allPickups = pickupObject;
            // pickupObject.forEach(item => {
            //     console.log(item.day);
            //     var tempDate = (+new Date(item.day).getMonth() + +1)+ '-' + new Date(item.day).getDate() + '-' + new Date(item.day).getFullYear();
            //     item.day = tempDate;
            //     console.log(tempDate);
            //     this.http.put('/api/pickups/'+item._id, item).subscribe(result => {
            //         console.log(result);
            //     });
            // });
        });
        var todate = (+new Date().getMonth() + +1)+ '-' + (+new Date().getDate() ) + '-' + new Date().getFullYear();
        this.http.get('/api/pickups/all/pickedup/'+todate).subscribe((pickupObject) => {
            if (pickupObject.length <= 0) {
                var tempDateWithTime = new Date(new Date(this.today).toDateString());
                var pickupForDatabase = {
                    pickedupby: '62c1b99fe78f5ac6cb253589',
                    pickupitems: [],
                    day: (+tempDateWithTime.getMonth() + +1)+ '-' + tempDateWithTime.getDate() + '-' + tempDateWithTime.getFullYear()
                };
                this.http.post('/api/pickups', pickupForDatabase).subscribe(() => {
                    this.loadingbuild = false;
                    this.checkLoadingAllDone();
                });
            } else {
                this.allSocketPickups = pickupObject;
                this.loadingbuild = false;
                this.checkLoadingAllDone();
            }
            this.SocketService.syncUpdates('pickup', this.allSocketPickups);
        });
        this.http.get('/api/pickups/all/needed/').subscribe((pickupObject) => {
            pickupObject.forEach(thisClassElement => {
                var needsSaved = false;
                var today = new Date().getTime();
                var startDay = new Date(thisClassElement.startdate).getTime();
                var endDay = new Date(thisClassElement.enddate).getTime();
                if (thisClassElement.pickups && thisClassElement.pickups.length>0 && (today >= startDay) && (endDay >= today)) {
                    thisClassElement.pickups.forEach(pickupElement => {
                        var thisStudent = {
                            _id: pickupElement._id,
                            class: thisClassElement,
                            firstname: pickupElement.firstname,
                            lastname: pickupElement.lastname,
                            parent: pickupElement.parent ? pickupElement.parent.firstname && pickupElement.parent.lastname ? pickupElement.parent.firstname + " " + pickupElement.parent.lastname : '' : '',
                            school: pickupElement.school ? pickupElement.school.name ? pickupElement.school.name : '' : '',
                            image: pickupElement.image,
                            schoolteacher: pickupElement.schoolteacher,
                            hall: pickupElement.schoolteacher ? pickupElement.schoolteacher.hall ? pickupElement.schoolteacher.hall : '' : '',
                        };
                        this.allpickuplists.push(thisStudent);
                        var foundClass = false;
                        pickupElement.classes.forEach(studentClassElement => {
                            if (studentClassElement._id == thisClassElement._id) {
                                foundClass = true;
                            }
                        });
                        if (!foundClass) {
                            var pickupIndex = -1;
                            pickupIndex = thisClassElement.pickups.map((item) => item._id).indexOf(pickupElement._id);
                            if (pickupIndex !== -1) {
                                thisClassElement.pickups.splice(pickupIndex, 1);
                                needsSaved = true;
                            }
                        }
                    });
                }
                if (needsSaved) {
                    this.http.put('/api/classes/' + thisClassElement._id, thisClassElement).subscribe();
                }
            });
            this.allFilteredPickupLists = this.allpickuplists;
            if (this.allpickuplists.length > 0) {
                this.sortByKey(this.allpickuplists, 'schoolteacher');
                this.sortByKey(this.allpickuplists, 'day');
            }
            this.SocketService.syncUpdates('pickupObject', this.allPickups);
            this.loadingget = false;
            this.checkLoadingAllDone();
        });
    }

    checkLoadingAllDone() {
        if (!this.loadingbuild && !this.loadingget) {
            this.loading = false;
        }
    }
    
    // deleteItem(item) {
    //     this.http.delete('/api/pickups/'+item._id).subscribe(pickupObject => {
    //         this.loading = false;
    //         this.messageService('Pickup Has Been Deleted.', 'Success!', 'success');
    //     });
    // }

    onInputChanged(inputText) {
        let modifiedItems = _.filter(this.allpickuplists, (item) => {
            return item.class.daysofweek.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
        });
        this.allFilteredPickupLists = modifiedItems;
    }

    sortByKey(data, key) {
        let sortedData;
        if (key == 'child'){
            sortedData = data.sort((a,b) => {
                let x = a.lastname.toLowerCase();
                let y = b.lastname.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'hall'){
            sortedData = data.sort((a,b) => {
                if (a.hall && b.hall) {
                    let x = a.hall.toLowerCase();
                    let y = b.hall.toLowerCase();
                    if (x>y) {
                        return 1;
                    }
                    if (x<y) {
                        return -1;
                    }
                    return 0;
                }
                if (a.hall && !b.hall) {
                    return -1;
                }
                if (!a.hall && b.hall) {
                    return 1;
                }
                if (!a.hall && !b.hall) {
                    return 0;
                }
            });
        } else if (key == 'parent') {
            sortedData = data.sort((a,b) => {
                let x = a.parent.toLowerCase();
                let y = b.parent.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'school'){
            sortedData = data.sort((a,b) => {
                let x = a.school.toLowerCase();
                let y = b.school.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'day') {
            sortedData = data.sort((a,b) => {
                let x = a.class.daysofweek.toLowerCase();
                let y = b.class.daysofweek.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'schoolteacher'){
            sortedData = data.sort((a,b) => {
                if (a.schoolteacher && b.schoolteacher) {
                    if (a.schoolteacher.lastname && b.schoolteacher.lastname) {
                        let x = a.schoolteacher.lastname.toLowerCase();
                        let y = b.schoolteacher.lastname.toLowerCase();
                        if (x>y) {
                            return 1;
                        }
                        if (x<y) {
                            return -1;
                        }
                        return 0;
                    }
                }
                if (a.schoolteacher && !b.schoolteacher) {
                    return -1;
                }
                if (!a.schoolteacher && b.schoolteacher) {
                    return 1;
                }
                if (!a.schoolteacher && !b.schoolteacher) {
                    return 0;
                }
            });
        } else {
            sortedData = data.sort((a,b) => {
                let x = a.class.name.toLowerCase();
                let y = b.class.name.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        }
        return sortedData;
    }

    setPickedUp(student) {
        let pickupIndexItem = -1;
        if (student._id) {
            pickupIndexItem = this.allSocketPickups[0].pickupitems.map((item) => item.student._id).indexOf(student._id);
        }
        if (pickupIndexItem !== -1) {
            this.allSocketPickups[0].pickupitems.splice(pickupIndexItem, 1);
        } else {
            if (student._id) {
                var pickupItem = {
                    student: student
                };
                this.allSocketPickups[0].pickupitems.push(pickupItem);
            }
        }
        this.http.put('/api/pickups/'+this.allSocketPickups[0]._id, this.allSocketPickups[0]).subscribe();
    }

    messageService(message, title?, type?, item?, objectType?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    if (objectType === 'pickup') {
                        if (this.currentUser.type=='all') {
                            this.deletePickup(item);
                        } else {
                            this.messageService('Only an admin can delete a pickup.', 'Error!', 'error');
                        }
                    }
                    if (objectType === 'fee') {
                        this.deleteCost(item);
                    }
                }
            })
        }  else if (type === 'error') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'ok'
            }).then((result) => {
                if (result.value === true) {
                    // this.deleteChild(item);
                    // this.messageService('Your child has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'remove') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, remove it!'
            }).then((result) => {
                if (result.value === true) {
                    this.removeFromClass(item);
                    this.messageService('The class has been removed.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
