import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'teacher',
    template: require('./teacher.html'),
    styles: [require('./teacher.scss')],
})
export class TeacherComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allTeachers = [];
    allSchools = [];
    allUsers = [];
    newTeacher = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    teacherImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        return this.http.get('/api/teachers').subscribe((teachers) => {
            this.allTeachers = teachers;
            this.allTeachers.forEach(teacher => {
                if (!teacher.pre && !teacher.firstname && !teacher.lastname) {
                    this.http.delete('/api/teachers/'+teacher._id,).subscribe(() => {
                    });
                }
                // if (teacher.pre != 'Sister') {
                //     teacher.pre = "Sister";
                //     this.http.put('/api/teachers/' + teacher._id, teacher).subscribe(teacher => {

                //     });
                // }
            });
            this.SocketService.syncUpdates('teacher', this.allTeachers);
            this.http.get('/api/schools').subscribe((schools) => {
                this.allSchools = schools;
                this.SocketService.syncUpdates('school', this.allSchools);
            });
            this.http.get('/api/users').subscribe((users) => {
                this.allUsers = users;
                this.SocketService.syncUpdates('user', this.allUsers);
            });
        });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('teacher');
    }

    buildTeachers() {
        this.http.get('/api/students/').subscribe((allChildrenObject) => {
            allChildrenObject.forEach(childElement => {
                if (childElement.school) {
                    var foundIndex = -1;
                    foundIndex = this.allSchools.map((item) => item._id).indexOf(childElement.school._id);
                    if (foundIndex != -1) {
                        var selectedSchool = this.allSchools[foundIndex];
                    }
                    var selectedTeacherPre = '';
                    var selectedTeacherFirstname = '';
                    var selectedTeacherLastname = '';
                    var selectedTeacherPhone = '';
                    var selectedTeacherEmail = '';
                    let teacherNameArray = [];
                    if (childElement.teacher) {
                        teacherNameArray = childElement.teacher.split(" ");
                        if (teacherNameArray[0] && teacherNameArray[1] && teacherNameArray[2]) {
                            selectedTeacherPre = teacherNameArray[0];
                            selectedTeacherFirstname = teacherNameArray[1];
                            selectedTeacherLastname = teacherNameArray[2];
                        } else if (teacherNameArray[0] && teacherNameArray[1]) {
                            selectedTeacherFirstname = teacherNameArray[0];
                            selectedTeacherLastname = teacherNameArray[1];
                        } else {
                            selectedTeacherFirstname = childElement.teacher;
                        }
                    }
                    var foundUserIndex = -1;
                    foundUserIndex = this.allUsers.map((item) => item.name).indexOf(selectedTeacherFirstname + ' ' + selectedTeacherLastname);
                    if (foundUserIndex != -1) {
                        if (this.allUsers[foundUserIndex].lastname == selectedTeacherLastname) {
                            console.log('TEACHER HAS USER???');
                            console.log(this.allUsers[foundUserIndex]);
                            selectedTeacherPhone = this.allUsers[foundUserIndex].phone ? this.allUsers[foundUserIndex].phone : '';
                            selectedTeacherEmail = this.allUsers[foundUserIndex].email ? this.allUsers[foundUserIndex].email : '';
                        }
                    }
                    var newTeacherElement = {
                        pre: selectedTeacherPre,
                        firstname: selectedTeacherFirstname,
                        lastname: selectedTeacherLastname,
                        school: selectedSchool,
                        hall: '',
                        room: '',
                        grade: '',
                        phone: selectedTeacherPhone,
                        email: selectedTeacherEmail
                    };
                    var foundTeacherIndex = -1;
                    foundTeacherIndex = this.allTeachers.map((item) => item.firstname).indexOf(newTeacherElement.firstname);
                    if (foundTeacherIndex != -1) {
                        if (this.allTeachers[foundTeacherIndex].lastname == newTeacherElement.lastname && this.allTeachers[foundTeacherIndex].pre == newTeacherElement.pre) {
                            childElement.schoolTeacher = this.allTeachers[foundTeacherIndex];
                            // this.http.put('/api/students/' + childElement._id, childElement).subscribe(childObject => {
                            //     console.log('saved');
                            //     console.log(childObject);
                            // });
                            console.log(this.allTeachers);
                        console.log(newTeacherElement);
                        console.log('found YES');
                        }
                    } else {
                        console.log(this.allTeachers);
                        console.log(newTeacherElement);
                        console.log('not fpouint');
                        this.http.post('/api/teachers/', newTeacherElement).subscribe(teacher => {
                            childElement.schoolTeacher = teacher;
                            this.allTeachers.push(teacher);
                            // this.http.put('/api/students/' + childElement._id, childElement).subscribe(childObject => {
                            //     console.log('saved');
                            //     console.log(childObject);
                            // });
                        });
                    }
                }
            });
        });
    }

    matchTeachers() {
        this.http.get('/api/students/').subscribe((allChildrenObject) => {
            allChildrenObject.forEach(childElement => {
                if (childElement.teacher) {
                    var selectedTeacherPre = '';
                    var selectedTeacherFirstname = '';
                    var selectedTeacherLastname = '';
                    let teacherNameArray = [];
                    if (childElement.teacher) {
                        teacherNameArray = childElement.teacher.split(" ");
                        if (teacherNameArray[0] && teacherNameArray[1] && teacherNameArray[2]) {
                            selectedTeacherPre = teacherNameArray[0];
                            selectedTeacherFirstname = teacherNameArray[1];
                            selectedTeacherLastname = teacherNameArray[2];
                        } else if (teacherNameArray[0] && teacherNameArray[1]) {
                            selectedTeacherFirstname = teacherNameArray[0];
                            selectedTeacherLastname = teacherNameArray[1];
                        } else {
                            selectedTeacherFirstname = childElement.teacher;
                        }
                        this.allTeachers.forEach(teacherElement => {
                            if (teacherElement.firstname == selectedTeacherFirstname && teacherElement.lastname == selectedTeacherLastname) {
                                childElement.schoolteacher = teacherElement;
                                console.log(teacherElement);
                                console.log('Saved');
                                this.http.put('/api/students/' + childElement._id, childElement).subscribe(childObject => {
                                });
                            }
                        });
                    }
                }
            });
        });
    }

    addTeacher() {
        if (this.newTeacher.firstname) {
            if (this.teacherImagesFormData) {
                if (this.teacherImagesFormData.length >= 1) {
                    for (let index = 0; index < this.teacherImagesFormData.length; index++) {
                        if (this.newTeacher.images) {
                            this.newTeacher.images.push(this.teacherImagesFormData[index]._id);
                        } else {
                            this.newTeacher.images = [];
                            this.newTeacher.images.push(this.teacherImagesFormData[index]._id);
                        }
                    }
                }
            }
            if (this.newTeacher._id) {
                return this.http.put('/api/teachers/' + this.newTeacher._id, this.newTeacher).subscribe(teacher => {
                    this.clearout();
                    this.messageService('Teacher has been modified.', 'Success!', 'success');
                });
            } else {
                return this.http.post('/api/teachers', this.newTeacher).subscribe(teacher => {
                    const locationIndex = this.allTeachers.map((item) => item._id).indexOf(teacher._id);
                    if (locationIndex !== -1) {
                        this.allTeachers[locationIndex].image = this.newTeacher.image;
                    }
                    this.clearout();
                    this.messageService('Teacher has been added.', 'Success!', 'success');
                });
            }
        }
    }

    deleteTeacher(teacher) {
        return this.http.delete(`/api/teachers/${teacher._id}`)
            .subscribe(() => {
            });
    }

    confirmDelete(teacher) {
        this.messageService('Are you sure you want to delete this teacher?', 'Are you sure?', 'delete', teacher);
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    getImages() {
        return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newTeacher.image = image;
        this.displayImages = false;
    }

    clearout() {
        this.newTeacher = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteTeacher(item);
                    this.messageService('The teacher has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
