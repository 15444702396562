import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'allforms',
    template: require('./allforms.html'),
    styles: [require('./allforms.scss')],
})
export class AllFormsComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allForms = [];
    allClasses = [];
    newForm = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    formImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";
    showForm = false;
    showClasses = false;
    selectedFormObject = [];

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        this.getUserData();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('formObject');
        this.SocketService.unsyncUpdates('classObject');
        this.SocketService.unsyncUpdates('schoolObject');
        this.SocketService.unsyncUpdates('studentObject');
    }

    getUserData() {
        this.http.get('/api/users/me').subscribe((user) => {
            this.currentUser = user;
            if (this.currentUser.type=='all') {
                this.http.get('/api/forms/').subscribe((formObject) => {
                    this.allForms = formObject;
                    this.SocketService.syncUpdates('formObject', this.allForms);
                });
                this.http.get('/api/classes/').subscribe((classObject) => {
                    this.allClasses = classObject;
                    this.SocketService.syncUpdates('classObject', this.allClasses);
                });
                this.http.get('/api/schools').subscribe((schoolObject) => {
                    this.allSchools = schoolObject;
                    this.SocketService.syncUpdates('schoolObject', this.allSchools);
                });
                this.http.get('/api/students').subscribe((studentObject) => {
                    this.allStudents = studentObject;
                    this.SocketService.syncUpdates('studentObject', this.allStudents);
                });
            } else {
                this.http.get('/api/forms/teacher/'+this.currentUser.person._id).subscribe((formObject) => {
                    this.allForms = formObject;
                    this.SocketService.syncUpdates('formObject', this.allForms);
                });
                this.http.get('/api/classes/teacher/'+this.currentUser.person._id).subscribe((classObject) => {
                    this.allClasses = classObject;
                    this.SocketService.syncUpdates('classObject', this.allClasses);
                });
            }
        });
    }

    addForm() {
        if (this.SocketService.imageUploader) {
            this.newForm.image = this.SocketService.imageUploader;
        }
        if (this.newForm.name) {
            if (this.newForm._id) {
                this.http.put('/api/forms/' + this.newForm._id, this.newForm).subscribe(formObject => {
                    this.getUserData();
                    this.clearout();
                    this.messageService('Form has been modified.', 'Success!', 'success');
                });
            } else {
                if (!this.currentUser.type=='all') {
                    this.newForm.owner = this.currentUser.person;
                }
                this.http.post('/api/forms', this.newForm).subscribe(formObject => {
                    const locationIndex = this.allForms.map((item) => item._id).indexOf(formObject._id);
                    if (locationIndex !== -1) {
                        this.allForms[locationIndex].image = this.newForm.image;
                    }
                    this.getUserData();
                    this.clearout();
                    this.messageService('Form has been added.', 'Success!', 'success');
                });
            }
        }
    }

    deleteForm(formObject) {
        this.http.delete(`/api/forms/${formObject._id}`).subscribe(() => {
            this.getUserData();
            this.messageService('Form has been deleted.', 'Success!', 'success');
        });
    }

    confirmDelete(formObject, type) {
        this.messageService('Are you sure you want to delete this ' + type + '?', 'Are you sure?', 'delete', formObject, type);
    }

    confirmRemoveClass(classObject) {
        this.messageService('Are you sure you want to remove this class?', 'Are you sure?', 'remove', classObject, 'class');
    }

    viewForm(formObject) {
        this.showForm = true;
        this.selectedFormObject = formObject;
    }

    removeFromClass(thisClassObject) {
        const locationIndex = this.selectedFormObject.classes.map((classItem) => classItem._id).indexOf(thisClassObject._id);
        if (locationIndex !== -1) {
            this.selectedFormObject.classes.splice(locationIndex, 1);
        }
        this.http.put('/api/forms/' + this.selectedFormObject._id, this.selectedFormObject).subscribe(formObject => {
            this.getUserData();
            this.showClasses = false;
            this.messageService('Class has been removed.', 'Success!', 'success');
        });
    }

    addToClass(thisClassObject) {
        console.log(this.selectedFormObject);
        console.log(thisClassObject);
        const locationIndex = this.selectedFormObject.classes.map((classItem) => classItem._id).indexOf(thisClassObject._id);
        if (locationIndex == -1) {
            this.selectedFormObject.classes.push(thisClassObject);
        }
        this.selectedFormObject.classes.forEach(classObject => {
            const locationIndex = classObject.forms.map((formItem) => formItem._id).indexOf(this.selectedFormObject._id);
            if (locationIndex == -1) {
                classObject.forms.push(this.selectedFormObject._id);
                this.http.put('/api/classes/' + classObject._id, classObject).subscribe(classObject => {
                    console.log(classObject)
                });
            }
        });
        this.http.put('/api/forms/' + this.selectedFormObject._id, this.selectedFormObject).subscribe(formObject => {
            this.getUserData();
            this.showClasses = false;
            this.messageService('Class has been added.', 'Success!', 'success');
        });
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    getImages() {
        this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newForm.image = image;
        this.displayImages = false;
    }

    clearout() {
        this.newForm = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, objectType?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    if (objectType === 'form') {
                        if (this.currentUser.type=='all') {
                            this.deleteForm(item);
                        } else {
                            this.messageService('Only an admin can delete a form.', 'Error!', 'error');
                        }
                    }
                    if (objectType === 'fee') {
                        this.deleteCost(item);
                    }
                }
            })
        }  else if (type === 'error') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'ok'
            }).then((result) => {
                if (result.value === true) {
                    // this.deleteChild(item);
                    // this.messageService('Your child has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'remove') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, remove it!'
            }).then((result) => {
                if (result.value === true) {
                    this.removeFromClass(item);
                    this.messageService('The class has been removed.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
