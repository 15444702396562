import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSelectedItem',
  pure: false
})

export class GetSelectedItem implements PipeTransform {

    constructor() {}

    transform(item: any, args?: any, all?: any): any {
        if (args === 'getSelected') {
            return this.getSelected(item, all);
        } else {
            return item;
        }
    }

    getSelected(item, all) {
        var itemFound = false;
        if (all && all.length > 0) {
            all.forEach(itemElement => {
                if (itemElement && itemElement.student) {
                    if (item._id == itemElement.student._id) {
                        itemFound = true;
                    }
                } else {
                    if (item._id == itemElement) {
                        itemFound = true;
                    }
                }
            });
        }
        return itemFound;
    }
  
}
