import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { ProgramsComponent } from './programs.component';
import { SocketService } from '../../components/socket/socket.service';
import { PipesModule } from '../../components/pipe/pipesModule';
import { LazyLoadImageModule } from 'ng-lazyload-image';

export const ROUTES: Routes = [
    { path: 'programs', component: ProgramsComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        PipesModule,
        LazyLoadImageModule
    ],
    declarations: [
        ProgramsComponent,
    ],
    providers: [
        SocketService,
    ],
    exports: [
        ProgramsComponent,
    ],
})
export class ProgramsModule {}
