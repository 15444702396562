import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import Swal from 'sweetalert2';
import { zip } from 'rxjs';

@Component({
    selector: 'child',
    template: require('./child.html'),
    styles: [require('./child.scss')],
})
export class ChildComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    Router;
    defaultImage = '../../../assets/images/defaultimage.gif';
    allChildren = [];
    allSystemLoadedStudents = [];
    allImages = [];
    allPeople = [];
    newChild = {};
    childImagesFormData = [];
    formGroupToShow = [];
    showListView = false;
    selectedChildren = null;
    selectedChild = null;
    selectedClass = null;
    showAddChild = false;
    showClassHistory = false;
    AuthService;
    currentUser = null;
    showForms = false;
    loading = true;
    changeLoading = false;
    showAllSystemChildren = true;
    showChatBox: boolean = false;
    currentClassHistoryClassId = '';
    currentClassHistoryDate = '';
    currentClassHistoryStatus = '';

    static parameters = [HttpClient, SocketService, Router, ActivatedRoute, AuthService];
    constructor(http: HttpClient, socketService: SocketService, router: Router, Route: ActivatedRoute, _AuthService_: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = _AuthService_;
        this.Router = router;
        this.Route = Route;
        this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.getUserData();
        this.getSchools();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('child');
    }

    showChatBox() {
        this.chatIsShown = ! this.chatIsShown;
    }

    getUserData(reload?) {
        this.http.get('/api/users/me').subscribe((user) => {
            this.currentUser = user;
            if (this.currentUser.type=='all') {
                this.getPeople();
                this.http.get('/api/students/').subscribe((allChildrenObject) => {
                    this.allSystemLoadedStudents = allChildrenObject;
                    this.allSystemLoadedStudents.forEach(childObject => {
                        if (childObject.classes.length > 0) {
                            childObject.classes.forEach(classObject => {
                                if ((classObject.startdate && new Date(classObject.startdate).getTime() > new Date().getTime()) || (classObject.enddate && new Date().getTime() > new Date(classObject.enddate).getTime())) {
                                    classObject.canLeaveClass = true;
                                } else {
                                    classObject.calLeaveClass = false;
                                }
                            });
                        }
                        if (childObject._id == this.Route.snapshot.queryParams.child) {
                            this.newChild = childObject;
                            if (this.newChild.parent) {
                                this.newChild.parent = this.newChild.parent._id;
                            }
                            if (this.newChild.school) {
                                this.newChild.school = this.newChild.school._id;
                            }
                            this.showAddChild = true;
                        }
                        if (childObject._id == this.Route.snapshot.queryParams.childclasses) {
                            this.selectedChild = childObject;
                            if (this.selectedChild.parent) {
                                this.selectedChild.parent = this.selectedChild.parent._id;
                            }
                            if (this.selectedChild.school) {
                                this.selectedChild.school = this.selectedChild.school._id;
                            }
                        }
                    });
                    if (this.Route.snapshot.queryParams.addchild) {
                        this.showAddChild = this.Route.snapshot.queryParams.addchild;
                    }
                    this.loading = false;
                    if (reload) {
                        this.changeLoading = false;
                        this.messageService('Your child has been modified.', 'Success!', 'success');
                    }
                    this.SocketService.syncUpdates('childObject', this.allChildren);
                });
            }
            this.http.get('/api/students/children/' + this.currentUser.person._id).subscribe((childrenObject) => {
                this.allChildren = childrenObject;
                this.allChildren.forEach(childObject => {
                    if (childObject.classes.length > 0) {
                        childObject.classes.forEach(classObject => {
                            if ((classObject.startdate && new Date(classObject.startdate).getTime() > new Date().getTime()) || (classObject.enddate && new Date().getTime() > new Date(classObject.enddate).getTime())) {
                                classObject.canLeaveClass = true;
                            } else {
                                classObject.calLeaveClass = false;
                            }
                        });
                    }
                    if (childObject._id == this.Route.snapshot.queryParams.child) {
                        this.newChild = childObject;
                        if (this.newChild.parent) {
                            this.newChild.parent = this.newChild.parent._id;
                        }
                        if (this.newChild.school) {
                            this.newChild.school = this.newChild.school._id;
                        }
                        this.showAddChild = true;
                    }
                    if (childObject._id == this.Route.snapshot.queryParams.childclasses) {
                        this.selectedChild = childObject;
                        if (this.selectedChild.parent) {
                            this.selectedChild.parent = this.selectedChild.parent._id;
                        }
                        if (this.selectedChild.school) {
                            this.selectedChild.school = this.selectedChild.school._id;
                        }
                    }
                });
                if (this.Route.snapshot.queryParams.addchild) {
                    this.showAddChild = this.Route.snapshot.queryParams.addchild;
                }
                this.loading = false;
                if (reload) {
                    this.changeLoading = false;
                    this.messageService('Your child has been modified.', 'Success!', 'success');
                }
                this.SocketService.syncUpdates('childObject', this.allChildren);
            });
        }, error => {
            if (error.statusText=='Unauthorized') {
                this.Router.navigateByUrl('/login');
            }
        });
    }

    getPeople() {
        this.http.get('/api/people/').subscribe((people) => {
            this.allPeople = people;
            this.SocketService.syncUpdates('people', this.allPeople);
        });
    }

    getSchools() {
        this.http.get('/api/teachers').subscribe((teachers) => {
            this.allTeachers = teachers;
        });
        return this.http.get('/api/schools').subscribe((schools) => {
            this.allSchools = schools;
            this.SocketService.syncUpdates('schools', this.allSchools);
        });
    }

    viewClasses(child) {
        this.Router.navigateByUrl('/children?childclasses='+child._id);
    }

    setPickup(classElement, index, child) {
        this.changeLoading = true;
        const pickupIndex = child.classes[index].pickups.map((item) => item).indexOf(child._id);
        if (pickupIndex !== -1) {
            child.classes[index].pickups.splice(pickupIndex, 1);
        } else {
            child.classes[index].pickups.push(child._id);
        }
        this.http.put('/api/classes/' + child.classes[index]._id, child.classes[index]).subscribe(classObject => {
            this.getUserData(true);
        });
    }

    setUniform(classElement, index, child) {
        this.changeLoading = true;
        const uniformIndex = child.classes[index].uniforms.map((item) => item).indexOf(child._id);
        if (uniformIndex !== -1) {
            child.classes[index].uniforms.splice(uniformIndex, 1);
        } else {
            child.classes[index].uniforms.push(child._id);
        }
        this.http.put('/api/classes/' + child.classes[index]._id, child.classes[index]).subscribe(classObject => {
            this.getUserData(true);
        });
    }

    selectForm(classObject) {
        this.selectedClass = classObject;
        this.formGroupToShow = [];
        if (classObject) {
            const classLocationIndex = this.selectedChild.classes.map((item) => item._id).indexOf(classObject._id);
            if (classLocationIndex !== -1) {
                classObject.forms.forEach(formElement => {
                    const formLocationIndex = this.selectedChild.forms.map((formItem) => formItem.class).indexOf(classObject._id);
                    if (formLocationIndex !== -1) {
                        this.http.get('/api/signatures/' + this.selectedChild.forms[formLocationIndex].signature).subscribe(signature => {
                            formElement.signature = signature.signature;
                            this.formGroupToShow.push(formElement);
                        });
                    }
                });
            }
            this.showForms = true;
        } else {
            this.showForms = false;
        }
    }

    selectChildCard(childObject) {
        if (!this.delete) {
            this.Router.navigateByUrl('/children?child='+childObject._id);
        }
    }

    selectImage(image) {
        this.newChild.image = image;
        this.displayImages = false;
    }

    startAddChild() {
        this.Router.navigateByUrl('/children?addchild=true');
    }

    editClassHistoryItem(student, item) {
        this.currentClassHistoryClassId = item.class._id;
        this.currentClassHistoryDate = item.date;
        this.currentClassHistoryStatus = item.status+" - MODIFY:"+item._id;
    }

    deleteClassHistoryItem(student, item) {
        var foundIndex = -1;
        foundIndex = student.classhistory.map((item) => item._id).indexOf(item._id);
        if (foundIndex != -1) {
            student.classhistory.splice(foundIndex, 1);
        }
    }

    saveClassHistoryItem(student) {
        var studentClassHistoryItem = {
            date: this.currentClassHistoryDate,
            status: this.currentClassHistoryStatus,
            class: this.currentClassHistoryClassId,
            bywho: this.currentUser.person._id
        };
        student.classhistory.push(studentClassHistoryItem);
        this.currentClassHistoryClassId = '';
        this.currentClassHistoryDate = '';
        this.currentClassHistoryStatus = '';
    }

    addChild() {
        if (this.SocketService.imageUploader) {
            this.newChild.image = this.SocketService.imageUploader;
        }
        console.log(this.newChild);
        if (this.newChild.firstname) {
            if (this.newChild._id) {
                if (!this.newChild.parent) {
                    this.newChild.parent = this.currentUser.person._id;
                }
                this.http.put('/api/students/' + this.newChild._id, this.newChild).subscribe(childObject => {
                    this.getUserData();
                    this.clearout();
                    this.messageService('Your child has been modified.', 'Success!', 'success');
                });
            } else {
                if (!this.newChild.parent) {
                    this.newChild.parent = this.currentUser.person._id;
                }
                this.http.post('/api/students', this.newChild).subscribe(childObject => {
                    const locationIndex = this.allChildren.map((item) => item._id).indexOf(childObject._id);
                    if (locationIndex !== -1) {
                        this.allChildren[locationIndex].image = this.newChild.image;
                    }
                    this.currentUser.person.children = this.currentUser.person.children.concat(childObject);
                    this.http.put('/api/people/' + this.currentUser.person._id, this.currentUser.person).subscribe((userObject) => {
                        this.getUserData();
                        this.clearout();
                        this.messageService('Your child has been added.', 'Success!', 'success');
                    });
                });
            }
        }
    }

    deleteChild(child) {
        this.http.delete(`/api/students/${child._id}`).subscribe(() => {
            this.delete = false;
            this.getUserData();
            this.messageService('Child has been deleted.', 'Success!', 'success');
        });
    }

    setChildForDelete(child) {
        child.delete = 'yes';
        this.http.put(`/api/students/${child._id}`, child).subscribe(() => {
            this.getUserData();
            this.messageService('Only an admin can delete a child, Admin has been notified.', 'Error!', 'error');
        });
    }

    setChildToNotDelete(child) {
        child.delete = 'no';
        this.http.put(`/api/students/${child._id}`, child).subscribe(() => {
            this.getUserData();
            this.messageService('Child will not be deleted.', 'Success!', 'success');
        });
    }

    confirmDelete(child, type) {
        this.messageService('Are you sure you want to delete this ' + type + '?', 'Are you sure?', 'delete', child, type);
    }

    removeChildFromClass(child, classObject) {
        console.log(JSON.parse(JSON.stringify(child)));
        console.log(child);
        console.log(classObject);
        let tempClass = '';
        this.loading = true;
        let foundClassIndex = child.classes.map((item) => item._id).indexOf(classObject._id);
        if (foundClassIndex != -1) {
            tempClass = JSON.parse(JSON.stringify(child.classes[foundClassIndex]));
            child.classes.splice(foundClassIndex, 1);
            var studentClassHistoryItem = {
                status: 'Removed From Class',
                class: classObject._id,
                bywho: this.currentUser.person._id
            };
            child.classhistory.push(studentClassHistoryItem);
        }
        console.log(tempClass);
        let foundStudentIndex = classObject.students.map((item) => item).indexOf(child._id);
        if (foundStudentIndex != -1) {
            console.log('found');
            classObject.students.splice(foundStudentIndex, 1);
            let foundStudentIndex2 = classObject.students.map((item) => item).indexOf(child._id);
            if (foundStudentIndex2 != -1) {
                console.log('found');
                classObject.students.splice(foundStudentIndex2, 1);
                let foundStudentIndex3 = classObject.students.map((item) => item).indexOf(child._id);
                if (foundStudentIndex3 != -1) {
                    console.log('found');
                    classObject.students.splice(foundStudentIndex3, 1);
                }
            }
        }
        zip(foundClassIndex != -1 ? this.saveStudentWithServer(child) : '', foundStudentIndex != -1 ? this.saveClassWithServer(classObject) : '').subscribe(() => {
            this.getUserData();
            this.getSchools();
            this.messageService('Student has been removed.', 'Success!', 'success');
        });
    }

    saveStudentWithServer(student) {
        return this.http.put('/api/students/' + student._id, student);
    }

    saveClassWithServer(classObject) {
        return this.http.put('/api/classes/' + classObject._id, classObject);
    }

    clearout() {
        this.newChild = {};
        this.Router.navigateByUrl('/children');
        // this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    if (this.currentUser.type=='all') {
                        this.deleteChild(item);
                    } else {
                        this.setChildForDelete(item);
                    }
                }
            })
        } else if (type === 'error') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'ok'
            }).then((result) => {
                if (result.value === true) {
                    // this.deleteChild(item);
                    // this.messageService('Your child has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
