import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { UserService } from '../../../components/auth/user.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'person',
    template: require('./person.html'),
    styles: [require('./person.scss')],
})
export class PersonComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allPeople = [];
    newPerson = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    personImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";

    static parameters = [HttpClient, UserService, SocketService, AuthService];
    constructor(http: HttpClient, userService: UserService, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.userService = userService;
        this.SocketService = socketService;
        this.AuthService = authService;

        this.userService.get().toPromise().then((user: User) => {
            this.currentUser = user;
        }).catch(err => {
            console.log(err);
            localStorage.removeItem('id_token');
        });
    }

    ngOnInit() {
        return this.http.get('/api/people').subscribe((people) => {
            this.allPeople = people;
            // this.allPeople.forEach(personElement => {
            //     if (personElement.forms.length > 0) {
            //         personElement.forms.forEach(formElement => {
            //             if (!formElement.signature || !formElement.form) {
            //                 const locationIndex = personElement.forms.map((item) => item._id).indexOf(formElement._id);
            //                 personElement.forms.splice(locationIndex, 1);
            //             }
            //         });
            //         this.http.put('/api/people/' + personElement._id, personElement).subscribe(person => {
            //             // console.log(person);
            //         });
            //     }
            // });
            this.SocketService.syncUpdates('people', this.allPeople);
        });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('person');
    }

    addPerson() {
        if (this.newPerson.firstname) {
            if (this.personImagesFormData) {
                if (this.personImagesFormData.length >= 1) {
                    for (let index = 0; index < this.personImagesFormData.length; index++) {
                        if (this.newPerson.images) {
                            this.newPerson.images.push(this.personImagesFormData[index]._id);
                        } else {
                            this.newPerson.images = [];
                            this.newPerson.images.push(this.personImagesFormData[index]._id);
                        }
                    }
                }
            }
            if (this.newPerson._id) {
                return this.http.put('/api/people/' + this.newPerson._id, this.newPerson).subscribe(person => {
                    this.clearout();
                    this.messageService('Person has been modified.', 'Success!', 'success');
                });
            } else {
                return this.http.post('/api/people', this.newPerson).subscribe(person => {
                    const locationIndex = this.allPeople.map((item) => item._id).indexOf(person._id);
                    if (locationIndex !== -1) {
                        this.allPeople[locationIndex].image = this.newPerson.image;
                    }
                    this.clearout();
                    this.messageService('Person has been added.', 'Success!', 'success');
                });
            }
        }
    }

    deletePerson(person) {
        return this.http.delete(`/api/people/${person._id}`).subscribe(() => {
            this.allPeople.splice(this.allPeople.indexOf(person), 1);
            this.messageService('The person has been deleted.', 'Success!', 'success');
        });
    }

    confirmDelete(person) {
        this.messageService('Are you sure you want to delete this person?', 'Are you sure?', 'delete', person);
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    getImages() {
        return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newPerson.image = image;
        this.displayImages = false;
    }

    getSignature(signature, signatureIndex, person, personIndex) {
        this.http.get('/api/signatures/' + signature).subscribe(signatureObject => {
            this.allPeople[personIndex].signatures[signatureIndex] = signatureObject;
        });
    }

    viewPerson(person) {
        console.log(person);
    }

    clearout() {
        this.newPerson = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    if (this.currentUser.email=='jenniferjohnson.mbs@gmail.com') {
                        this.deletePerson(item);
                    } else {
                        this.messageService('Contact web creator to delete a person.', 'Error!', 'error');
                    }
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
