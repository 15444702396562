import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'runinvoice',
    template: require('./runinvoice.html'),
    styles: [require('./runinvoice.scss')],
})
export class RunInvoiceComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allRunInvoices = [];
    newRunInvoice = {};
    loading = true;
    runinvoiceImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = ""; 
    corporation = "balletvirtuosa";
    ensignAndVanguardInvoice = [];
    daycareAndMorningstarInvoice = [];
    ensignAndVanguardInvoiceItems = [];
    daycareAndMorningstarInvoiceItems = [];
    ensignAndVanguardInvoiceTotal = 0;
    daycareAndMorningstarInvoiceTotal = 0;
    invoicesRan = false;
    invoicesReady = false;
    addresses = [{
        name: 'Ensign Learning Center',
        addresslineone: '2755 Decker Lake Ln',
        addresslinetwo: 'Salt Lake City, UT 84119',
        code: 'elci',
        clientno: 4696,
        invoiceno: Math.floor(100000000 + Math.random() * 900000000)
    }, {
        name: 'Morning Star',
        addresslineone: '1860 W Parkway Blvd',
        addresslinetwo: 'West Valley City, UT 84119',
        code: 'mspa',
        clientno: 4609,
        invoiceno: Math.floor(100000000 + Math.random() * 900000000)
    }, {
        name: 'Ensign Learning Center',
        addresslineone: '2755 Decker Lake Ln',
        addresslinetwo: 'Salt Lake City, UT 84119',
        code: 'other'
    }, {
        name: 'DCCS Martial Arts',
        addresslineone: '2140 South 3600 West',
        addresslinetwo: 'Salt Lake City, Utah 84119',
        code: 'dccsmartialarts'
    }, {
        name: 'Valene Arts',
        addresslineone: '3516 South 800 West',
        addresslinetwo: 'Bountiful, UT 84010',
        code: 'valenearts'
    }, {
        name: 'Virtuosa School of Dance',
        addresslineone: '1820W Parkway Blvd',
        addresslinetwo: 'West Valley City, UT 84119',
        code: 'balletvirtuosa'
    }, {
        name: 'ZMPC',
        addresslineone: '1820W Parkway Blvd',
        addresslinetwo: 'West Valley City, UT 84119',
        code: 'zmpc'
    }, {
        name: 'ZMPC9',
        addresslineone: '1820W Parkway Blvd',
        addresslinetwo: 'West Valley City, UT 84119',
        code: 'zmpc9'
    }];

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        this.http.get('/api/classes').subscribe((classObject) => {
            this.allClasses = classObject;
        });
        setTimeout(()=> {
            this.loading = false;
        }, 300);
    }

    ngOnDestroy() {
        // this.SocketService.unsyncUpdates('runinvoice');
    }

    // print() {
    //     window.print();
    // }

    // runInvoices() {
    //     this.loading = true;
    //     this.invoicesRan = true;
    //     this.invoicesReady = false;
    //     this.ensignAndVanguardInvoice = [];
    //     this.daycareAndMorningstarInvoice = [];
    //     this.ensignAndVanguardInvoiceItems = [];
    //     this.daycareAndMorningstarInvoiceItems = [];
    //     this.addresses[0].invoiceno = Math.floor(100000000 + Math.random() * 900000000);
    //     this.addresses[1].invoiceno = Math.floor(100000000 + Math.random() * 900000000);
    //     this.http.get('/api/classes').subscribe((classObject) => {
    //         this.allClasses = classObject;
    //         this.allClasses.forEach(classElement => {
    //             if (classElement.corporation === this.corporation) {
    //                 var ensignAndVanguardRows = [];
    //                 var daycareAndMorningstarRows = [];
    //                 classElement.students.forEach(childElement => {
    //                     var rowId = Math.floor(1000000000000000000 + Math.random() * 9000000000000000000);
    //                     var invoiceRowItem = {
    //                         id: rowId,
    //                         class: classElement.name ? classElement.name : 'NO NAME FOUND',
    //                         daysofweek: classElement.daysofweek ? classElement.daysofweek : 'NO DAYS OF WEEK FOUND',
    //                         school: childElement.school ? childElement.school.name : 'NO SCHOOL NAME FOUND',
    //                         child: childElement.firstname && childElement.lastname ? childElement.firstname + ' ' + childElement.lastname : 'NO CHILD NAME FOUND',
    //                         childid: childElement._id,
    //                         parent: childElement.parent.firstname + ' ' +  childElement.parent.lastname,
    //                         parentid: childElement.parent._id,
    //                         tuition: classElement.tuition ? classElement.tuition : 'NO TUITION FOUND',
    //                         classtuition: classElement.tuition ? classElement.tuition : 'NO TUITION FOUND',
    //                         qty: 1,
    //                         total: classElement.tuition ? classElement.tuition : 'NO TUITION FOUND'
    //                     };
    //                     if (childElement && childElement.school && childElement.school.name && (childElement.school.name == 'Ensign Learning Center' || childElement.school.name == 'Vanguard Academy')) {
    //                         ensignAndVanguardRows.push(invoiceRowItem);
    //                     }
    //                     if (childElement && childElement.school && childElement.school.name && (childElement.school.name == 'Daycare' || childElement.school.name == 'Morning Star')) {
    //                         daycareAndMorningstarRows.push(invoiceRowItem);
    //                     }
    //                 });
    //                 if (ensignAndVanguardRows && ensignAndVanguardRows.length && ensignAndVanguardRows.length >= 1) {
    //                     var ensignAndVanguardInvoiceRowObject = {
    //                         title: (classElement.name ? classElement.name : 'NO NAME FOUND' + ' ' + classElement.daysofweek ? classElement.daysofweek : 'NO DAYS OF WEEK FOUND'),
    //                         qty: ensignAndVanguardRows.length,
    //                         tuition: classElement.tuition ? classElement.tuition : 'NO TUITION FOUND',
    //                         total: ensignAndVanguardRows.length * classElement.tuition
    //                     };
    //                     this.ensignAndVanguardInvoice.push(ensignAndVanguardInvoiceRowObject);
    //                     this.ensignAndVanguardInvoiceItems.push(ensignAndVanguardRows);
    //                 }
    //                 if (daycareAndMorningstarRows && daycareAndMorningstarRows.length && daycareAndMorningstarRows.length >= 1) {
    //                     var daycareAndMorningstarInvoiceRowObject = {
    //                         title: (classElement.name ? classElement.name : 'NO NAME FOUND' + ' ' + classElement.daysofweek ? classElement.daysofweek : 'NO DAYS OF WEEK FOUND'),
    //                         qty: daycareAndMorningstarRows.length,
    //                         tuition: classElement.tuition ? classElement.tuition : 'NO TUITION FOUND',
    //                         total: daycareAndMorningstarRows.length * classElement.tuition
    //                     };
    //                     this.daycareAndMorningstarInvoice.push(daycareAndMorningstarInvoiceRowObject);
    //                     this.daycareAndMorningstarInvoiceItems.push(daycareAndMorningstarRows);
    //                 }
    //             }
    //         });
    //         if (this.ensignAndVanguardInvoice && this.ensignAndVanguardInvoice.length && this.ensignAndVanguardInvoice.length >= 1) {
    //             this.ensignAndVanguardInvoiceTotal = this.ensignAndVanguardInvoice.map(item => item.total).reduce((prev, next) => +prev + +next);
    //         }
    //         if (this.daycareAndMorningstarInvoice && this.daycareAndMorningstarInvoice.length && this.daycareAndMorningstarInvoice.length >= 1) {
    //             this.daycareAndMorningstarInvoiceTotal = this.daycareAndMorningstarInvoice.map(item => item.total).reduce((prev, next) => +prev + +next);
    //         }
    //         setTimeout(()=> {
    //             this.loading = false;
    //         }, 300);
    //     });
    // }

    // removeInvoiceRow(item, doubleArray) {
    //     var indexMap = doubleArray.map(subarray => subarray.map(element => element.id).indexOf(item.id));
    //     indexMap.forEach((itemElement, index) => {
    //         if (itemElement != -1) {
    //             // console.log(doubleArray[index][itemElement]);
    //             doubleArray[index].splice(itemElement, 1);
    //             if (doubleArray[index] && doubleArray[index].length <= 0) {
    //                 doubleArray.splice(index, 1);
    //             }
    //         }
    //     });
    // }

    // changeRowValue(row) {
    //     row.total = row.tuition * row.qty;
    // }

    // reRunInvoices() {
    //     this.loading = true;
    //     this.ensignAndVanguardInvoice = [];
    //     this.daycareAndMorningstarInvoice = [];
    //     this.ensignAndVanguardInvoiceItems.forEach(invoiceRowElement => {
    //         if (invoiceRowElement && invoiceRowElement.length >= 1) {
    //             var ensignAndVanguardRows = [];
    //             invoiceRowElement.forEach(invoiceRowItem => {
    //                 ensignAndVanguardRows.push(invoiceRowItem);
    //             });
    //             if (ensignAndVanguardRows && ensignAndVanguardRows.length && ensignAndVanguardRows.length >= 1) {
    //                 var ensignAndVanguardInvoiceRowObject = {
    //                     title: (invoiceRowElement[0].class ? invoiceRowElement[0].class : 'NO NAME FOUND' + ' ' + invoiceRowElement[0].daysofweek ? invoiceRowElement[0].daysofweek : 'NO DAYS OF WEEK FOUND'),
    //                     qty: ensignAndVanguardRows.map(item => item.qty).reduce((prev, next) => +prev + +next),
    //                     tuition: invoiceRowElement[0].classtuition ? invoiceRowElement[0].classtuition : 'NO TUITION FOUND',
    //                     total: ensignAndVanguardRows.map(item => item.total).reduce((prev, next) => +prev + +next)
    //                 };
    //                 this.ensignAndVanguardInvoice.push(ensignAndVanguardInvoiceRowObject);
    //             }
    //         }
    //     });
    //     this.daycareAndMorningstarInvoiceItems.forEach(invoiceRowElement => {
    //         if (invoiceRowElement && invoiceRowElement.length >= 1) {
    //             var daycareAndMorningstarRows = [];
    //             invoiceRowElement.forEach(invoiceRowItem => {
    //                 daycareAndMorningstarRows.push(invoiceRowItem);
    //             });
    //             if (daycareAndMorningstarRows && daycareAndMorningstarRows.length && daycareAndMorningstarRows.length >= 1) {
    //                 var daycareAndMorningstarInvoiceRowObject = {
    //                     title: (invoiceRowElement[0].class ? invoiceRowElement[0].class : 'NO NAME FOUND' + ' ' + invoiceRowElement[0].daysofweek ? invoiceRowElement[0].daysofweek : 'NO DAYS OF WEEK FOUND'),
    //                     qty: daycareAndMorningstarRows.map(item => item.qty).reduce((prev, next) => +prev + +next),
    //                     tuition: invoiceRowElement[0].classtuition ? invoiceRowElement[0].classtuition : 'NO TUITION FOUND',
    //                     total: daycareAndMorningstarRows.map(item => item.total).reduce((prev, next) => +prev + +next)
    //                 };
    //                 this.daycareAndMorningstarInvoice.push(daycareAndMorningstarInvoiceRowObject);
    //             }
    //         }
    //     });
    //     if (this.ensignAndVanguardInvoice && this.ensignAndVanguardInvoice.length && this.ensignAndVanguardInvoice.length >= 1) {
    //         this.ensignAndVanguardInvoiceTotal = this.ensignAndVanguardInvoice.map(item => item.total).reduce((prev, next) => +prev + +next);
    //     }
    //     if (this.daycareAndMorningstarInvoice && this.daycareAndMorningstarInvoice.length && this.daycareAndMorningstarInvoice.length >= 1) {
    //         this.daycareAndMorningstarInvoiceTotal = this.daycareAndMorningstarInvoice.map(item => item.total).reduce((prev, next) => +prev + +next);
    //     }
    //     this.invoicesReady = true;
    //     this.loading = false;
    // }

    // messageService(message, title?, type?, item?, message2?, title2?) {
    //     if (type === 'delete') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, delete it!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 this.deleteRunInvoice(item);
    //                 this.messageService('The runinvoice has been deleted.', 'Success!', 'success');
    //             }
    //         })
    //     } else if (type === 'success') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'success',
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //     } else {
    //         Swal.fire(message);
    //     }
    // }
}
