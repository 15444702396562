import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../components/auth/user.service';
import Swal from 'sweetalert2';

import { Router } from '@angular/router';
import { AuthService } from '../../components/auth/auth.service';

@Component({
    selector: 'admin',
    template: require('./admin.html'),
    styles: [require('./admin.scss')],
})
export class AdminComponent {
    users: Object[];
    AuthService;
    Router;
    loading = true;
    pending = false;
    pendingNumber = 0;

    static parameters = [HttpClient, UserService, AuthService, Router];
    constructor(http: HttpClient, userService: UserService, _AuthService_: AuthService, router: Router) {
        this.http = http;
        this.userService = userService;
        this.AuthService = _AuthService_;
        this.Router = router;
        
        // Use the user service to fetch all users
        this.userService.query().subscribe(users => {
            users.forEach(userElement => {
                if (userElement.status == 'pending') {
                    this.pending = true;
                    this.pendingNumber = +this.pendingNumber + +1;
                }
            });
            this.allUsers = users;
            this.loading = false;
        });
        this.userService.get().toPromise().then((user: User) => {
            this.currentUser = user;
        }).catch(err => {
            console.log(err);
            localStorage.removeItem('id_token');
        });
    }

    viewUserAccess(user) {
        return this.AuthService.logintoUser({user: user}).then(() => {
            // Logged in, redirect to home
            this.Router.navigateByUrl('/settings');
        }).catch(err => {
            console.log(err);
            this.error = err.error;
            // this.errors.login = err.json().message;
        });
    }

    editUserAccess(editUser) {
        this.pending = false;
        this.pendingNumber = 0;
        if (editUser.password) {
            if (editUser.password.length < 8) {
                return this.messageService('Password must be 8 characters.', 'Error!', 'error');
            }
        }
        console.log(editUser);
        this.userService.editaccess(this.currentUser, editUser).subscribe(editedUser => {
            // console.log(editedUser);
            this.userService.query().subscribe(users => {
                users.forEach(userElement => {
                    if (userElement.status = 'pending') {
                        this.pending = true;
                        this.pendingNumber = +this.pendingNumber + +1;
                    }
                });
                this.allUsers = users;
            });
            this.messageService('User has been updated.', 'Success!', 'success');
        });
    }

    confirmDelete(user, type) {
        this.messageService('Are you sure you want to delete this ' + type + '?', 'Are you sure?', 'delete', user, type);
    }

    getUserReadyToDelete1(user, type) {
        if (user.person && user.person.children.length > 0) {
            this.messageService('User has ' + user.person.children.length + ' children, delete them too?', 'Remove all children?', 'delete2', user, type);
        } else {
            this.messageService('Did this user pay all invoices?', 'Account balances $0.00?', 'delete3', user, type);
        }
    }

    getUserReadyToDelete2(user, type) {
        if (user.person && user.person.children.length > 0) {
            this.messageService('Did this user pay all invoices?', 'Account balances $0.00?', 'delete3', user, type);
        }
    }

    getUserReadyToDelete3(user, type) {
        this.messageService('Wait, are you admin!', 'Do you have access?', 'delete4', user, type);
    }

    deleteUser(user) {
        this.loading = true;
        this.loadArray = [];
        if (user.person) {
            var personIdToDelete = JSON.parse(JSON.stringify(user.person));
            var childrenToDelete = JSON.parse(JSON.stringify(user.person.children));
            if (childrenToDelete.length > 0) {
                childrenToDelete.forEach(childElement => {
                    var newLoad = {
                        id: childElement
                    };
                    this.loadArray.push(newLoad);
                    this.http.delete(`/api/students/${childElement}`).subscribe(() => {
                        var newLoadIndex = -1;
                        newLoadIndex = this.loadArray.map((item) => item.id).indexOf(childElement);
                        if (newLoadIndex != -1) {
                            this.loadArray.splice(newLoadIndex, 1);
                        }
                        this.checkLoadDone(user);
                    });
                });
            }
            var newLoad = {
                id: personIdToDelete._id
            };
            this.loadArray.push(newLoad);
            this.http.delete(`/api/people/${personIdToDelete._id}`).subscribe(() => {
                var newLoadIndex = -1;
                newLoadIndex = this.loadArray.map((item) => item.id).indexOf(personIdToDelete._id);
                if (newLoadIndex != -1) {
                    this.loadArray.splice(newLoadIndex, 1);
                }
                this.checkLoadDone(user);
            });
        } else {
            this.checkLoadDone(user);
        }
    }

    checkLoadDone(user) {
        if (this.loadArray.length < 1) {
            this.userService.remove(user).subscribe(deletedUser => {
                this.allUsers.splice(this.allUsers.indexOf(deletedUser), 1);
                setTimeout(() => {
                    this.loading = false;
                    this.messageService('User has been deleted.', 'Success!', 'success');
                }, 1000);
            });
        }
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    if (this.currentUser.email=='jenniferjohnson.mbs@gmail.com') {
                        this.getUserReadyToDelete1(item, 'delete2');
                    } else {
                        this.messageService('Contact web creator to delete a user.', 'Error!', 'error');
                    }
                }
            })
        } else if (type === 'delete2') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete all!'
            }).then((result) => {
                if (result.value === true) {
                    if (this.currentUser.email=='jenniferjohnson.mbs@gmail.com') {
                        this.getUserReadyToDelete2(item, 'delete3');
                    } else {
                        this.messageService('Contact web creator to delete a user.', 'Error!', 'error');
                    }
                }
            })
        } else if (type === 'delete3') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete user!'
            }).then((result) => {
                if (result.value === true) {
                    if (this.currentUser.email=='jenniferjohnson.mbs@gmail.com') {
                        this.getUserReadyToDelete3(item, 'delete4');
                    } else {
                        this.messageService('Contact web creator to delete a user.', 'Error!', 'error');
                    }
                }
            })
        } else if (type === 'delete4') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, Delete This User!'
            }).then((result) => {
                if (result.value === true) {
                    if (this.currentUser.email=='jenniferjohnson.mbs@gmail.com') {
                        this.deleteUser(item);
                    } else {
                        this.messageService('Contact web creator to delete a user.', 'Error!', 'error');
                    }
                }
            })
        } else if (type === 'error') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'ok'
            }).then((result) => {
                if (result.value === true) {
                    // this.deleteChild(item);
                    // this.messageService('Your child has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
