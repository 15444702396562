import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';

// @flow
type User = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
};

@Component({
    selector: 'settings',
    template: require('./settings.html'),
})
export class SettingsComponent {
    user: User = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };
    errors = {other: undefined};
    message = '';
    phonenumbermessage = '';
    submitted = false;
    phoneSubmittedError = false;
    AuthService;

    static parameters = [HttpClient, AuthService];
    constructor(http: HttpClient, _AuthService_: AuthService) {
        this.http = http;
        this.AuthService = _AuthService_;
    }
    
    changePhoneNumber(form) {
        if(form.invalid) {;
            this.phoneSubmittedError = true;
            setTimeout(() => {
                this.phoneSubmittedError = false;
            }, 3000);
        } else {
            return this.http.put(`/api/people/${this.AuthService.getCurrentUserSync().person._id}`, form.value).subscribe(data => {
                this.phonenumbermessage = 'Phone Number Successfully Changed.';
                setTimeout(() => {
                    this.phonenumbermessage = '';
                }, 3000);
            });
        }
    }

    changePassword(form) {
        if(form.invalid) return;

        this.submitted = true;

        return this.AuthService.changePassword(this.user.oldPassword, this.user.newPassword)
            .then(() => {
                this.message = 'Password successfully changed.';
                setTimeout(() => {
                    this.message = '';
                }, 3000);
            })
            .catch(() => {
                // form.password.$setValidity('mongoose', false);
                this.errors.other = 'Incorrect password';
                this.message = '';
            });
    }
}
