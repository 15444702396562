import { Component } from '@angular/core';

@Component({
    selector: 'calendar',
    template: require('./calendar.html'),
    styles: [require('./calendar.scss')],
})
export class CalendarComponent {

    static parameters = [];
    constructor() {}
}
