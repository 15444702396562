import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import SignaturePad from 'signature_pad';
import _ from 'lodash';

@Component({
    selector: 'programs',
    template: require('./programs.html'),
    styles: [require('./programs.scss')],
})
export class ProgramsComponent implements OnInit, OnDestroy {
    title = 'signatureJS';
    signaturePad: SignaturePad;
    @ViewChild('canvas') canvasEl: ElementRef;
    signatureImg: string;
    http;
    SocketService;
    Router;
    defaultImage = '../../assets/images/defaultimage.gif';
    allClasses = [];
    allCosts = [];
    allChildren = [];
    classImagesFormData = [];
    allSelectedCosts = [];
    savedSignature;
    selectedClass = null;
    allSelectedChildren = [];
    showListView = [];
    allSelectedChildrenString = null;
    allSelectedCostsString = null;
    signupstep = 0;
    classFull = false;
    waitList = false;
    loading = true;
    saveLoading = false;
    signatureSaving = false;
    loadArray = [];
    showSignatureHolderBox = false;
    viewform = 0;
    signersDate = new Date();
    signersName = '';
    selectedButtonText = '';

    static parameters = [HttpClient, SocketService, Router, ActivatedRoute];
    constructor(http: HttpClient, socketService: SocketService, router: Router, Route: ActivatedRoute) {
        this.http = http;
        this.SocketService = socketService;
        this.Router = router;
        this.Route = Route;
        this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        if (this.Route.snapshot.queryParams.signupstep) {
            this.signupstep = this.Route.snapshot.queryParams.signupstep;
        }
        if (this.Route.snapshot.queryParams.waitlist) {
            this.waitList = this.Route.snapshot.queryParams.waitlist;
        }
        this.getClasses();
        this.getUserData();
        setTimeout(() => {
            if (this.canvasEl) {
                this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
            } else {
                setTimeout(() => {
                    if (this.canvasEl) {
                        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                    } else {
                        setTimeout(() => {
                            if (this.canvasEl) {
                                this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                            } else {
                                setTimeout(() => {
                                    if (this.canvasEl) {
                                        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                                    } else {
                                        setTimeout(() => {
                                            if (this.canvasEl) {
                                                this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                                            } else {
                                                this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                                            }
                                        }, 1000);
                                    }
                                }, 1000);
                            }
                        }, 1000);
                    }
                }, 1000);
            }
        }, 1000);
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('classObject');
    }

    onInputChanged(inputText) {
        this.selectedButtonText = inputText;
        let modifiedItems = _.filter(this.allClasses, (item) => {
            return item.name.toLowerCase().indexOf(inputText.toLowerCase()) >= 0 || item.daysofweek.toLowerCase().indexOf(inputText.toLowerCase()) >= 0;
        });
        this.allFilteredClasses = modifiedItems;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.canvasEl && this.canvasEl.nativeElement) {
                this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
            }
        }, 1000);
    }
    
    startDrawing(event: Event) {
        // console.log(event);
    }
    
    moved(event: Event) {
        // console.log(event);
    }
    
    clearPad() {
        if (this.canvasEl) {
            this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
        } else {
            setTimeout(() => {
                if (this.canvasEl) {
                    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                } else {
                    setTimeout(() => {
                        if (this.canvasEl) {
                            this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                        } else {
                            setTimeout(() => {
                                if (this.canvasEl) {
                                    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                                } else {
                                    setTimeout(() => {
                                        if (this.canvasEl) {
                                            this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                                        } else {
                                            this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
                                        }
                                    }, 1000);
                                }
                            }, 1000);
                        }
                    }, 1000);
                }
            }, 1000);
        }
        this.signaturePad.clear();
        this.signatureImg = null;
    }
    
    savePad() {
        this.signatureSaving = true;
        if (this.signaturePad._isEmpty) {
            this.messageService('Please Sign Your Name.', 'Signature required!', 'error');
        } else {
            if (!this.signersName) {
                this.messageService('Please Enter Your Name (Print).', 'Print name required!', 'error');
            } else {
                const base64Data = this.signaturePad.toDataURL();
                this.signatureImg = base64Data;
                var signatureObject = {
                    signature: this.signatureImg,
                    parent: this.currentUser.person._id,
                    class: this.selectedClass,
                    forms: [],
                    children: []
                };
                this.allSelectedChildren.forEach(childElement => {
                    signatureObject.children.push(childElement);
                    this.selectedClass.forms.forEach(formElement => {
                        signatureObject.forms.push(formElement);
                    });
                });
                this.http.post('/api/signatures/', signatureObject).subscribe(savedSignatureElement => {
                    this.savedSignature = savedSignatureElement;
                    this.showSignatureHolderBox=!this.showSignatureHolderBox;
                    this.signatureSaving = false;
                });
            }
        }
    }

    getUserData() {
        return this.http.get('/api/users/me').subscribe((user) => {
            this.currentUser = user;
            return this.http.get('/api/students/children/'+this.currentUser.person._id).subscribe((childrenObject) => {
                this.allChildren = childrenObject;
                if (this.Route.snapshot.queryParams.children) {
                    var allSelectedChildrenArray = this.Route.snapshot.queryParams.children.split("_");
                    allSelectedChildrenArray.forEach(selectedChildElement => {
                        var childSelectedIndex = -1; 
                        childSelectedIndex = this.allChildren.map((childElement) => childElement._id).indexOf(selectedChildElement);
                        if (childSelectedIndex !== -1) {
                            this.allSelectedChildren.push(this.allChildren[childSelectedIndex]);
                        }
                    });
                }
                this.loading = false;
                this.SocketService.syncUpdates('childObject', this.allChildren);
            });
        }, error => {
            if (error.statusText=='Unauthorized') {
                this.Router.navigateByUrl('/login');
            }
        });
    }

    getClasses() {
        return this.http.get('/api/classes').subscribe((classObject) => {
            this.allClasses = classObject;
            this.allFilteredClasses = this.allClasses;
            this.allClasses.forEach(classElement => {
                if (classElement._id == this.Route.snapshot.queryParams.class) {
                    this.selectedClass = classElement;
                    this.allCosts = classElement.costs;
                    if (this.Route.snapshot.queryParams.classoptions) {
                        var allSelectedCostsArray = this.Route.snapshot.queryParams.classoptions.split("_");
                        allSelectedCostsArray.forEach(selectedCostElement => {
                            var costSelectedIndex = -1; 
                            costSelectedIndex = this.allCosts.map((costElement) => costElement._id).indexOf(selectedCostElement);
                            if (costSelectedIndex !== -1) {
                                this.allSelectedCosts.push(this.allCosts[costSelectedIndex]);
                            }
                        });
                    }
                    if (this.selectedClass.students.length >= this.selectedClass.size) {
                        this.classFull = true;
                    }
                }
            });
            this.loading = false;
            this.SocketService.syncUpdates('classObject', this.allClasses);
        });
    }

    selectClassCard(classObject) {
        this.Router.navigateByUrl('/programs?class='+classObject._id);
        this.selectedClass = classObject;
    }

    selectChildCard(childObject) {
        var childSelectedIndex = -1;
        childSelectedIndex = this.allSelectedChildren.map((childElement) => childElement).indexOf(childObject);
        if (childSelectedIndex !== -1) {
            this.allSelectedChildren.splice(childSelectedIndex, 1);
        } else {
            if (!this.waitList) {
                if ((this.selectedClass.students.length + this.allSelectedChildren.length) >= this.selectedClass.size) {
                    this.classFull = true;
                    return
                }
            }
            this.allSelectedChildren.push(childObject);
        }
        this.allSelectedChildrenString = null;
        this.allSelectedChildren.forEach(childElement => {
            if (this.allSelectedChildrenString) {
                this.allSelectedChildrenString = this.allSelectedChildrenString + '_' + childElement._id;
            } else {
                this.allSelectedChildrenString = childElement._id;
            }
        });
    }

    startSignup(waitList?) {
        if (waitList) {
            this.Router.navigateByUrl('/programs?class='+this.selectedClass._id+'&signupstep=1'+'&waitlist='+waitList);
        } else {
            this.Router.navigateByUrl('/programs?class='+this.selectedClass._id+'&signupstep=1');
        }
    }

    saveSelectedStudents() {
        var groupOfStudents = [];
        this.allSelectedChildren.forEach(element => {
            var locationIndex = -1;
            locationIndex = this.selectedClass.students.map((item) => item._id).indexOf(element._id);
            if (locationIndex == -1) {
                groupOfStudents.push(element);
            }
        });
        this.allSelectedCostsString = null;
        if (this.selectedClass.costs && this.selectedClass.costs.length > 0) {
            this.selectedClass.costs.forEach(costElement => {
                if (costElement.selected) {
                    if (this.allSelectedCostsString) {
                        this.allSelectedCostsString = this.allSelectedCostsString + '_' + costElement._id;
                    } else {
                        this.allSelectedCostsString = costElement._id;
                    }
                }
            });
        }
        if (!this.waitList) {
            this.selectedClass.students = this.selectedClass.students.concat(groupOfStudents);
            this.Router.navigateByUrl('/programs?class='+this.Route.snapshot.queryParams.class+'&classoptions='+this.allSelectedCostsString+'&children='+this.allSelectedChildrenString+'&signupstep=2');    
        } else {
            this.selectedClass.waitlist = this.selectedClass.waitlist.concat(groupOfStudents);
            this.Router.navigateByUrl('/programs?class='+this.Route.snapshot.queryParams.class+'&classoptions='+this.allSelectedCostsString+'&children='+this.allSelectedChildrenString+'&signupstep=2'+'&waitlist='+this.waitList);
        }
    }

    checkLoadDone() {
        // console.log(this.loadArray);
        if (this.loadArray.length < 1) {
            setTimeout(() => {
                this.saveLoading = false;
                this.messageService('Classes booked!', 'Success!', 'success');
                window.scroll(0,0);
                this.Router.navigateByUrl('/programs?class='+this.Route.snapshot.queryParams.class+'&classoptions='+this.Route.snapshot.queryParams.classoptions+'&children='+this.allSelectedChildrenString+'&signupstep=3');
            }, 2000);
        }
    }

    saveSignature() {
        this.saveLoading = true;
        this.loadArray = [];
        var allParentSignedForms = [];
        console.log(this.allSelectedChildren);
        this.allSelectedChildren.forEach(childElement => {
            console.log(childElement);
            this.selectedClass.forms.forEach(formElement => {
                // childElement.forms = [];
                // this.http.put('/api/students/' + childElement._id, childElement).subscribe(studentObject => {
                //     console.log(studentObject);
                // });
                // this.currentUser.person.forms = [];
                // this.currentUser.person.signatures = [];
                // this.http.put('/api/people/' + this.currentUser.person._id, this.currentUser.person).subscribe(studentObject => {
                //     console.log(studentObject);
                // });
                var personform = {
                    id: '_F-' + formElement._id + '_CL-' + this.selectedClass._id + '_P-' + this.currentUser.person._id + '_CH-' + childElement._id,
                    form: formElement,
                    signature: this.savedSignature._id,
                    status: 'signed',
                    notes: '',
                    class: this.selectedClass,
                    child: childElement._id
                };
                var parentFormLocationIndex = -1;
                parentFormLocationIndex = this.currentUser.person.forms.map((item) => item.id).indexOf(personform.id);
                if (parentFormLocationIndex != -1) {
                    this.currentUser.person.forms[parentFormLocationIndex] = personform;
                } else {
                    allParentSignedForms.push(personform);
                }
                var studentform = {
                    id: 'F-' + formElement._id + '_CL-' + this.selectedClass._id + '_P-' + this.currentUser.person._id + '_CH-' + childElement._id,
                    form: formElement,
                    signature: this.savedSignature._id,
                    status: 'signed',
                    notes: '',
                    class: this.selectedClass,
                    parent: this.currentUser.person._id
                };
                var childFormLocationIndex = -1;
                childFormLocationIndex = childElement.forms.map((item) => item.id).indexOf(studentform.id);
                if (childFormLocationIndex != -1) {
                    childElement.forms[childFormLocationIndex] = studentform;
                } else {
                    childElement.forms.push(studentform);
                }
                if (this.waitList) {
                    var classWaitListLocationIndex = -1;
                    classWaitListLocationIndex = this.selectedClass.waitlist.map((item) => item._id).indexOf(childElement._id);
                    if (classWaitListLocationIndex == -1 && this.waitList) {
                        this.selectedClass.waitlist.push(childElement._id);
                    } else {
                        console.log('Waitlist already has child.');
                    }
                    var studentLocationIndex = -1;
                    studentLocationIndex = childElement.waitlists.map((item) => item._id).indexOf(this.selectedClass._id);
                    if (studentLocationIndex == -1) {
                        var childClassHistoryItem = {
                            status: 'Added To Waitlist',
                            class: this.selectedClass._id,
                            bywho: this.currentUser.person._id
                        };
                        childElement.classhistory.push(childClassHistoryItem);
                        childElement.waitlists.push(this.selectedClass);
                    } else {
                        console.log('Child already has waitlist.');
                    }
                } else {
                    var classLocationIndex = -1;
                    classLocationIndex = this.selectedClass.students.map((item) => item._id).indexOf(childElement._id);
                    if (classLocationIndex == -1) {
                        this.selectedClass.students.push(childElement._id);
                    } else {
                        console.log('Class Already Has Child');
                    }
                    const studentLocationIndex = childElement.classes.map((item) => item._id).indexOf(this.selectedClass._id);
                    if (studentLocationIndex == -1) {
                        var childClassHistoryItem = {
                            status: 'Added To Class',
                            class: this.selectedClass._id,
                            bywho: this.currentUser.person._id
                        };
                        childElement.classhistory.push(childClassHistoryItem);
                        childElement.classes.push(this.selectedClass);
                    } else {
                        console.log('Child Already Has Class');
                    }
                }
                var newLoad1 = {
                    id: childElement._id
                };
                this.loadArray.push(newLoad1);
                // console.log(childElement);
                if (childElement.parent && childElement.parent._id) {
                    childElement.parent = childElement.parent._id
                }
                this.http.put('/api/students/' + childElement._id, childElement).subscribe(studentObject => {
                    var newLoadIndex = -1;
                    newLoadIndex = this.loadArray.map((item) => item.id).indexOf(studentObject.id);
                    if (newLoadIndex == -1) {
                        this.loadArray.splice(newLoadIndex, 1);
                    }
                    this.checkLoadDone();
                });
                var newLoad2 = {
                    id: this.selectedClass._id
                };
                this.loadArray.push(newLoad2);
                this.http.put('/api/classes/' + this.selectedClass._id, this.selectedClass).subscribe(classObject => {
                    var newLoadIndex = -1;
                    newLoadIndex = this.loadArray.map((item) => item.id).indexOf(classObject.id);
                    if (newLoadIndex == -1) {
                        this.loadArray.splice(newLoadIndex, 1);
                    }
                    this.checkLoadDone();
                });
            });
        });
        if (allParentSignedForms.length > 0) {
            this.currentUser.person.forms = this.currentUser.person.forms.concat(allParentSignedForms);
        }
        if (this.savedSignature) {
            this.currentUser.person.signatures.push(this.savedSignature._id);
        }
        var newLoad = {
            id: this.currentUser.person._id
        };
        this.loadArray.push(newLoad);
        this.http.put('/api/people/' + this.currentUser.person._id, this.currentUser.person).subscribe(userObject => {
            var newLoadIndex = -1;
            newLoadIndex = this.loadArray.map((item) => item.id).indexOf(userObject.id);
            if (newLoadIndex == -1) {
                this.loadArray.splice(newLoadIndex, 1);
            }
            this.checkLoadDone();
        });
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteClass(item);
                    this.messageService('The class has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message).then(() => {
                this.signatureSaving = false;
            });
        }
    }
}
