import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import Swal from 'sweetalert2';

@Component({
    selector: 'class',
    template: require('./class.html'),
    styles: [require('./class.scss')],
})
export class ClassComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    Router;
    defaultImage = '../../../../assets/images/defaultimage.gif';
    allImages = [];
    allClasses = [];
    allTeachers = [];
    allSchools = [];
    allStudents = [];
    allCosts = [];
    allForms = [];
    allRolls = [];
    allPickedupLists = [];
    allpickedup = [];
    allpickuplists = [];
    allFilteredPickupLists = [];
    filterRollSummary = [];
    newClass = {};
    newCost = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    classImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    displayStudents = false;
    displayCosts = false;
    displayCostsForm = false;
    displayForms = false;
    displayFormsForm = false;
    imagetype = "";
    showClass = false;
    showStudents = false;
    showRoll = false;
    showRollSummary = false;
    showOldRoll = false;
    showOldPickup = false;
    showWaitinglist = false;
    showUniformlist = false;
    showPickuplist = false;
    showallpickuplists = false;
    showClassesWaitingLists = false;
    showPickupListView = false;
    showAddNewRollBlank = false;
    addNewRollBlankDate = (new Date().getFullYear()) + "-" + (new Date().getMonth() + 1) + "-" + String(new Date().getDate()).padStart(2, '0');
    selectedClassObject = [];
    selectedRoll;
    selectedPickup;
    selectedDate = new Date(new Date().toDateString());
    today = new Date(new Date().toDateString());
    yesterday = new Date("" + this.today.getFullYear() + "-" + (this.today.getMonth() + 1) + "-" + (this.today.getDate() - 1) + "");
    oldDays = this.today;
    currentStudents = '';
    newDate1 = new Date(new Date('01/01/1935').toDateString()).getTime();
    newDate2 = new Date(new Date().toDateString()).getTime();
    newPickupDate1 = new Date(new Date('01/01/1935').toDateString()).getTime();
    newPickupDate2 = new Date(new Date().toDateString()).getTime();
    selectedDateIsToday = true;
    loading = true;
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    rollSummaryMonth = new Date().getMonth();
    workbook = new Workbook();

    static parameters = [HttpClient, SocketService, AuthService, Router, ActivatedRoute];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService, router: Router, Route: ActivatedRoute) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
        this.Router = router;
        this.Route = Route;
        this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        // this.getRollsData();
        this.getUserData();
        this.getStudents();
        this.getSchools();
        this.getTeachers();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('classObject');
    }

    downloadExcel() {
        var worksheet = this.workbook.addWorksheet('ZionsEliteClassData-'+new Date().valueOf());
        var excelHeader=["Class Name", "Days of Week", "School Name", "Student Name", "Parent Name", "Class Tuition"];
        var excelHeaderRow = worksheet.addRow(excelHeader);
        var excelFileName = 'ZionsEliteInvoiceData-'+new Date().valueOf()+'.xlsx';
        this.allClasses.forEach(classElement => {
            classElement.students.forEach(childElement => {
                var excelRow = [];
                excelRow.push(classElement.name ? classElement.name : 'NO NAME FOUND');
                excelRow.push(classElement.daysofweek ? classElement.daysofweek : 'NO DAYS OF WEEK FOUND');
                excelRow.push(childElement.school ? childElement.school.name : 'NO SCHOOL NAME FOUND');
                excelRow.push(childElement.firstname && childElement.lastname ? childElement.firstname + ' ' + childElement.lastname : 'NO CHILD NAME FOUND');
                excelRow.push(childElement.parent.firstname + ' ' +  childElement.parent.lastname);
                excelRow.push(classElement.tuition ? classElement.tuition : 'NO TUITION FOUND');
                worksheet.addRow(excelRow);
            });
        });
        this.workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, excelFileName);
        });
    }

    getRollsData() {
        this.http.get('/api/rolls/').subscribe((allRollsObject) => {
            allRollsObject.forEach(rollElement => {
                var needSave = false;
                rollElement.rollitems.forEach(studentElement => {
                    if (studentElement.here && studentElement.missing) {
                        studentElement.missing = null;
                        needSave = true;
                    }
                });
                // if (needSave) {
                //     this.http.put('/api/rolls/' + rollElement._id, rollElement).subscribe();
                // }
            });
        });
    }

    testFunction() {
        var allBadObjects = [];
        var allBadObjects2 = [];
        this.http.get('/api/classes/').subscribe((allClassesObject) => {
            var allClassesFoundOriginal = allClassesObject;
            this.http.get('/api/students/').subscribe((allStudentsObject) => {
                    allClassesObject.forEach(classElement => {
                        classElement.students.forEach(studentInClassElement => {
                            var itemIndex = -1; 
                            itemIndex = studentInClassElement.classes.map((itemElement) => itemElement).indexOf(classElement._id);
                            if (itemIndex == -1) {
                                // studentInClassElement.classes.push(classElement._id);
                                // this.http.put('/api/students/' + studentInClassElement._id, studentInClassElement).subscribe(studentObject => {
                                //     console.log('Student: ' + studentInClassElement.firstname + ' ' + studentInClassElement.lastname + ' Updated to now have the class: ' + classElement.name);
                                // });
                                var test = {
                                    classInfo: 'class: ' + classElement.name + ' Days: ' + classElement.daysofweek + ' Times: ' + classElement.starttime + ' ' + classElement.endtime,
                                    studentInfo: 'Student: ' + studentInClassElement.firstname + ' ' + studentInClassElement.lastname + ' Parent: ' + studentInClassElement.parent.firstname + ' ' + studentInClassElement.parent.lastname
                                };
                                allBadObjects.push(test);
                            } else { 
                                console.log('student found class that class has student');
                            }
                        });
                    });
                console.log(allBadObjects);

                allStudentsObject.forEach(studentInClassElement3 => {
                    studentInClassElement3.classes.forEach(classElement3 => {
                        var itemIndex3 = -1; 
                        itemIndex3 = allClassesFoundOriginal.map((itemElement3) => itemElement3._id).indexOf(classElement3._id);
                        if (itemIndex3 != -1) {
                            var itemIndex4 = -1;
                            var itemIndex4 = allClassesFoundOriginal[itemIndex3].students.map((itemElement4) => itemElement4._id).indexOf(studentInClassElement3._id);
                            if (itemIndex4 == -1) {
                                var itemIndex5 = -1;
                                var itemIndex5 = studentInClassElement3.classes.map((itemElement4) => itemElement4._id).indexOf(classElement3._id);
                                if (itemIndex5 != -1) {
                                    // studentInClassElement3.classes.splice(itemIndex5, 1);
                                    // this.http.put('/api/students/' + studentInClassElement3._id, studentInClassElement3).subscribe(studentObject => {
                                    //     console.log('Student: ' + studentInClassElement3.firstname + ' ' + studentInClassElement3.lastname + ' removed from the class: ' + classElement3.name);
                                    // });
                                }
                                var test = {
                                    classInfo: 'class: ' + allClassesFoundOriginal[itemIndex3].name + ' Days: ' + allClassesFoundOriginal[itemIndex3].daysofweek + ' Times: ' + allClassesFoundOriginal[itemIndex3].starttime + ' ' + allClassesFoundOriginal[itemIndex3].endtime,
                                    studentInfo: 'Student: ' + studentInClassElement3.firstname + ' ' + studentInClassElement3.lastname + ' Parent: ' + studentInClassElement3.parent.firstname + ' ' + studentInClassElement3.parent.lastname
                                };
                                allBadObjects2.push(test);
                            } else {
                                console.log('class found student that student has class');
                            }
                        } else {
                            console.log('no class with that id???');
                        }
                    });
                });
                console.log(allBadObjects2);
            });
        });
    }

    getUserData() {
        // this.testFunction();
        return this.http.get('/api/users/me').subscribe((user) => {
            this.currentUser = user;
            if (this.currentUser.type=='all') {
                this.http.get('/api/classes/').subscribe((classObject) => {
                    this.allClasses = classObject;
                    this.allpickuplists = [];
                    var all = [];
                    this.allClasses.forEach(thisClassElement => {
                        thisClassElement.pickuplist = [];
                        thisClassElement.uniformlist = [];
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Monday")) {
                            thisClassElement.daysofweek0 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Tuesday")) {
                            thisClassElement.daysofweek1 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Wednesday")) {
                            thisClassElement.daysofweek2 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Thursday")) {
                            thisClassElement.daysofweek3 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Friday")) {
                            thisClassElement.daysofweek4 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Saturday")) {
                            thisClassElement.daysofweek5 = true;
                        }
                        var today = new Date().getTime();
                        var startDay = new Date(thisClassElement.startdate).getTime();
                        var endDay = new Date(thisClassElement.enddate).getTime();
                        if (thisClassElement.pickups && thisClassElement.pickups.length>0 && (today >= startDay) && (endDay >= today)) {
                            thisClassElement.pickups.forEach(pickupElement => {
                                const studentIndex = thisClassElement.students.map((item) => item._id).indexOf(pickupElement);
                                if (studentIndex !== -1) {
                                    if (!thisClassElement.pickuplist) {
                                        thisClassElement.pickuplist = [];
                                    }
                                    thisClassElement.pickuplist.push(thisClassElement.students[studentIndex]);
                                    var thisStudent = {
                                        _id: thisClassElement.students[studentIndex]._id,
                                        class: thisClassElement,
                                        firstname: thisClassElement.students[studentIndex].firstname,
                                        lastname: thisClassElement.students[studentIndex].lastname,
                                        parent: thisClassElement.students[studentIndex].parent,
                                        school: thisClassElement.students[studentIndex].school,
                                        image: thisClassElement.students[studentIndex].image,
                                        schoolteacher: thisClassElement.students[studentIndex].schoolteacher,
                                        hall: thisClassElement.students[studentIndex].schoolteacher ? thisClassElement.students[studentIndex].schoolteacher.hall ? thisClassElement.students[studentIndex].schoolteacher.hall : '' : '',
                                    };
                                    this.allpickuplists.push(thisStudent);
                                }
                            });
                            this.allFilteredPickupLists = this.allpickuplists;
                            this.sortByKey(this.allpickuplists, 'schoolteacher');
                            this.sortByKey(this.allpickuplists, 'day');
                        }
                        if (thisClassElement.uniforms && thisClassElement.uniforms.length>0) {
                            thisClassElement.uniforms.forEach(uniformElement => {
                                const studentIndex = thisClassElement.students.map((item) => item._id).indexOf(uniformElement);
                                if (studentIndex !== -1) {
                                    if (!thisClassElement.uniformlist) {
                                        thisClassElement.uniformlist = [];
                                    }
                                    thisClassElement.uniformlist.push(thisClassElement.students[studentIndex]);
                                }
                            });
                        }
                        if (isNaN(thisClassElement.tuition)) {
                            thisClassElement.tuition = thisClassElement.tuition.replace(/\D+/g, '');;
                        }
                        // thisClassElement.students.forEach(studentElement => {
                        //     var needSave = false;
                        //     if (studentElement.classhistory.length> 0) {
                        //         var allclassids = [];
                        //         var allgoodelements = [];
                        //         var allbadelements = [];
                        //         studentElement.classhistory.forEach(historyElement => {
                        //             if (allclassids.includes(historyElement.class)) {
                        //                 allbadelements.push(historyElement);
                        //             } else {
                        //                 allclassids.push(historyElement.class);
                        //                 allgoodelements.push(historyElement);
                        //             }
                        //         });
                        //         console.log(allbadelements);
                        //         allbadelements.forEach(element => {
                        //             var removeIndex = -1;
                        //             removeIndex = studentElement.classhistory.map((item) => item._id).indexOf(element._id);
                        //             if (removeIndex != -1) {
                        //                 studentElement.classhistory.splice(removeIndex, 1);
                        //                 needSave = true;
                        //             }
                        //         });
                        //     }
                        //     if (needSave) {
                        //         this.http.put('/api/students/' + studentElement._id, studentElement).subscribe();
                        //     }
                        // });
                        // thisClassElement.students.forEach(studentElement => {
                        //     var needSave = false;
                        //     if (studentElement.classhistory.length < studentElement.classes.length) {
                        //         if (studentElement.classhistory.length >= 1) {
                        //             var foundIndex = -1;
                        //             foundIndex = studentElement.classhistory.map((item) => item.class).indexOf(thisClassElement._id);
                        //             if (foundIndex == -1) {
                        //                 var studentClassHistoryItem = {
                        //                     status: 'Added To Class Start Date - Program Generated',
                        //                     class: thisClassElement._id,
                        //                     bywho: this.currentUser.person._id,
                        //                     date: thisClassElement.startdate
                        //                 };
                        //                 needSave = true;
                        //                 console.log('pushed 1');
                        //                 studentElement.classhistory.push(studentClassHistoryItem);
                        //             }
                        //             // if (studentElement.classhistory[0].class != thisClassElement._id) {
                        //             //     var one = 'Student: ' + studentElement.firstname + ' ' + studentElement.lastname + 'Class: ' + thisClassElement.name;
                        //             //     all.push(one);
                        //             // }
                        //         } else {
                        //             var one = 'Student: ' + studentElement.firstname + ' ' + studentElement.lastname + 'Class: ' + thisClassElement.name;
                        //             all.push(one);
                        //         }
                        //     }
                        //     if (needSave) {
                        //         this.http.put('/api/students/' + studentElement._id, studentElement).subscribe();
                        //     }
                        // });
                        // thisClassElement.students.forEach(studentElement => {
                        //     var needSave = false;
                        //     if (studentElement.forms.length > 0) {
                        //         studentElement.forms.forEach(formElement => {
                        //             if (formElement.class == thisClassElement._id) {
                        //                 var studentClassHistoryItem = {
                        //                     status: 'Added To Class - Program Generated',
                        //                     class: formElement.class,
                        //                     bywho: formElement.parent,
                        //                     date: formElement.date
                        //                 };
                        //                 needSave = true;
                        //                 studentElement.classhistory.push(studentClassHistoryItem);
                        //             }
                        //         });
                        //     }
                        //     if (needSave) {
                        //         this.http.put('/api/students/' + studentElement._id, studentElement).subscribe();
                        //     }
                        // });
                    });
                    console.log(all);
                    if (this.Route.snapshot.queryParams.allpickuplists) {
                        this.oldPickupDays = 'loading . . .';
                        this.http.delete('/api/pickups/23424234234').subscribe((pickupObject) => {
                        });
                        this.http.get('/api/pickups/').subscribe((pickupObject) => {
                            console.log(pickupObject);
                            this.allPickedupLists = pickupObject;
                            if (this.allPickedupLists.length>0) {
                                this.newPickupDate1 = new Date(new Date(this.allPickedupLists[0].day).toDateString()).getTime();
                                this.newPickupDate2 = new Date(new Date(this.today).toDateString()).getTime();
                                if (this.newPickupDate1 == this.newPickupDate2) {
                                    this.showOldPickup = true;
                                    this.selectedPickup = this.allPickedupLists[0];
                                    this.changePickupDate(this.allPickedupLists[0]._id);
                                } else {
                                    this.oldPickupDays = this.today
                                }
                            } else {
                                this.oldPickupDays = this.today
                            }
                        });
                    }
                    if (this.Route.snapshot.queryParams.class) {
                        var classSelectedIndex = -1; 
                        classSelectedIndex = this.allClasses.map((classElement) => classElement._id).indexOf(this.Route.snapshot.queryParams.class);
                        if (classSelectedIndex !== -1) {
                            this.selectedClassObject = this.allClasses[classSelectedIndex];
                            this.showClass = true;
                            this.http.get('/api/rolls/class/' + this.selectedClassObject._id).subscribe((rollObject) => {
                                this.allRolls = rollObject;
                                if (this.allRolls.length>0) {
                                    this.newDate1 = new Date(new Date(this.allRolls[0].day).toDateString()).getTime();
                                    this.newDate2 = new Date(new Date(this.today).toDateString()).getTime();
                                    if (this.newDate1 == this.newDate2) {
                                        this.showOldRoll = true;
                                        this.selectedRoll = this.allRolls[0];
                                        this.changeDate(this.allRolls[0]._id);
                                    }
                                    this.filterRollSummary = [];
                                    var studentGroup = {};
                                    this.allRolls.forEach(rollElement => {
                                        if (new Date(rollElement.day).getMonth() == new Date().getMonth()) {
                                            this.filterRollSummary.push(rollElement);
                                            rollElement.rollitems.forEach(studentElement => {
                                                if (!studentGroup[studentElement.student._id]) {
                                                    studentGroup[studentElement.student._id] = [];
                                                }
                                                studentGroup[studentElement.student._id].push(studentElement);
                                            });
                                        }
                                    });
                                    this.selectedClassObject.students.forEach(studentElement => {
                                        studentElement.totalhere = 0;
                                        studentElement.totallate = 0;
                                        studentElement.totalmissing = 0;
                                        studentElement.totalnouniform = 0;
                                    });
                                    Object.keys(studentGroup).forEach(student => {
                                        var totalhere = 0;
                                        var totallate = 0;
                                        var totalmissing = 0;
                                        var totalnouniform = 0;
                                        studentGroup[student].forEach(rollItemElement => {
                                            if (rollItemElement.here) {
                                                totalhere = +totalhere + +1;
                                            }
                                            if (rollItemElement.late) {
                                                totallate = +totallate + +1;
                                            }
                                            if (rollItemElement.missing) {
                                                totalmissing = +totalmissing + +1;
                                            }
                                            if (rollItemElement.nouniform) {
                                                totalnouniform = +totalnouniform + +1;
                                            }
                                        });
                                        this.selectedClassObject.students.forEach(studentElement => {
                                            if (studentElement._id == student) {
                                                studentElement.totalhere = totalhere;
                                                studentElement.totallate = totallate;
                                                studentElement.totalmissing = totalmissing;
                                                studentElement.totalnouniform = totalnouniform;
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    }
                    if (this.Route.snapshot.queryParams.roll) {
                        this.showRoll = this.Route.snapshot.queryParams.roll;
                    }
                    if (this.Route.snapshot.queryParams.waitinglist) {
                        this.showWaitinglist = this.Route.snapshot.queryParams.waitinglist;
                    }
                    if (this.Route.snapshot.queryParams.uniformlist) {
                        this.showUniformlist = this.Route.snapshot.queryParams.uniformlist;
                    }
                    if (this.Route.snapshot.queryParams.pickuplist) {
                        this.showPickuplist = this.Route.snapshot.queryParams.pickuplist;
                    }
                    if (this.Route.snapshot.queryParams.allpickuplists) {
                        this.showallpickuplists = this.Route.snapshot.queryParams.allpickuplists;
                    }
                    this.loading = false;
                });
                this.getForms(this.currentUser.type);
                this.getCosts(this.currentUser.type);
            } else {
                this.http.get('/api/classes/teacher/'+this.currentUser.person._id).subscribe((classObject) => {
                    this.allClasses = classObject;
                    this.allClasses.forEach(thisClassElement => {
                        thisClassElement.pickuplist = [];
                        thisClassElement.uniformlist = [];
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Monday")) {
                            thisClassElement.daysofweek0 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Tuesday")) {
                            thisClassElement.daysofweek1 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Wednesday")) {
                            thisClassElement.daysofweek2 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Thursday")) {
                            thisClassElement.daysofweek3 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Friday")) {
                            thisClassElement.daysofweek4 = true;
                        }
                        if (thisClassElement.daysofweek && thisClassElement.daysofweek.includes("Saturday")) {
                            thisClassElement.daysofweek5 = true;
                        }
                        var today = new Date().getTime();
                        var startDay = new Date(thisClassElement.startdate).getTime();
                        var endDay = new Date(thisClassElement.enddate).getTime();
                        if (thisClassElement.pickups && thisClassElement.pickups.length>0 && (today >= startDay) && (endDay >= today)) {
                            thisClassElement.pickups.forEach(pickupElement => {
                                const studentIndex = thisClassElement.students.map((item) => item._id).indexOf(pickupElement);
                                if (studentIndex !== -1) {
                                    if (!thisClassElement.pickuplist) {
                                        thisClassElement.pickuplist = [];
                                    }
                                    thisClassElement.pickuplist.push(thisClassElement.students[studentIndex]);
                                    var thisStudent = {
                                        _id: thisClassElement.students[studentIndex]._id,
                                        class: thisClassElement,
                                        firstname: thisClassElement.students[studentIndex].firstname,
                                        lastname: thisClassElement.students[studentIndex].lastname,
                                        parent: thisClassElement.students[studentIndex].parent,
                                        school: thisClassElement.students[studentIndex].school,
                                        image: thisClassElement.students[studentIndex].image,
                                        schoolteacher: thisClassElement.students[studentIndex].schoolteacher,
                                        hall: thisClassElement.students[studentIndex].schoolteacher ? thisClassElement.students[studentIndex].schoolteacher.hall ? thisClassElement.students[studentIndex].schoolteacher.hall : '' : '',
                                    };
                                    this.allpickuplists.push(thisStudent);
                                }
                            });
                            this.allFilteredPickupLists = this.allpickuplists;
                            this.sortByKey(this.allpickuplists, 'schoolteacher');
                            this.sortByKey(this.allpickuplists, 'day');
                        }
                        if (thisClassElement.uniforms && thisClassElement.uniforms.length>0) {
                            thisClassElement.uniforms.forEach(uniformElement => {
                                const studentIndex = thisClassElement.students.map((item) => item._id).indexOf(uniformElement);
                                if (studentIndex !== -1) {
                                    if (!thisClassElement.uniformlist) {
                                        thisClassElement.uniformlist = [];
                                    }
                                    thisClassElement.uniformlist.push(thisClassElement.students[studentIndex]);
                                }
                            });
                        }
                    });
                    if (this.Route.snapshot.queryParams.class) {
                        var classSelectedIndex = -1; 
                        classSelectedIndex = this.allClasses.map((classElement) => classElement._id).indexOf(this.Route.snapshot.queryParams.class);
                        if (classSelectedIndex !== -1) {
                            this.selectedClassObject = this.allClasses[classSelectedIndex];
                            this.showClass = true;
                            this.http.get('/api/rolls/class/' + this.selectedClassObject._id).subscribe((rollObject) => {
                                this.allRolls = rollObject;
                                if (this.allRolls.length>0) {
                                    this.newDate1 = new Date(new Date(this.allRolls[0].day).toDateString()).getTime();
                                    this.newDate2 = new Date(new Date(this.today).toDateString()).getTime();
                                    if (this.newDate1 == this.newDate2) {
                                        this.showOldRoll = true;
                                        this.selectedRoll = this.allRolls[0];
                                        this.changeDate(this.allRolls[0]._id);
                                    }
                                    this.filterRollSummary = [];
                                    var studentGroup = {};
                                    this.allRolls.forEach(rollElement => {
                                        if (new Date(rollElement.day).getMonth() == new Date().getMonth()) {
                                            this.filterRollSummary.push(rollElement);
                                            rollElement.rollitems.forEach(studentElement => {
                                                if (!studentGroup[studentElement.student._id]) {
                                                    studentGroup[studentElement.student._id] = [];
                                                }
                                                studentGroup[studentElement.student._id].push(studentElement);
                                            });
                                        }
                                    });
                                    this.selectedClassObject.students.forEach(studentElement => {
                                        studentElement.totalhere = 0;
                                        studentElement.totallate = 0;
                                        studentElement.totalmissing = 0;
                                        studentElement.totalnouniform = 0;
                                    });
                                    Object.keys(studentGroup).forEach(student => {
                                        var totalhere = 0;
                                        var totallate = 0;
                                        var totalmissing = 0;
                                        var totalnouniform = 0;
                                        studentGroup[student].forEach(rollItemElement => {
                                            if (rollItemElement.here) {
                                                totalhere = +totalhere + +1;
                                            }
                                            if (rollItemElement.late) {
                                                totallate = +totallate + +1;
                                            }
                                            if (rollItemElement.missing) {
                                                totalmissing = +totalmissing + +1;
                                            }
                                            if (rollItemElement.nouniform) {
                                                totalnouniform = +totalnouniform + +1;
                                            }
                                        });
                                        this.selectedClassObject.students.forEach(studentElement => {
                                            if (studentElement._id == student) {
                                                studentElement.totalhere = totalhere;
                                                studentElement.totallate = totallate;
                                                studentElement.totalmissing = totalmissing;
                                                studentElement.totalnouniform = totalnouniform;
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    }
                    if (this.Route.snapshot.queryParams.roll) {
                        this.showRoll = this.Route.snapshot.queryParams.roll;
                    }
                    if (this.Route.snapshot.queryParams.waitinglist) {
                        this.showWaitinglist = this.Route.snapshot.queryParams.waitinglist;
                    }
                    if (this.Route.snapshot.queryParams.uniformlist) {
                        this.showUniformlist = this.Route.snapshot.queryParams.uniformlist;
                    }
                    if (this.Route.snapshot.queryParams.pickuplist) {
                        this.showPickuplist = this.Route.snapshot.queryParams.pickuplist;
                    }
                    if (this.Route.snapshot.queryParams.allpickuplists) {
                        this.showallpickuplists = this.Route.snapshot.queryParams.allpickuplists;
                    }
                    this.loading = false;
                });
                this.getForms(this.currentUser.type);
                this.getCosts(this.currentUser.type);
            }
            this.SocketService.syncUpdates('classObject', this.allClasses);
            
        }, error => {
            if (error.statusText=='Unauthorized') {
                this.Router.navigateByUrl('/login');
            }
        });
    }

    closeClass() {
        this.Router.navigateByUrl('/classes');
    }

    getSchools() {
        return this.http.get('/api/schools').subscribe((schools) => {
            this.allSchools = schools;
            this.SocketService.syncUpdates('schools', this.allSchools);
        });
    }

    onInputChanged(inputText) {
        let modifiedItems = _.filter(this.allpickuplists, (item) => {
            return item.class.daysofweek.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
        });
        this.allFilteredPickupLists = modifiedItems;
    }

    getTeachers() {
        return this.http.get('/api/people').subscribe((teachers) => {
            teachers.forEach(teacher => {
                if (teacher.link) {
                    if (teacher.link.type=='teacher' || teacher.link.type=='all') {
                        this.allTeachers.push(teacher);
                    }
                }
            });
            this.SocketService.syncUpdates('teachers', this.allTeachers);
        });
    }

    getStudents() {
        return this.http.get('/api/students').subscribe((students) => {
            this.allStudents = students;
            this.SocketService.syncUpdates('students', this.allStudents);
        });
    }

    getCosts(userType) {
        if (userType == 'all') {
            this.http.get('/api/costs/').subscribe((costs) => {
                this.allCosts = costs;
                this.SocketService.syncUpdates('costs', this.allCosts);
                if (this.newClass.costs) {
                    this.newClass.costs.forEach(cost => {
                        const allCostsLocationIndex = this.allCosts.map((item) => item._id).indexOf(cost._id);
                        if (allCostsLocationIndex !== -1) {
                            this.allCosts.splice(allCostsLocationIndex, 1);
                        }
                    });
                }
            });
        } else {
            this.http.get('/api/costs/teacher/' + this.currentUser._id ).subscribe((costs) => {
                this.allCosts = costs;
                this.SocketService.syncUpdates('costs', this.allCosts);
                if (this.newClass.costs) {
                    this.newClass.costs.forEach(cost => {
                        const allCostsLocationIndex = this.allCosts.map((item) => item._id).indexOf(cost._id);
                        if (allCostsLocationIndex !== -1) {
                            this.allCosts.splice(allCostsLocationIndex, 1);
                        }
                    });
                }
            });
        }
    }

    getForms(userType) {
        this.http.get('/api/forms/').subscribe((forms) => {
            this.allForms = forms;
            this.SocketService.syncUpdates('forms', this.allForms);
            if (this.newClass.forms) {
                this.newClass.forms.forEach(form => {
                    const allFormsLocationIndex = this.allForms.map((item) => item._id).indexOf(form._id);
                    if (allFormsLocationIndex !== -1) {
                        this.allForms.splice(allFormsLocationIndex, 1);
                    }
                });
            }
        });
        // if (userType == 'all') {
        //     this.http.get('/api/forms/').subscribe((forms) => {
        //         this.allForms = forms;
        //         this.SocketService.syncUpdates('forms', this.allForms);
        //         if (this.newClass.forms) {
        //             this.newClass.forms.forEach(form => {
        //                 const allFormsLocationIndex = this.allForms.map((item) => item._id).indexOf(form._id);
        //                 if (allFormsLocationIndex !== -1) {
        //                     this.allForms.splice(allFormsLocationIndex, 1);
        //                 }
        //             });
        //         }
        //     });
        // } else {
        //     this.http.get('/api/forms/teacher/'+ this.currentUser._id).subscribe((forms) => {
        //         this.allForms = forms;
        //         this.SocketService.syncUpdates('forms', this.allForms);
        //         if (this.newClass.forms) {
        //             this.newClass.forms.forEach(form => {
        //                 const allFormsLocationIndex = this.allForms.map((item) => item._id).indexOf(form._id);
        //                 if (allFormsLocationIndex !== -1) {
        //                     this.allForms.splice(allFormsLocationIndex, 1);
        //                 }
        //             });
        //         }
        //     });
        // }
    }

    savePickups() {
        this.loading = true;
        if (this.allpickedup.length > 0) {
            var pickupsReady = [];
            this.allpickedup.forEach(pickupElement => {
                var allThisPickupData = [];
                this.allpickuplists.forEach(pickupDataElement => {
                    if (pickupDataElement._id == pickupElement) {
                        allThisPickupData.push(pickupDataElement);
                    }
                });
                if (allThisPickupData.length > 0) {
                    var pickup = {
                        student: pickupElement
                    };
                    pickupsReady.push(pickup);
                }
            });
            var tempDateWithTime = new Date(new Date(this.today).toDateString());
            var pickupForDatabase = {
                pickedupby: this.currentUser.person,
                pickupitems: pickupsReady,
                day: (+tempDateWithTime.getMonth() + +1)+ '-' + tempDateWithTime.getDate() + '-' + tempDateWithTime.getFullYear()
            };
            if (!this.showOldPickup) {
                return this.http.post('/api/pickups', pickupForDatabase).subscribe(pickupObject => {
                    this.loading = false;
                    this.messageService('Pickup Has Been Saved.', 'Success!', 'success');
                });
            } else {
                return this.http.put('/api/pickups/'+this.selectedPickup._id, pickupForDatabase).subscribe(pickupObject => {
                    this.loading = false;
                    this.messageService('Pickup Has Been Saved.', 'Success!', 'success');
                });
            }
        } else {
            this.loading = false;
            this.messageService('Please Select The Student Checkbox', 'Erro!', 'error');
        }
    }

    addClass() {
        this.loading = true;
        if (this.SocketService.imageUploader) {
            this.newClass.image = this.SocketService.imageUploader;
        }
        var weekDays = '';
        var weekDayGroup = [];
        if (this.newClass.daysofweek0) {
            weekDays = weekDays + 'Mondays';
            weekDayGroup.push(this.newClass.daysofweek0);
        }
        if (this.newClass.daysofweek1) {
            if (weekDayGroup.length > 0) {
                weekDays = weekDays + ' & Tuesdays';
            } else {
                weekDays = weekDays + 'Tuesdays';
            }
            weekDayGroup.push(this.newClass.daysofweek1);
        }
        if (this.newClass.daysofweek2) {
            if (weekDayGroup.length > 0) {
                weekDays = weekDays + ' & Wednesdays';
            } else {
                weekDays = weekDays + 'Wednesdays';
            }
            weekDayGroup.push(this.newClass.daysofweek2);
        }
        if (this.newClass.daysofweek3) {
            if (weekDayGroup.length > 0) {
                weekDays = weekDays + ' & Thursdays';
            } else {
                weekDays = weekDays + 'Thursdays';
            }
            weekDayGroup.push(this.newClass.daysofweek3);
        }
        if (this.newClass.daysofweek4) {
            if (weekDayGroup.length > 0) {
                weekDays = weekDays + ' & Fridays';
            } else {
                weekDays = weekDays + 'Fridays';
            }
            weekDayGroup.push(this.newClass.daysofweek4);
        }
        if (this.newClass.daysofweek5) {
            if (weekDayGroup.length > 0) {
                weekDays = weekDays + ' & Saturdays';
            } else {
                weekDays = weekDays + 'Saturdays';
            }
            weekDayGroup.push(this.newClass.daysofweek5);
        }
        this.newClass.daysofweek = weekDays;
        if (this.newClass.name) {
            if (this.newClass._id) {
                return this.http.put('/api/classes/' + this.newClass._id, this.newClass).subscribe(classObject => {
                    this.getUserData();
                    this.clearout();
                    this.messageService('Class has been modified.', 'Success!', 'success');
                });
            } else {
                if (!this.currentUser.type=='all') {
                    this.newClass.teacher = this.currentUser.person;
                } else {
                    if (!this.newClass.teacher) {
                        this.newClass.teacher = this.currentUser.person;
                    }
                }
                return this.http.post('/api/classes', this.newClass).subscribe(classObject => {
                    const locationIndex = this.allClasses.map((item) => item._id).indexOf(classObject._id);
                    if (locationIndex !== -1) {
                        this.allClasses[locationIndex].image = this.newClass.image;
                    }
                    this.getUserData();
                    this.clearout();
                    this.messageService('Class has been added.', 'Success!', 'success');
                });
            }
        }
    }

    deleteClass(classObject) {
        return this.http.delete(`/api/classes/${classObject._id}`).subscribe(() => {
            this.getUserData();
            this.messageService('Class has been deleted.', 'Success!', 'success');
        });
    }

    deleteCost(costObject) {
        return this.http.delete(`/api/costs/${costObject._id}`).subscribe(() => {
            this.getUserData();
        });
    }

    confirmDelete(object, type) {
        this.messageService('Are you sure you want to delete this ' + type + '?', 'Are you sure?', 'delete', object, type);
    }

    confirmRemoveStudent(studentObject) {
        this.messageService('Are you sure you want to remove this student?', 'Are you sure?', 'remove', studentObject, 'student');
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    chooseStudents() {
        this.displayStudents = !this.displayStudents;
        this.getStudents();
    }

    chooseCosts() {
        this.displayCosts = !this.displayCosts;
    }

    chooseForms() {
        this.displayForms = !this.displayForms;
    }

    getImages() {
        return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newClass.image = image;
        this.displayImages = false;
    }

    selectStudent(student) {
        if (!this.newClass.students) {
            this.newClass.students = [];
        }
        const locationIndex = this.newClass.students.map((item) => item._id).indexOf(student._id);
        if (locationIndex !== -1) {
            this.newClass.students[locationIndex] = student;
        } else {
            this.newClass.students.push(student);
        }
    }

    selectCost(cost) {
        if (!this.newClass.costs) {
            this.newClass.costs = [];
        }
        const locationIndex = this.newClass.costs.map((item) => item._id).indexOf(cost._id);
        if (locationIndex !== -1) {
            this.newClass.costs.splice(locationIndex, 1);
            this.allCosts.push(cost);
        } else {
            const allCostsLocationIndex = this.allCosts.map((item) => item._id).indexOf(cost._id);
            if (allCostsLocationIndex !== -1) {
                this.newClass.costs.push(cost);
                this.allCosts.splice(allCostsLocationIndex, 1);
            }
        }
    }

    selectForm(form) {
        if (!this.newClass.forms) {
            this.newClass.forms = [];
        }
        const locationIndex = this.newClass.forms.map((item) => item._id).indexOf(form._id);
        if (locationIndex !== -1) {
            this.newClass.forms.splice(locationIndex, 1);
            this.allForms.push(form);
        } else {
            const allFormsLocationIndex = this.allForms.map((item) => item._id).indexOf(form._id);
            if (allFormsLocationIndex !== -1) {
                this.newClass.forms.push(form);
                this.allForms.splice(allFormsLocationIndex, 1);
            }
        }
    }

    addCost(cost) {
        if (!cost.owner) {
            cost.owner = this.currentUser._id;
        }
        return this.http.post('/api/costs', cost).subscribe(costObject => {
            const locationIndex = this.allCosts.map((item) => item._id).indexOf(costObject._id);
            if (locationIndex !== -1) {
                this.allCosts[locationIndex] = costObject;
            } else {
                this.allCosts.push(costObject);
            }
            this.newCost = {};
            this.messageService('Cost has been added.', 'Success!', 'success');
        });
    }

    viewClass(classObject) {
        this.Router.navigateByUrl('/classes?class='+classObject._id);
    }

    addNewRollBlank() {
        this.showAddNewRollBlank = false;
        var newRollDate = true;
        this.allRolls.forEach(roll => {
            if (new Date((new Date(roll.day).getFullYear()) + "-" + (new Date(roll.day).getMonth() + 1) + "-" + String(new Date(roll.day).getDate()).padStart(2, '0')).getTime() == new Date(this.addNewRollBlankDate).getTime()) {
                newRollDate = false;
            }
        });
        if (newRollDate) {
            var rollItems = [];
            var rollObject = {
                type: 'classRoll',
                status: 'saved',
                class: this.selectedClassObject._id,
                teacher: this.selectedClassObject.teacher,
                owner: this.currentUser._id,
            };
            this.selectedClassObject.students.forEach(studentElement => {
                var rollItem = {
                    student: studentElement._id,
                    parent: studentElement.parent,
                    here: studentElement.here ? studentElement.here : '',
                    late: studentElement.late ? studentElement.late : '',
                    missing: studentElement.missing ? studentElement.missing : '',
                    nouniform: studentElement.nouniform ? studentElement.nouniform : '',
                    attitude: studentElement.attitude ? studentElement.attitude : '',
                    leftclass: studentElement.leftclass ? studentElement.leftclass : '',
                };
                rollItems.push(rollItem);
            });
            var tempDate = this.addNewRollBlankDate.replace(/-/g, '/');
            rollObject.day = new Date(new Date(tempDate).toDateString());
            rollObject.rollitems = rollItems;
            this.http.post('/api/rolls', rollObject).subscribe(savedRollObject => {
                this.allRolls.push(savedRollObject);
                this.messageService('Roll has been added (REFRESHING PAGE).', 'Success!', 'success');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
        } else {
            this.messageService('This Roll Already Exists.', 'Error!', 'error');
        }
    }

    runInvoices(classObject) {
        console.log(classObject);
    }

    goToRoll(classObject) {
        this.Router.navigateByUrl('/classes?class='+classObject._id+'&roll=true');
    }

    viewRoll(toggle) {
        if (toggle) {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id+'&roll='+toggle);
        } else {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id);
        }
    }

    viewWaitinglist(toggle) {
        if (toggle) {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id+'&waitinglist='+toggle);
        } else {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id);
        }
    }

    viewUniformlist(toggle) {
        if (toggle) {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id+'&uniformlist='+toggle);
        } else {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id);
        }
    }

    viewPickuplist(toggle) {
        if (toggle) {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id+'&pickuplist='+toggle);
        } else {
            this.Router.navigateByUrl('/classes?class='+this.selectedClassObject._id);
        }
    }

    viewAllPickuplists(toggle) {
        if (toggle) {
            this.Router.navigateByUrl('/classes?allpickuplists='+toggle);
        } else {
            this.Router.navigateByUrl('/classes');
        }
    }

    setPickedUp(student) {
        let pickupIndex = -1;
        if (student._id) {
            pickupIndex = this.allpickedup.map((item) => item).indexOf(student._id);
        } else {
            pickupIndex = this.allpickedup.map((item) => item).indexOf(student);
        }
        if (pickupIndex !== -1) {
            this.allpickedup.splice(pickupIndex, 1);
        } else {
            if (student._id) {
                this.allpickedup.push(student._id);
            } else {
                this.allpickedup.push(student);
            }
        }
    }

    sortByKey(data, key) {
        let sortedData;
        if (key == 'child'){
            sortedData = data.sort((a,b) => {
                let x = a.lastname.toLowerCase();
                let y = b.lastname.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'hall'){
            sortedData = data.sort((a,b) => {
                if (a.hall && b.hall) {
                    let x = a.hall.toLowerCase();
                    let y = b.hall.toLowerCase();
                    if (x>y) {
                        return 1;
                    }
                    if (x<y) {
                        return -1;
                    }
                    return 0;
                }
                if (a.hall && !b.hall) {
                    return -1;
                }
                if (!a.hall && b.hall) {
                    return 1;
                }
                if (!a.hall && !b.hall) {
                    return 0;
                }
            });
        } else if (key == 'parent') {
            sortedData = data.sort((a,b) => {
                let x = a.parent.firstname.toLowerCase();
                let y = b.parent.firstname.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'school'){
            sortedData = data.sort((a,b) => {
                let x = a.school.name.toLowerCase();
                let y = b.school.name.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'day') {
            sortedData = data.sort((a,b) => {
                let x = a.class.daysofweek.toLowerCase();
                let y = b.class.daysofweek.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        } else if (key == 'schoolteacher'){
            sortedData = data.sort((a,b) => {
                if (a.schoolteacher && b.schoolteacher) {
                    if (a.schoolteacher.lastname && b.schoolteacher.lastname) {
                        let x = a.schoolteacher.lastname.toLowerCase();
                        let y = b.schoolteacher.lastname.toLowerCase();
                        if (x>y) {
                            return 1;
                        }
                        if (x<y) {
                            return -1;
                        }
                        return 0;
                    }
                }
                if (a.schoolteacher && !b.schoolteacher) {
                    return -1;
                }
                if (!a.schoolteacher && b.schoolteacher) {
                    return 1;
                }
                if (!a.schoolteacher && !b.schoolteacher) {
                    return 0;
                }
            });
        } else {
            sortedData = data.sort((a,b) => {
                let x = a.class.name.toLowerCase();
                let y = b.class.name.toLowerCase();
                if (x>y) {
                    return 1;
                }
                if (x<y) {
                    return -1;
                }
                return 0;
            });
        }
        return sortedData;
    }

    getAllIndexes(arr, val) {
        var indexes = [], i = -1;
        while ((i = arr.indexOf(val, i+1)) != -1){
            indexes.push(i);
        }
        return indexes;
    }

    removeStudentFromWaitingList(student, added?) {
        var studentIndexes = this.getAllIndexes(student.waitlists, this.selectedClassObject._id);
        var classIndexes = this.getAllIndexes(this.selectedClassObject.waitlist, student);
        studentIndexes.forEach(studentElement => {
            student.waitlists.splice(studentElement, 1);
        });
        var studentClassHistoryItem = {
            status: 'Removed From Waitlist',
            class: this.selectedClassObject._id,
            bywho: this.currentUser.person._id
        };
        student.classhistory.push(studentClassHistoryItem);
        this.http.put('/api/students/' + student._id, student).subscribe(studentObject => {
            this.loading = false;
            classIndexes.forEach(classElement => {
                this.selectedClassObject.waitlist.splice(classElement, 1);
            });
            this.http.put('/api/classes/' + this.selectedClassObject._id, this.selectedClassObject).subscribe(classObject => {
                this.loading = false;
                if (added) {
                    this.messageService('Student has been added.', 'Success!', 'success');
                }
            });
        });
    }

    removeStudentFromUniformList(student, classObject) {
        const uniformIndex = classObject.uniforms.map((item) => item).indexOf(student._id);
        if (uniformIndex !== -1) {
            classObject.uniforms.splice(uniformIndex, 1);
        }
        const uniformListIndex = classObject.uniformlist.map((item) => item._id).indexOf(student._id);
        if (uniformListIndex !== -1) {
            classObject.uniformlist.splice(uniformListIndex, 1);
        }
        this.http.put('/api/classes/' + classObject._id, classObject).subscribe(savedClassObject => {
            classObject = savedClassObject;
            this.messageService('Student has been removed.', 'Success!', 'success');
        });
    }

    removeStudentFromPickupList(student, classObject) {
        const pickupIndex = classObject.pickups.map((item) => item).indexOf(student._id);
        if (pickupIndex !== -1) {
            classObject.pickups.splice(pickupIndex, 1);
        }
        const pickupListIndex = classObject.pickuplist.map((item) => item._id).indexOf(student._id);
        if (pickupListIndex !== -1) {
            classObject.pickuplist.splice(pickupListIndex, 1);
        }
        this.http.put('/api/classes/' + classObject._id, classObject).subscribe(savedClassObject => {
            classObject = savedClassObject;
            this.messageService('Student has been removed.', 'Success!', 'success');
        });
    }

    changePickupDate(event) {
        this.allpickedup = [];
        if (new Date(event) != 'Invalid Date') {
            this.showOldPickup = false;
            this.selectedDate = new Date(new Date(this.today).toDateString());
            this.oldPickupDays = this.today;
            this.selectedDateIsToday = true;
        } else {
            this.selectedDateIsToday = false;
            this.allPickedupLists.forEach(element => {
                if (event == element._id) {
                    this.selectedPickup = element
                    this.showOldPickup = true;
                    this.selectedDate = new Date(new Date(element.day).toDateString());
                    this.oldPickupDays = event;
                    element.pickupitems.forEach(element => {
                        this.setPickedUp(element.student);
                    });
                }
            });
        }
    }

    changeDate(event) {
        if (new Date(event) != 'Invalid Date') {
            this.showOldRoll = false;
            this.selectedDate = new Date(new Date(this.today).toDateString());
            this.oldDays = this.today;
            this.selectedDateIsToday = true;
        } else {
            this.selectedDateIsToday = false;
            this.allRolls.forEach(element => {
                if (event == element._id) {
                    this.selectedRoll = element
                    this.showOldRoll = true;
                    this.selectedDate = new Date(new Date(element.day).toDateString());
                    this.oldDays = event;
                }
            });
        }
    }

    changeAddedStudent(event) {
        this.currentStudents = event;
    }

    changeRollSummaryMonth(event) {
        this.filterRollSummary = [];
        var studentGroup = {};
        this.rollSummaryMonth = event;
        this.allRolls.forEach(rollElement => {
            if (new Date(rollElement.day).getMonth() == event) {
                this.filterRollSummary.push(rollElement);
                rollElement.rollitems.forEach(studentElement => {
                    if (studentElement.student && studentElement.student._id) {
                        if (!studentGroup[studentElement.student._id]) {
                            studentGroup[studentElement.student._id] = [];
                        }
                        studentGroup[studentElement.student._id].push(studentElement);
                    } else {
                        console.log(studentElement);
                    }
                });
            }
        });
        this.selectedClassObject.students.forEach(studentElement => {
            studentElement.totalhere = 0;
            studentElement.totallate = 0;
            studentElement.totalmissing = 0;
            studentElement.totalnouniform = 0;
        });
        Object.keys(studentGroup).forEach(student => {
            var totalhere = 0;
            var totallate = 0;
            var totalmissing = 0;
            var totalnouniform = 0;
            studentGroup[student].forEach(rollItemElement => {
                if (rollItemElement.here) {
                    totalhere = +totalhere + +1;
                }
                if (rollItemElement.late) {
                    totallate = +totallate + +1;
                }
                if (rollItemElement.missing) {
                    totalmissing = +totalmissing + +1;
                }
                if (rollItemElement.nouniform) {
                    totalnouniform = +totalnouniform + +1;
                }
            });
            this.selectedClassObject.students.forEach(studentElement => {
                if (studentElement._id == student) {
                    studentElement.totalhere = totalhere;
                    studentElement.totallate = totallate;
                    studentElement.totalmissing = totalmissing;
                    studentElement.totalnouniform = totalnouniform;
                }
            });
        });
    }

    markRoll(element, status) {
        if (status == 'here') {
            if (element['missing']) {
                element['missing'] = null;
            }
        }
        if (status == 'late') {
            if (!element['here']) {
                element['here'] = 1;
            }
            if (element['missing']) {
                element['missing'] = null;
            }
        }
        if (status == 'missing') {
            if (element['here']) {
                element['here'] = null;
            }
            if (element['late']) {
                element['late'] = null;
            }
        }
        if (!element[status]) {
            element[status] = 1;
        } else {
            element[status] = null;
        }
    }

    approveRoll(status) {
        this.loading = true;
        this.selectedClassObject.rollapproved = status;
        this.http.put('/api/classes/' + this.selectedClassObject._id, this.selectedClassObject).subscribe(classObject => {
            this.loading = false;
            console.log(classObject);
            this.messageService('ClassRoll has been updated.', 'Success!', 'success');
        });
    }

    deleteRoll() {
        if (this.showOldRoll) {
            console.log(this.selectedRoll);
            this.http.delete('/api/rolls/'+this.selectedRoll._id).subscribe(() => {
                this.messageService('Roll has been deleted (REFRESHING PAGE).', 'Success!', 'success');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
        }
    }

    saveRoll() {
        var rollItems = [];
        var rollObject = {
            type: 'classRoll',
            status: 'saved',
            class: this.selectedClassObject._id,
            teacher: this.selectedClassObject.teacher,
            owner: this.currentUser._id,
        };
        if (!this.showOldRoll) {
            this.selectedClassObject.students.forEach(studentElement => {
                var rollItem = {
                    student: studentElement._id,
                    parent: studentElement.parent,
                    here: studentElement.here ? studentElement.here : '',
                    late: studentElement.late ? studentElement.late : '',
                    missing: studentElement.missing ? studentElement.missing : '',
                    nouniform: studentElement.nouniform ? studentElement.nouniform : '',
                    attitude: studentElement.attitude ? studentElement.attitude : '',
                    leftclass: studentElement.leftclass ? studentElement.leftclass : '',
                };
                rollItems.push(rollItem);
            });
            rollObject.day = new Date(new Date(this.today).toDateString());
            rollObject.rollitems = rollItems;
            return this.http.post('/api/rolls', rollObject).subscribe(savedRollObject => {
                this.messageService('Class roll has been saved.', 'Success!', 'success');
                this.getUserData();
                this.getStudents();
                this.getSchools();
                this.getTeachers();
            });
        } else {
            this.selectedRoll.rollitems.forEach(rollElement => {
                var rollItem = {
                    student: rollElement.student._id,
                    parent: rollElement.parent._id,
                    here: rollElement.here ? rollElement.here : '',
                    late: rollElement.late ? rollElement.late : '',
                    missing: rollElement.missing ? rollElement.missing : '',
                    nouniform: rollElement.nouniform ? rollElement.nouniform : '',
                    attitude: rollElement.attitude ? rollElement.attitude : '',
                    leftclass: rollElement.leftclass ? rollElement.leftclass : '',
                };
                rollItems.push(rollItem);
            });
            rollObject.day = new Date(new Date(this.selectedDate).toDateString());
            rollObject.rollitems = rollItems;
            return this.http.put('/api/rolls/'+this.selectedRoll._id, rollObject).subscribe(savedRollObject => {
                this.messageService('Class roll has been saved.', 'Success!', 'success');
                this.getUserData();
                this.getStudents();
                this.getSchools();
                this.getTeachers();
            });
        }
    }

    removeStudent(student) {
        console.log(student);
        this.loading = true;
        var saveStudent = false;
        var saveClass = false;
        var selectedClassObjectLocationIndex = -1;
        selectedClassObjectLocationIndex = this.allClasses.map((selectedClassObjectElement) => selectedClassObjectElement._id).indexOf(this.selectedClassObject._id);
        if (selectedClassObjectLocationIndex != -1) {
            var locationIndex = -1;
            locationIndex = this.allClasses[selectedClassObjectLocationIndex].students.map((item) => item._id).indexOf(student._id);
            if (locationIndex != -1) {
                this.allClasses[selectedClassObjectLocationIndex].students.splice(locationIndex, 1);
                saveClass = true;
            }
            var studentKeepClasses = [];
            student.classes.forEach(loopElement => {
                if(typeof loopElement === 'object' && loopElement !== null) {
                    if (loopElement._id != this.allClasses[selectedClassObjectLocationIndex]._id) {
                        studentKeepClasses.push(loopElement);
                    } else {
                        saveStudent = true;
                    }
                } else {
                    if (loopElement != this.allClasses[selectedClassObjectLocationIndex]._id) {
                        studentKeepClasses.push(loopElement);
                    } else {
                        saveStudent = true;
                    }
                }
            });
            if (saveStudent) {
                var studentClassHistoryItem = {
                    status: 'Removed From Class',
                    class: this.selectedClassObject._id,
                    bywho: this.currentUser.person._id
                };
                student.classhistory.push(studentClassHistoryItem);
            }
            student.classes = studentKeepClasses;
            if (saveStudent && saveClass) {
                this.http.put('/api/students/' + student._id, student).subscribe(studentObject => {
                    this.http.put('/api/classes/' + this.allClasses[selectedClassObjectLocationIndex]._id, this.allClasses[selectedClassObjectLocationIndex]).subscribe(classObject => {
                        this.getUserData();
                        this.showStudents = false;
                        this.messageService('Student has been removed.', 'Success!', 'success');
                        this.loading = false;
                    });
                });
            }
            if (saveStudent && !saveClass) {
                this.http.put('/api/students/' + student._id, student).subscribe(studentObject => {
                    this.getUserData();
                    this.showStudents = false;
                    this.messageService('Student has been removed.', 'Success!', 'success');
                    this.loading = false;
                });
            }
            if (saveClass && !saveStudent) {
                this.http.put('/api/classes/' + this.allClasses[selectedClassObjectLocationIndex]._id, this.allClasses[selectedClassObjectLocationIndex]).subscribe(classObject => {
                    this.getUserData();
                    this.showStudents = false;
                    this.messageService('Student has been removed.', 'Success!', 'success');
                    this.loading = false;
                });
            }
        } else {
            console.log('Class not Found');
        }
    }

    addStudentToRoll(student, roll) {
        var locationIndex = -1;
        locationIndex = this.selectedClassObject.students.map((studentItem) => studentItem._id).indexOf(student);
        if (locationIndex != -1) {
            var locationInRollIndex = -1;
            locationInRollIndex = roll.rollitems.map((rollItem) => rollItem.student._id).indexOf(student);
            if (locationInRollIndex == -1) {
                var rollItem = {
                    attitude: "",
                    here: "",
                    late: "",
                    leftclass: "",
                    missing: "",
                    nouniform: "",
                    parent: this.selectedClassObject.students[locationIndex].parent,
                    student: this.selectedClassObject.students[locationIndex]
                };
                roll.rollitems.push(rollItem);
                return this.http.post('/api/rolls', roll).subscribe(savedRollObject => {
                    this.messageService('Student has been added to roll.', 'Success!', 'success');
                    this.getUserData();
                    this.getStudents();
                    this.getSchools();
                    this.getTeachers();
                });
            } else {
                this.messageService('This Student Is Already In This Class.', 'Error!', 'error');
            }
        }
    }

    addStudent(student, waitlist?) {
        this.loading = true;
        var saveStudent = false;
        var saveClass = false;
        var locationIndex = -1;
        locationIndex = this.selectedClassObject.students.map((item) => item._id).indexOf(student._id);
        if (locationIndex == -1) {
            this.selectedClassObject.students.push(student);
            saveClass = true;
        }
        var studentLocationIndex = -1;
        studentLocationIndex = student.classes.map((item) => { if (typeof item === 'object' && item !== null) {if ('_id' in item) { return item._id }} else { return item}}).indexOf(this.selectedClassObject._id);
        if (studentLocationIndex == -1) {
            var studentClassHistoryItem = {
                status: 'Added To Class',
                class: this.selectedClassObject._id,
                bywho: this.currentUser.person._id
            };
            student.classhistory.push(studentClassHistoryItem);
            student.classes.push(this.selectedClassObject._id);
            saveStudent = true;
        }
        if (saveStudent && saveClass) {
            this.http.put('/api/students/' + student._id, student).subscribe(studentObject => {
                this.http.put('/api/classes/' + this.selectedClassObject._id, this.selectedClassObject).subscribe(classObject => {
                    if (!waitlist) {
                        this.getUserData();
                        this.showStudents = false;
                        this.loading = false;
                        this.messageService('Student has been added.', 'Success!', 'success');
                    }
                    if (waitlist) {
                        this.removeStudentFromWaitingList(student, true);
                    }
                });
            });
        }
        if (saveStudent && !saveClass) {
            this.http.put('/api/students/' + student._id, student).subscribe(studentObject => {
                if (!waitlist) {
                    this.getUserData();
                    this.showStudents = false;
                    this.loading = false;
                    this.messageService('Student has been added.', 'Success!', 'success');
                }
                if (waitlist) {
                    this.removeStudentFromWaitingList(student, true);
                }
            });
        }
        if (saveClass && !saveStudent) {
            this.http.put('/api/classes/' + this.selectedClassObject._id, this.selectedClassObject).subscribe(classObject => {
                if (!waitlist) {
                    this.getUserData();
                    this.showStudents = false;
                    this.loading = false;
                    this.messageService('Student has been added.', 'Success!', 'success');
                }
                if (waitlist) {
                    this.removeStudentFromWaitingList(student, true);
                }
            });
        }
        if (!saveClass && !saveStudent) {
            if (!waitlist) {
                this.getUserData();
                this.showStudents = false;
                this.loading = false;
                this.messageService('Student already has class.', 'Success!', 'success');
            }
            if (waitlist) {
                this.removeStudentFromWaitingList(student, true);
            }
        }
    }

    clearout() {
        this.newClass = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, objectType?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    if (objectType === 'class') {
                        if (this.currentUser.type=='all') {
                            this.deleteClass(item);
                        } else {
                            this.messageService('Only an admin can delete a class.', 'Error!', 'error');
                        }
                    }
                    if (objectType === 'fee') {
                        this.deleteCost(item);
                    }
                }
            })
        }  else if (type === 'error') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'ok'
            }).then((result) => {
                if (result.value === true) {
                    // this.deleteChild(item);
                    // this.messageService('Your child has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'remove') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, remove it!'
            }).then((result) => {
                if (result.value === true) {
                    this.removeStudent(item);
                    // this.messageService('The student has been removed.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
