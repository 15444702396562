import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSelectedChild',
  pure: false
})

export class GetSelectedChild implements PipeTransform {

    constructor() {}

    transform(child: any, args?: any, allSelectedChildren?: any): any {
        if (args === 'getSelected') {
            return this.getSelected(child, allSelectedChildren);
        } else {
            return child;
        }
    }

    getSelected(child, allSelectedChildren) {
        var childFound = false;
        allSelectedChildren.forEach(childElement => {
            if (child._id === childElement._id) {
                childFound = true;
            }
        });
        return childFound;
    }
  
}
