import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'school',
    template: require('./school.html'),
    styles: [require('./school.scss')],
})
export class SchoolComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allSchools = [];
    newSchool = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    schoolImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        return this.http.get('/api/schools').subscribe((school) => {
            this.allSchools = school;
            this.SocketService.syncUpdates('school', this.allSchools);
        });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('school');
    }

    addSchool() {
        if (this.newSchool.name) {
            if (this.schoolImagesFormData) {
                if (this.schoolImagesFormData.length >= 1) {
                    for (let index = 0; index < this.schoolImagesFormData.length; index++) {
                        if (this.newSchool.images) {
                            this.newSchool.images.push(this.schoolImagesFormData[index]._id);
                        } else {
                            this.newSchool.images = [];
                            this.newSchool.images.push(this.schoolImagesFormData[index]._id);
                        }
                    }
                }
            }
            if (this.newSchool._id) {
                return this.http.put('/api/schools/' + this.newSchool._id, this.newSchool).subscribe(school => {
                    this.clearout();
                    this.messageService('School has been modified.', 'Success!', 'success');
                });
            } else {
                return this.http.post('/api/schools', this.newSchool).subscribe(school => {
                    const locationIndex = this.allSchools.map((item) => item._id).indexOf(school._id);
                    if (locationIndex !== -1) {
                        this.allSchools[locationIndex].image = this.newSchool.image;
                    }
                    this.clearout();
                    this.messageService('School has been added.', 'Success!', 'success');
                });
            }
        }
    }

    deleteSchool(school) {
        return this.http.delete(`/api/schools/${school._id}`)
            .subscribe(() => {
            });
    }

    confirmDelete(school) {
        this.messageService('Are you sure you want to delete this school?', 'Are you sure?', 'delete', school);
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    getImages() {
        return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newSchool.image = image;
        this.displayImages = false;
    }

    clearout() {
        this.newSchool = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteSchool(item);
                    this.messageService('The school has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
