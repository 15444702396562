import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'uploadimages',
    template: require('./uploadimage.html'),
    styles: [require('./uploadimage.scss')],
})
export class UploadImageComponent implements OnInit, OnDestroy {
    SocketService;
    allUploadImages = [];
    newUploadImage = '';
    website = 'http://198.199.110.91';
    uploadimagesFormData = [];
    uploadimageUploadProgress = [];
    uploadimageHolder = "";
    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        // console.log(this.AuthService.getCurrentUserSync());
        // return this.http.get('/api/images').subscribe((uploadimages) => {
        //     uploadimages.forEach(uploadimage => {
        //         uploadimage.path = this.website + uploadimage.path;
        //     });
        //     this.allUploadImages = uploadimages;
        //     this.SocketService.syncUpdates('uploadimage', this.allUploadImages);
        // });

        this.SocketService.imageUploader = null;

        if (this.AuthService.getCurrentUserSync().person) {
            return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((uploadimages) => {
                uploadimages.forEach(uploadimage => {
                    uploadimage.path = this.website + uploadimage.path;
                });
                this.allUploadImages = uploadimages;
                this.SocketService.syncUpdates('uploadimage', this.allUploadImages);
            });
        }
    }


    ngOnDestroy() {
        this.SocketService.unsyncUpdates('uploadimage');
    }

    addUploadImage() {
        if(this.newUploadImage) {
            let text = this.newUploadImage;
            this.newUploadImage = '';

            return this.http.post('/api/images', { name: text }).subscribe(uploadimage => {
                console.log('Added UploadImage:', uploadimage);
            });
        }
    }

    confirmDelete(uploadimage) {
        this.messageService('Are you sure you want to delete this uploadimage?', 'Are you sure?', 'delete', uploadimage);
    }


    deleteUploadImageFile(uploadimage) {
        console.log(uploadimage);
        return this.http.delete(`/api/images/${uploadimage._id}`).subscribe(() => {
            console.log('Deleted UploadImage');
        }, error => {
            console.log(error);
        });
    }


    displayFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
        if (Math. abs(bytes) < thresh) {
          return bytes + ' B';
        }
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
        do {
          bytes /= thresh;
          ++u;
        } while (Math. round(Math. abs(bytes) * r) / r >= thresh && u < units. length - 1);
        return bytes. toFixed(dp) + ' ' + units[u];
      }

    uploadMultiple(event: any) {
        const files: FileList = event.target.files;
        const startIndex = this.uploadimageUploadProgress.length;
        for (let index = (0 + startIndex); index < files.length + startIndex; index++) {
            const fileIndex = index - startIndex;
            const fileData = new FormData();
            this.uploadimageUploadProgress[index] = {
                currentUploadProgressValue: 0,
                fileName: "File Name: " + files[fileIndex].name + " File Size: " + this.displayFileSize(files[fileIndex].size),
                currentUplaodSuccessMessage: '',
                currentUplaodErrorMessage: '',
                currentUploadImageId: '',
                currentUploadImageUrl: ''
            };
            const oldName = files[fileIndex].name
            const newName = Date.now();
            const extension = files[fileIndex].name.substring(files[fileIndex].name.lastIndexOf('.'), files[fileIndex].name.length);
            const currentUploadImage = {
                oldName: oldName,
                newName: newName,
                extension: extension,
                path: '/assets/files/' + newName,
                location: './client/assets/files/' + newName,
                size: files[fileIndex].size,
                type: files[fileIndex].type,
                owner: this.AuthService.getCurrentUserSync()._id + this.AuthService.getCurrentUserSync().role + '-uploadimagespage-addUploadImage',
                ownerid: this.AuthService.getCurrentUserSync().person._id
            };
            fileData.append('body', JSON.stringify(currentUploadImage));
            fileData.append('file', files[fileIndex], newName);
            this.http.post('/api/images/', fileData, {reportProgress: true, observe: 'events', responseType: 'json'}).subscribe(response => {
                this.SocketService.imageUploader = null;

                if (response.type === 3) {
                    this.uploadimageUploadProgress[index].currentUplaodSuccessMessage = "Upload Complete.";
                }
                if (response.type === 1) {
                    const percentDone = Math.round(100 * response.loaded / response.total);
                    this.uploadimageUploadProgress[index].currentUploadProgressValue = percentDone;
                }
                if (response.type === 4) {
                    this.uploadimagesFormData.push(response.body);
                    this.uploadimageUploadProgress[index].currentUploadImageId = response.body._id;
                    this.uploadimageUploadProgress[index].currentUploadImageUrl = response.body.path;
                }
                if (response && response.body) {
                    this.SocketService.imageUploader = response.body;
                }
            }, err => {
              this.uploadimageUploadProgress[index].currentUplaodErrorMessage = err.statusText;
            });
        }
    }

    deleteUploadImage(uploadimageStatus) {
        if (uploadimageStatus.currentUploadImageId) {
            return this.http.delete(`/api/images/${uploadimageStatus.currentUploadImageId}`).subscribe(() => {
                const removeIndex1 = this.uploadimagesFormData.map((item1) => item1._id).indexOf(uploadimageStatus.currentUploadImageId);
                if (removeIndex1 !== -1) {
                    this.uploadimagesFormData.splice(removeIndex1, 1);
                    console.log(this.uploadimagesFormData);
                }
                const removeIndex2 = this.uploadimageUploadProgress.map((item2) => item2.currentUploadImageId).indexOf(uploadimageStatus.currentUploadImageId);
                if (removeIndex2 !== -1) {
                    this.uploadimageUploadProgress.splice(removeIndex2, 1);
                    console.log(this.uploadimageUploadProgress);
                }
            });
        }
    }



    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteUploadImageFile(item);
                    this.messageService('The uploadimage has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
