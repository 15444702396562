import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'exhibit',
    template: require('./exhibit.html'),
    styles: [require('./exhibit.scss')],
})
export class ExhibitComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allExhibits = [];
    allClasses = [];
    newExhibit = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    defaultImage = '../../../../assets/images/defaultimage.gif';
    exhibitImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    showAddExhibitItem = false;
    imagetype = "";
    newExhibitItem = {
        title: '',
        description: '',
        file: null,
        class: '',
        students: []
    };
    studentsObject = {};
    selectedClass = {};
    selectedExhibitToDeleteFrom = {};

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        this.http.get('/api/exhibits').subscribe((exhibit) => {
            this.allExhibits = exhibit;
            this.SocketService.syncUpdates('exhibit', this.allExhibits);
        });
        this.http.get('/api/classes').subscribe((classItem) => {
            this.allClasses = classItem;
            console.log(classItem);
            this.SocketService.syncUpdates('class', this.allClasses);
        });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('exhibit');
        this.SocketService.unsyncUpdates('class');
    }

    changeClass(event) {
        this.newExhibitItem.class = event;
        var foundIndex = -1;
        foundIndex = this.allClasses.map((item) => item._id).indexOf(event);
        if (foundIndex != -1) {
            this.selectedClass = this.allClasses[foundIndex];
        }
    }

    addAllStudentsToNewExhibitItem() {
        this.newExhibitItem.students = [];
        this.selectedClass.students.forEach(element => {
            this.newExhibitItem.students.push(element);
        });
    }

    addStudentToNewExhibitItem() {
        var foundIndex = -1;
        foundIndex = this.selectedClass.students.map((item) => item._id).indexOf(this.studentsObject);
        if (foundIndex != -1) {
            this.newExhibitItem.students.push(this.selectedClass.students[foundIndex]);
        }
    }

    removeStudentFromNewExhibitItem(student) {
        var foundIndex = -1;
        foundIndex = this.newExhibitItem.students.map((item) => item._id).indexOf(student._id);
        if (foundIndex != -1) {
            this.newExhibitItem.students.splice(foundIndex, 1);
        }
    }

    deleteExhibitItem(exhibitItem) {
        var foundIndex = -1;
        var needsSaved = false;
        foundIndex = this.selectedExhibitToDeleteFrom.exhibititems.map((item) => item._id).indexOf(exhibitItem._id);
        if (foundIndex != -1) {
            this.selectedExhibitToDeleteFrom.exhibititems.splice(foundIndex, 1);
            needsSaved = true;
        }
        this.http.put('/api/exhibits/' + this.selectedExhibitToDeleteFrom._id, this.selectedExhibitToDeleteFrom).subscribe(exhibit => {
            this.newExhibitItem = {
                title: '',
                description: '',
                file: null,
                class: '',
                students: []
            };
            this.studentsObject = {};
            this.selectedClass = {};
            this.selectedExhibitToDeleteFrom = {};
            this.messageService('The exhibit item has been deleted (REFRESH NEEDED).', 'Success!', 'success');
        });
    }

    confirmDeleteExhibitItem(exhibitItem, exhibit) {
        this.selectedExhibitToDeleteFrom = exhibit;
        this.messageService('Are you sure you want to delete this exhibit item?', 'Are you sure?', 'deleteitem', exhibitItem);
    }

    saveExhibitItem(exhibit) {
        if (this.SocketService.imageUploader) {
            this.newExhibitItem.file = this.SocketService.imageUploader;
        }
        exhibit.exhibititems.push(this.newExhibitItem);
        this.http.put('/api/exhibits/' + exhibit._id, exhibit).subscribe(exhibit => {
            this.newExhibitItem = {
                title: '',
                description: '',
                file: null,
                class: '',
                students: []
            };
            this.studentsObject = {};
            this.selectedClass = {};
            this.messageService('Exhibititem has been added (REFRESH NEEDED).', 'Success!', 'success');
        });
    }

    addExhibit() {
        if (this.SocketService.imageUploader) {
            this.newExhibit.image = this.SocketService.imageUploader;
        }
        if (this.newExhibit.title) {
            if (this.exhibitImagesFormData) {
                if (this.exhibitImagesFormData.length >= 1) {
                    for (let index = 0; index < this.exhibitImagesFormData.length; index++) {
                        if (this.newExhibit.images) {
                            this.newExhibit.images.push(this.exhibitImagesFormData[index]._id);
                        } else {
                            this.newExhibit.images = [];
                            this.newExhibit.images.push(this.exhibitImagesFormData[index]._id);
                        }
                    }
                }
            }
            if (this.newExhibit._id) {
                return this.http.put('/api/exhibits/' + this.newExhibit._id, this.newExhibit).subscribe(exhibit => {
                    this.clearout();
                    this.messageService('Exhibit has been modified.', 'Success!', 'success');
                });
            } else {
                return this.http.post('/api/exhibits', this.newExhibit).subscribe(exhibit => {
                    const locationIndex = this.allExhibits.map((item) => item._id).indexOf(exhibit._id);
                    if (locationIndex !== -1) {
                        this.allExhibits[locationIndex].image = this.newExhibit.image;
                    }
                    this.clearout();
                    this.messageService('Exhibit has been added.', 'Success!', 'success');
                });
            }
        } else {
            console.log('no title');
        }
    }

    deleteExhibit(exhibit) {
        return this.http.delete(`/api/exhibits/${exhibit._id}`)
            .subscribe(() => {
            });
    }

    confirmDelete(exhibit) {
        this.messageService('Are you sure you want to delete this exhibit?', 'Are you sure?', 'delete', exhibit);
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    getImages() {
        return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newExhibit.image = image;
        this.displayImages = false;
    }

    clearout() {
        this.newExhibit = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteExhibit(item);
                    this.messageService('The exhibit has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'deleteitem') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteExhibitItem(item);
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
