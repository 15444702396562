import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { zip } from 'rxjs';

@Component({
    selector: 'invoices',
    template: require('./invoices.html'),
    styles: [require('./invoices.scss')],
})
export class InvoicesComponent implements OnInit, OnDestroy {
    // http;
    // SocketService;
    // AuthService;
    // Router;
    // allInvoices = [];
    // allFilteredInvoices = [];
    // allPaidInvoices = [];
    // allFilteredPaidInvoices = [];
    // allClasses = [];
    // allMarkedPaidLoader = [];
    // pageSettings = {
    //     loading: false,
    //     modifying: false,
    //     currentObject: {}
    // };
    // searchLoading = false;
    // monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // workbook = new Workbook();

    static parameters = [HttpClient, SocketService, AuthService, Router, ActivatedRoute];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService, router: Router, Route: ActivatedRoute) {
        // this.http = http;
        // this.SocketService = socketService;
        // this.AuthService = authService;
        // this.Router = router;
        // this.Route = Route;
        // this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        // this.getInvoiceData();
    }

    ngOnDestroy() {
        // this.SocketService.unsyncUpdates('invoiceObject');
        // this.SocketService.unsyncUpdates('classObject');
    }

    // downloadExcel() {
    //     var worksheet = this.workbook.addWorksheet('ZionsEliteInvoiceData-'+new Date().valueOf());
    //     var excelHeader=["Month", "School", "Student Name", "Parent Name", "Number of Classes", "Class Title", "Days of Week", "Total Cost Per Child"];
    //     var excelHeaderRow = worksheet.addRow(excelHeader);
    //     var excelFileName = 'ZionsEliteInvoiceData-'+new Date().valueOf()+'.xlsx';
    //     this.allFilteredInvoices.forEach(invoiceElement => {
    //         invoiceElement.children.forEach(childElement => {
    //             var excelRow = [];
    //             excelRow.push(invoiceElement.billdate);
    //             excelRow.push(invoiceElement.school.name);
    //             excelRow.push(childElement.firstname + ' ' + childElement.lastname);
    //             excelRow.push(invoiceElement.parent.firstname + ' ' + invoiceElement.parent.lastname);
    //             excelRow.push(invoiceElement.quantity/invoiceElement.children.length);
    //             excelRow.push(invoiceElement.class ? invoiceElement.class.name : '');
    //             excelRow.push(invoiceElement.class ? invoiceElement.class.daysofweek : '');
    //             excelRow.push(invoiceElement.total/invoiceElement.children.length);
    //             worksheet.addRow(excelRow);
    //         });
    //     });
    //     this.workbook.xlsx.writeBuffer().then((data) => {
    //         let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         fs.saveAs(blob, excelFileName);
    //     });
    // }

    // checkLoading(array1, array2) {
    //     if (array1.length == array2.length) {
    //         setTimeout(() => {
    //             this.searchLoading = false;
    //         }, 300);
    //     } else {
    //         setTimeout(() => {
    //             this.checkLoading(array1, array2);
    //         }, 1000);
    //     }
    // }

    // searchLoadedText(array, searchItem) {
    //     if (searchItem.indexOf(' ') >= 0) {
    //         var searchArrayGroup = searchItem.split(" ");
    //         var search1 = searchArrayGroup[0];
    //         var search2 = searchArrayGroup[1];
    //         var foundItems = _.filter(array, (item) => {
    //             return item.parent.firstname.toLowerCase().indexOf(search1.toLowerCase()) >= 0 && item.parent.lastname.toLowerCase().indexOf(search2.toLowerCase()) >= 0;
    //         });
    //     } else {
    //         var foundItems = _.filter(array, (item) => {
    //             return item.billdate.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.billtype.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.parent.firstname.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.parent.lastname.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.school.name.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item._id.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0;
    //         });
    //     }
    //     return foundItems;
    // }

    // onInputChangedSearch(inputText) {
    //     this.searchInvoiceText = inputText;
    //     this.allFilteredInvoices = this.searchLoadedText(this.allInvoices, this.searchInvoiceText);
    //     this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //         return +a + +b.total;
    //     }, 0);
    //     this.allFilteredPaidInvoices = this.searchLoadedText(this.allPaidInvoices, this.searchInvoiceText);
    //     this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //         return +a + +b.total;
    //     }, 0);
    // }

    // onInputChangedCorporation(inputText) {
    //     this.searchCorporationText = inputText;
    //     if (this.searchMonthText && this.searchMonthText != 'all') {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = [];
    //             let modifiedItems = [];
    //             modifiedItems = _.filter(this.allInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(this.searchMonthText.toLowerCase()) >= 0;
    //             });
    //             this.allFilteredInvoices = modifiedItems;
    //             if (this.searchCorporationText) {
    //                 modifiedItems = _.filter(this.allFilteredInvoices, (item) => {
    //                     if (item.class) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0;
    //                     }
    //                 });
    //                 this.allFilteredInvoices = modifiedItems;
    //             }
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, modifiedItems);
    //             this.allFilteredPaidInvoices = [];
    //             let modifiedPaidItems = [];
    //             modifiedPaidItems = _.filter(this.allPaidInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(this.searchMonthText.toLowerCase()) >= 0
    //             });
    //             this.allFilteredPaidInvoices = modifiedPaidItems;
    //             if (this.searchCorporationText) {
    //                 modifiedPaidItems = _.filter(this.allFilteredPaidInvoices, (item) => {
    //                     if (item.class) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0
    //                     }
    //                 });
    //                 this.allFilteredPaidInvoices = modifiedPaidItems;
    //             }
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, modifiedPaidItems);
    //         }, 300);
    //     } else {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = [];
    //             let modifiedItems = [];
    //             modifiedItems = _.filter(this.allInvoices, (item) => {
    //                 if (item.class) {
    //                     return item.class.corporation.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
    //                 }
    //             });
    //             this.allFilteredInvoices = modifiedItems;
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             let modifiedPaidItems = [];
    //             modifiedPaidItems = _.filter(this.allPaidInvoices, (item) => {
    //                 if (item.class) {
    //                     return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0
    //                 }
    //             });
    //             this.allFilteredPaidInvoices = modifiedPaidItems;
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, modifiedPaidItems);
    //         }, 300);
    //     }
    // }

    // onInputChanged(inputText) {
    //     this.searchMonthText = inputText;
    //     if (inputText == 'all') {
    //         this.searchCorporationText = '';
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = this.allInvoices;
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, this.allInvoices);
    //         }, 300);
    //     } else {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = [];
    //             let modifiedItems = [];
    //             modifiedItems = _.filter(this.allInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(inputText.toLowerCase()) >= 0;
    //             });
    //             this.allFilteredInvoices = modifiedItems;
    //             if (this.searchCorporationText) {
    //                 var startArray = JSON.parse(JSON.stringify(this.allFilteredInvoices));
    //                 modifiedItems = _.filter(startArray, (item) => {
    //                     if (item.class) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0;
    //                     }
    //                 });
    //                 this.allFilteredInvoices = modifiedItems;
    //             }
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, modifiedItems);
    //         }, 300);
    //     }
    //     if (inputText == 'all') {
    //         this.searchCorporationText = '';
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredPaidInvoices = this.allPaidInvoices;
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, this.allPaidInvoices);
    //         }, 300);
    //     } else {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredPaidInvoices = [];
    //             let modifiedPaidItems = [];
    //             modifiedPaidItems = _.filter(this.allPaidInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
    //             });
    //             this.allFilteredPaidInvoices = modifiedPaidItems;
    //             if (this.searchCorporationText) {
    //                 var startPaidArray = JSON.parse(JSON.stringify(this.allFilteredPaidInvoices));
    //                 modifiedPaidItems = _.filter(startPaidArray, (item) => {
    //                     if (item.clas) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0
    //                     }
    //                 });
    //                 this.allFilteredPaidInvoices = modifiedPaidItems;
    //             }
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, modifiedPaidItems);
    //         }, 300);
    //     }
    // }

    // getInvoiceData() {
    //     this.allInvoices = [];
    //     this.totalBalances = 0.00;
    //     this.http.get('/api/invoices/current').subscribe((allInvoicesObject) => {
    //         this.allInvoices = allInvoicesObject;
    //         this.allFilteredInvoices = allInvoicesObject;
    //         this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //             return +a + +b.total;
    //         }, 0);
    //     });
    // }

    // getAllClassesData() {
    //     return this.http.get('/api/classes/current');
    // }
    
    // getRollsForClass(classId) {
    //     return this.http.get('/api/rolls/class/' + classId);
    // }

    // runBilling() {
    //     this.billingLoading = true;
    //     zip(this.getAllClassesData()).subscribe(async ([allClassesObject]) => {
    //         this.allClasses = await this.combineRollsToClasses(allClassesObject);
    //         console.log(this.allClasses);
    //         this.billingLoading = false;
    //     });
    // }

    // combineRollsToClasses(allClassesObject) {
    //     return allClassesObject.reduce((all, classElement) => {
    //         if (classElement.frequency === 'perclass') {
    //             zip(this.getRollsForClass(classElement._id)).subscribe(([rollsForClass]) => {
    //                 if (rollsForClass.length>0) {
    //                     var filterRollSummary = [];
    //                     var studentGroup = {};
    //                     rollsForClass.forEach(rollElement => {
    //                         if (new Date(rollElement.day).getMonth() == new Date().getMonth()) {
    //                             filterRollSummary.push(rollElement);
    //                             rollElement.rollitems.forEach(studentElement => {
    //                                 if (!studentGroup[studentElement.student._id]) {
    //                                     studentGroup[studentElement.student._id] = [];
    //                                 }
    //                                 studentGroup[studentElement.student._id].push(studentElement);
    //                             });
    //                         }
    //                     });
    //                     classElement.students.forEach(studentElement => {
    //                         studentElement.totalhere = 0;
    //                         studentElement.totallate = 0;
    //                         studentElement.totalmissing = 0;
    //                         studentElement.totalnouniform = 0;
    //                         studentElement.totalclasses = rollsForClass.length;
    //                     });
    //                     Object.keys(studentGroup).forEach(student => {
    //                         var totalhere = 0;
    //                         var totallate = 0;
    //                         var totalmissing = 0;
    //                         var totalnouniform = 0;
    //                         studentGroup[student].forEach(rollItemElement => {
    //                             if (rollItemElement.here) {
    //                                 totalhere = +totalhere + +1;
    //                             }
    //                             if (rollItemElement.late) {
    //                                 totallate = +totallate + +1;
    //                             }
    //                             if (rollItemElement.missing) {
    //                                 totalmissing = +totalmissing + +1;
    //                             }
    //                             if (rollItemElement.nouniform) {
    //                                 totalnouniform = +totalnouniform + +1;
    //                             }
    //                         });
    //                         classElement.students.forEach(studentElement => {
    //                             if (studentElement._id == student) {
    //                                 studentElement.totalhere = totalhere;
    //                                 studentElement.totallate = totallate;
    //                                 studentElement.totalmissing = totalmissing;
    //                                 studentElement.totalnouniform = totalnouniform;
    //                                 studentElement.totalclasses = rollsForClass.length;
    //                             }
    //                         });
    //                     });
    //                 }
    //                 all.push(classElement);
    //             });
    //         } else {
    //             all.push(classElement);
    //         }
    //         return all;
    //     }, []);
    // }

    // deleteInvoice(invoiceObject) {
    //     // console.log(this.allTotalInvoices.length);
    //     // this.allTotalInvoices.forEach(element => {
    //     //     this.http.delete(`/api/invoices/${element._id}`).subscribe(() => {
    //     //         // this.getUserData();
    //     //         // this.messageService('Invoice has been deleted.', 'Success!', 'success');
    //     //     });
    //     // });
    //     this.searchMonthText = '';
    //     this.searchCorporationText = '';
    //     this.http.delete(`/api/invoices/${invoiceObject._id}`).subscribe(() => {
    //         this.getUserData();
    //         this.messageService('Invoice has been deleted.', 'Success!', 'success');
    //     });
    // }

    // confirmDeleteAllSelected() {
    //     this.messageService('Are you sure you want to delete all selected invoices?', 'Are you sure?', 'deleteall', '', 'invoiceall');
    // }

    // confirmDelete(invoiceObject, type) {
    //     this.messageService('Are you sure you want to delete this ' + type + '?', 'Are you sure?', 'delete', invoiceObject, type);
    // }

    // confirmRemoveClass(classObject) {
    //     this.messageService('Are you sure you want to remove this class?', 'Are you sure?', 'remove', classObject, 'class');
    // }

    // viewInvoice(invoiceObject) {
    //     this.showInvoice = true;
    //     this.selectedInvoiceObject = invoiceObject;
    // }

    // runSelectAll() {
    //     this.showSelectAll = true;
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     this.allFilteredInvoices.forEach((el)=>{el.checked = true;});
    // }

    // runUnSelectAll() {
    //     this.showSelectAll = true;
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     this.allFilteredInvoices.forEach((el)=>{el.checked = false;});
    //     this.closeRunSelectAll();
    // }

    // runDeleteAllChecked() {
    //     this.searchLoading = true;
    //     this.searchMonthText = '';
    //     this.searchCorporationText = '';
    //     var selectedInvoices = this.allInvoices.filter(obj => {return obj.checked === true;});
    //     selectedInvoices.forEach((element, index) => {
    //         var newLoad = {
    //             id: element.id
    //         };
    //         this.loadArray.push(newLoad);
    //         this.http.delete(`/api/invoices/${element._id}`).subscribe(() => {
    //             var newLoadIndex = -1;
    //             newLoadIndex = this.loadArray.map((item) => item.id).indexOf(element.id);
    //             if (newLoadIndex != -1) {
    //                 this.loadArray.splice(newLoadIndex, 1);
    //             }
    //             this.checkLoadDoneDeleteAll();
    //             this.databaseLoadingNumber -= +1;
    //         });
    //     });
    // }

    // closeRunSelectAll() {
    //     this.showSelectAll = false;
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     // this.allInvoices = newArray;
    // }

    // checkMarkedLoadingDone() {
    //     if (this.allMarkedPaidLoader.length<=0) {
    //         setTimeout(() => {
    //             this.searchLoading = false;
    //         }, 300);
    //     }
    // }

    // runMarkPaid() {
    //     if (this.allFilteredInvoices && this.allFilteredInvoices.length > 0) {
    //         this.searchLoading = true;
    //         var selectedInvoices = this.allFilteredInvoices.filter(obj => {return obj.checked === true;});
    //         var partialPaymentInvoices = this.allFilteredInvoices.filter(obj => {return obj.checked === false;});
    //         var readyToSavePaid = [];
    //         selectedInvoices.forEach(element => {
    //             element.status = "PAID";
    //             this.allPaidInvoices.push(element);
    //             readyToSavePaid.push(element);
    //         });
    //         readyToSavePaid.forEach(invoiceElement => {
    //             var invoiceFoundIndex = -1;
    //             invoiceFoundIndex = this.allInvoices.map((itemElement) => itemElement._id).indexOf(invoiceElement._id);
    //             if (invoiceFoundIndex != -1) {
    //                 this.allInvoices.splice(invoiceFoundIndex, 1);
    //             }
    //             this.allMarkedPaidLoader.push(invoiceElement._id);
    //             this.http.put('/api/invoices/' + invoiceElement._id, invoiceElement).subscribe(invoiceObject => {
    //                 var invoiceFoundInLoaderIndex = -1;
    //                 invoiceFoundInLoaderIndex = this.allMarkedPaidLoader.map((item) => item).indexOf(invoiceObject._id);
    //                 if (invoiceFoundInLoaderIndex !== -1) {
    //                     this.allMarkedPaidLoader.splice(invoiceFoundInLoaderIndex, 1);
    //                     this.checkMarkedLoadingDone();
    //                 }
    //             });
    //         });
    //         this.allInvoices.forEach((el)=>{el.checked = false;});
    //         this.allFilteredInvoices.forEach((el)=>{el.checked = false;});
    //         this.showSelectAll = false;
    //     } else {
    //         this.closeRunSelectAll();
    //     }
    // }

    // setThisCheck(invoiceObject, status) {
    //     invoiceObject.checked = status;
    // }

    // clearout() {
    //     this.newInvoice = {};
    //     this.pageSettings.modifying = false;
    //     this.displayImages = false;
    // }

    // messageService(message, title?, type?, item?, objectType?) {
    //     if (type === 'delete') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, delete it!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 if (objectType === 'invoice') {
    //                     if (this.currentUser.type=='all') {
    //                         this.deleteInvoice(item);
    //                     } else {
    //                         this.messageService('Only an admin can delete an invoice.', 'Error!', 'error');
    //                     }
    //                 }
    //                 if (objectType === 'fee') {
    //                     this.deleteCost(item);
    //                 }
    //             }
    //         })
    //     } else if (type === 'deleteall') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, delete them!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 if (objectType === 'invoiceall') {
    //                     if (this.currentUser.type=='all') {
    //                         this.runDeleteAllChecked();
    //                     } else {
    //                         this.messageService('Only an admin can delete an invoice.', 'Error!', 'error');
    //                     }
    //                 }
    //                 if (objectType === 'fee') {
    //                     this.deleteCost(item);
    //                 }
    //             }
    //         })
    //     } else if (type === 'error') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'ok'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 // this.deleteChild(item);
    //                 // this.messageService('Your child has been deleted.', 'Success!', 'success');
    //             }
    //         })
    //     } else if (type === 'remove') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, remove it!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 this.removeFromClass(item);
    //                 this.messageService('The class has been removed.', 'Success!', 'success');
    //             }
    //         })
    //     } else if (type === 'success') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'success',
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //     } else {
    //         Swal.fire(message);
    //     }
    // }
}