import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import Swal from 'sweetalert2';
import _ from 'lodash';

@Component({
    selector: 'allinvoices',
    template: require('./allinvoices.html'),
    styles: [require('./allinvoices.scss')],
})
export class AllInvoicesComponent implements OnInit, OnDestroy {
    // http;
    // SocketService;
    // AuthService;
    // Router;
    // allImages = [];
    // allInvoices = [];
    // allTotalInvoices = [];
    // allPaidInvoices = [];
    // allFilteredInvoices = [];
    // allFilteredPaidInvoices = [];
    // allClasses = [];
    // allSemesterClasses = [];
    // allCalcPerClass = [];
    // allCalcSemesterClasses = [];
    // allMonthlyClasses = [];
    // allMultiKidDiscount = [];
    // allRolls = [];
    // allSchools = [];
    // allStudents = [];
    // allPeople = [];
    // allBillings = [];
    // newInvoice = {};
    // allGeneratedInvoices = [];
    // allGeneratedDiscountInvoices = [];
    // allGeneratedSchoolInvoices = [];
    // allReadyToRunInvoices = [];
    // corporationInvoiceGroup = {};
    // corporationGroup = {};
    // schoolInvoicing = {};
    // allSchoolInvoices = [];
    // allThisParentChildrenGroupForClasses = [];
    // thisParentChildrenGroupForClass = {};
    // allMarkedPaidLoader = [];
    // pageSettings = {
    //     loading: false,
    //     modifying: false,
    //     currentObject: {}
    // };
    // invoiceImagesInvoiceData = [];
    // imageUploadProgress = [];
    // selectedInvoiceObject = [];
    // loadArray = [];
    // pageNumber = 1;
    // displayImages = false;
    // imagetype = "";
    // searchInvoiceText = "";
    // searchCorporationText = "";
    // searchMonthText = "";
    // showInvoice = false;
    // showPaid = false;
    // showClasses = false;
    // showSelectAll = false;
    // saveLoading = false;
    // showAddPayment = true;
    // showAddDiscount = true;
    // showAddFee = true;
    // showSchoolInvoices = false;
    // billingLoading = false;
    // billReady = false;
    // schoolInvoicesReady = false;
    // searchLoading = false;
    // databaseLoadingNumber = 0;
    // databaseLoadingEstimatedTime = 0;
    // totalBalances = 0.00;
    // totalPaidBalances = 0.00;
    // monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // workbook = new Workbook();

    // static parameters = [HttpClient, SocketService, AuthService, Router, ActivatedRoute];
    // constructor(http: HttpClient, socketService: SocketService, authService: AuthService, router: Router, Route: ActivatedRoute) {
    constructor() {
        // this.http = http;
        // this.SocketService = socketService;
        // this.AuthService = authService;
        // this.Router = router;
        // this.Route = Route;
        // this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        // this.getUserData();
    }

    ngOnDestroy() {
        // this.SocketService.unsyncUpdates('invoiceObject');
        // this.SocketService.unsyncUpdates('classObject');
        // this.SocketService.unsyncUpdates('schoolObject');
        // this.SocketService.unsyncUpdates('studentObject');
    }

    // downloadExcel() {
    //     var worksheet = this.workbook.addWorksheet('ZionsEliteInvoiceData-'+new Date().valueOf());
    //     var excelHeader=["Month", "School", "Student Name", "Parent Name", "Number of Classes", "Class Title", "Days of Week", "Total Cost Per Child"];
    //     var excelHeaderRow = worksheet.addRow(excelHeader);
    //     var excelFileName = 'ZionsEliteInvoiceData-'+new Date().valueOf()+'.xlsx';
    //     this.allFilteredInvoices.forEach(invoiceElement => {
    //         invoiceElement.children.forEach(childElement => {
    //             var excelRow = [];
    //             excelRow.push(invoiceElement.billdate);
    //             excelRow.push(invoiceElement.school.name);
    //             excelRow.push(childElement.firstname + ' ' + childElement.lastname);
    //             excelRow.push(invoiceElement.parent.firstname + ' ' + invoiceElement.parent.lastname);
    //             excelRow.push(invoiceElement.quantity/invoiceElement.children.length);
    //             excelRow.push(invoiceElement.class ? invoiceElement.class.name : '');
    //             excelRow.push(invoiceElement.class ? invoiceElement.class.daysofweek : '');
    //             excelRow.push(invoiceElement.total/invoiceElement.children.length);
    //             worksheet.addRow(excelRow);
    //         });
    //     });
    //     this.workbook.xlsx.writeBuffer().then((data) => {
    //         let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         fs.saveAs(blob, excelFileName);
    //     });
    // }

    // checkLoading(array1, array2) {
    //     if (array1.length == array2.length) {
    //         setTimeout(() => {
    //             this.searchLoading = false;
    //         }, 300);
    //     } else {
    //         setTimeout(() => {
    //             this.checkLoading(array1, array2);
    //         }, 1000);
    //     }
    // }

    // searchLoadedText(array, searchItem) {
    //     if (searchItem.indexOf(' ') >= 0) {
    //         var searchArrayGroup = searchItem.split(" ");
    //         var search1 = searchArrayGroup[0];
    //         var search2 = searchArrayGroup[1];
    //         var foundItems = _.filter(array, (item) => {
    //             return item.parent.firstname.toLowerCase().indexOf(search1.toLowerCase()) >= 0 && item.parent.lastname.toLowerCase().indexOf(search2.toLowerCase()) >= 0;
    //         });
    //     } else {
    //         var foundItems = _.filter(array, (item) => {
    //             return item.billdate.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.billtype.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.parent.firstname.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.parent.lastname.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item.school.name.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0
    //                 || item._id.toLowerCase().indexOf(searchItem.toLowerCase()) >= 0;
    //         });
    //     }
    //     return foundItems;
    // }

    // onInputChangedSearch(inputText) {
    //     this.searchInvoiceText = inputText;
    //     this.allFilteredInvoices = this.searchLoadedText(this.allInvoices, this.searchInvoiceText);
    //     this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //         return +a + +b.total;
    //     }, 0);
    //     this.allFilteredPaidInvoices = this.searchLoadedText(this.allPaidInvoices, this.searchInvoiceText);
    //     this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //         return +a + +b.total;
    //     }, 0);
    // }

    // onInputChangedCorporation(inputText) {
    //     this.searchCorporationText = inputText;
    //     if (this.searchMonthText && this.searchMonthText != 'all') {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = [];
    //             let modifiedItems = [];
    //             modifiedItems = _.filter(this.allInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(this.searchMonthText.toLowerCase()) >= 0;
    //             });
    //             this.allFilteredInvoices = modifiedItems;
    //             if (this.searchCorporationText) {
    //                 modifiedItems = _.filter(this.allFilteredInvoices, (item) => {
    //                     if (item.class) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0;
    //                     }
    //                 });
    //                 this.allFilteredInvoices = modifiedItems;
    //             }
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, modifiedItems);
    //             this.allFilteredPaidInvoices = [];
    //             let modifiedPaidItems = [];
    //             modifiedPaidItems = _.filter(this.allPaidInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(this.searchMonthText.toLowerCase()) >= 0
    //             });
    //             this.allFilteredPaidInvoices = modifiedPaidItems;
    //             if (this.searchCorporationText) {
    //                 modifiedPaidItems = _.filter(this.allFilteredPaidInvoices, (item) => {
    //                     if (item.class) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0
    //                     }
    //                 });
    //                 this.allFilteredPaidInvoices = modifiedPaidItems;
    //             }
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, modifiedPaidItems);
    //         }, 300);
    //     } else {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = [];
    //             let modifiedItems = [];
    //             modifiedItems = _.filter(this.allInvoices, (item) => {
    //                 if (item.class) {
    //                     return item.class.corporation.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
    //                 }
    //             });
    //             this.allFilteredInvoices = modifiedItems;
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             let modifiedPaidItems = [];
    //             modifiedPaidItems = _.filter(this.allPaidInvoices, (item) => {
    //                 if (item.class) {
    //                     return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0
    //                 }
    //             });
    //             this.allFilteredPaidInvoices = modifiedPaidItems;
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, modifiedPaidItems);
    //         }, 300);
    //     }
    // }

    // onInputChanged(inputText) {
    //     this.searchMonthText = inputText;
    //     if (inputText == 'all') {
    //         this.searchCorporationText = '';
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = this.allInvoices;
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, this.allInvoices);
    //         }, 300);
    //     } else {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredInvoices = [];
    //             let modifiedItems = [];
    //             modifiedItems = _.filter(this.allInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(inputText.toLowerCase()) >= 0;
    //             });
    //             this.allFilteredInvoices = modifiedItems;
    //             if (this.searchCorporationText) {
    //                 var startArray = JSON.parse(JSON.stringify(this.allFilteredInvoices));
    //                 modifiedItems = _.filter(startArray, (item) => {
    //                     if (item.class) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0;
    //                     }
    //                 });
    //                 this.allFilteredInvoices = modifiedItems;
    //             }
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, modifiedItems);
    //         }, 300);
    //     }
    //     if (inputText == 'all') {
    //         this.searchCorporationText = '';
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredPaidInvoices = this.allPaidInvoices;
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, this.allPaidInvoices);
    //         }, 300);
    //     } else {
    //         this.searchLoading = true;
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         setTimeout(() => {
    //             this.allFilteredPaidInvoices = [];
    //             let modifiedPaidItems = [];
    //             modifiedPaidItems = _.filter(this.allPaidInvoices, (item) => {
    //                 return item.billdate.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
    //             });
    //             this.allFilteredPaidInvoices = modifiedPaidItems;
    //             if (this.searchCorporationText) {
    //                 var startPaidArray = JSON.parse(JSON.stringify(this.allFilteredPaidInvoices));
    //                 modifiedPaidItems = _.filter(startPaidArray, (item) => {
    //                     if (item.clas) {
    //                         return item.class.corporation.toLowerCase().indexOf(this.searchCorporationText.toLowerCase()) >= 0
    //                     }
    //                 });
    //                 this.allFilteredPaidInvoices = modifiedPaidItems;
    //             }
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredPaidInvoices, modifiedPaidItems);
    //         }, 300);
    //     }
    // }

    // getUserData() {
    //     this.allInvoices = [];
    //     this.allTotalInvoices = [];
    //     this.allPaidInvoices = [];
    //     this.http.get('/api/users/me').subscribe((user) => {
    //         this.currentUser = user;
    //         if (this.currentUser.type=='all' || this.currentUser.type=='hr') {
    //             this.searchLoading = true;
    //             this.http.get('/api/invoices/').subscribe((invoiceObject) => {
    //                 this.allInvoices = [];
    //                 this.allTotalInvoices = invoiceObject;
    //                 this.allPaidInvoices = [];
    //                 this.allFilteredInvoices = [];
    //                 this.allFilteredPaidInvoices = [];
    //                 this.totalBalances = 0.00;
    //                 this.totalPaidBalances = 0.00;
    //                 invoiceObject.forEach(invoiceElement => {
    //                     if (!invoiceElement.total) {
    //                         console.log(invoiceElement);
    //                     }
    //                     var type = this.showSchoolInvoices ? 'School' : 'Parent';
    //                     if (invoiceElement.type == type + ' Invoice') {
    //                         if (new Date(invoiceElement.date).getTime() < new Date('01/01/2023').getTime()) {
    //                             // this.http.delete(`/api/invoices/${invoiceElement._id}`).subscribe(() => {
    //                             //     console.log("Deleted");
    //                             // });
    //                             // console.log(invoiceElement);
    //                         }
    //                         if (new Date(invoiceElement.date).getTime() >= new Date('01/01/2023').getTime()) {
    //                             // this.http.delete(`/api/invoices/${invoiceElement._id}`).subscribe(() => {
    //                             //     console.log("Deleted");
    //                             // });
    //                             console.log(invoiceElement);
    //                         }
    //                         if (invoiceElement.status == 'PAID') {
    //                             this.allPaidInvoices.push(invoiceElement);
    //                             this.allFilteredPaidInvoices.push(invoiceElement);
    //                         } else {
    //                             this.allInvoices.push(invoiceElement);
    //                             this.allFilteredInvoices.push(invoiceElement);
    //                         }
    //                     }
    //                 });
    //                 this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                     return +a + +b.total;
    //                 }, 0);
    //                 this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                     return +a + +b.total;
    //                 }, 0);
    //                 this.checkLoading(this.allFilteredInvoices, this.allInvoices);
    //                 this.checkLoading(this.allFilteredPaidInvoices, this.allPaidInvoices);
    //                 this.SocketService.syncUpdates('invoiceObject', this.allInvoices);
    //                 this.SocketService.syncUpdates('invoicePaidObject', this.allPaidInvoices);
    //             });
    //             this.http.get('/api/classes/').subscribe((classObject) => {
    //                 this.allClasses = classObject;
    //                 this.SocketService.syncUpdates('classObject', this.allClasses);
    //             });
    //             this.http.get('/api/schools').subscribe((schoolObject) => {
    //                 this.allSchools = schoolObject;
    //                 this.SocketService.syncUpdates('schoolObject', this.allSchools);
    //             });
    //             this.http.get('/api/students').subscribe((studentObject) => {
    //                 this.allStudents = studentObject;
    //                 this.SocketService.syncUpdates('studentObject', this.allStudents);
    //             });
    //             this.http.get('/api/people').subscribe((peopleObject) => {
    //                 this.allPeople = peopleObject;
    //                 this.SocketService.syncUpdates('peopleObject', this.allPeople);
    //             });
    //             this.http.get('/api/rolls').subscribe((rollObject) => {
    //                 this.allRolls = rollObject;
    //                 this.SocketService.syncUpdates('rollObject', this.allRolls);
    //             });
    //             this.http.get('/api/billings').subscribe((billingObject) => {
    //                 this.allBillings = billingObject;
    //                 if (this.allBillings.length > 0) {
    //                     this.lastRanDate = new Date(this.allBillings[this.allBillings.length - 1].date);
    //                 }
    //                 this.SocketService.syncUpdates('billingObject', this.allBillings);
    //             });
    //         } else if (this.currentUser.type=='teacher') {
    //             this.http.get('/api/invoices/teacher/'+this.currentUser.person._id+'/'+this.pageNumber).subscribe((invoiceObject) => {
    //                 tthis.allInvoices = [];
    //                 this.allTotalInvoices = invoiceObject;
    //                 this.allPaidInvoices = [];
    //                 this.allFilteredInvoices = [];
    //                 this.allFilteredPaidInvoices = [];
    //                 this.totalBalances = 0.00;
    //                 this.totalPaidBalances = 0.00;
    //                 invoiceObject.forEach(invoiceElement => {
    //                     var type = this.showSchoolInvoices ? 'School' : 'Parent';
    //                     if (invoiceElement.type == type + ' Invoice') {
    //                         if (invoiceElement.status == 'PAID') {
    //                             this.allPaidInvoices.push(invoiceElement);
    //                             this.allFilteredPaidInvoices.push(invoiceElement);
    //                         } else {
    //                             this.allInvoices.push(invoiceElement);
    //                             this.allFilteredInvoices.push(invoiceElement);
    //                         }
    //                     }
    //                 });
    //                 this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                     return +a + +b.total;
    //                 }, 0);
    //                 this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                     return +a + +b.total;
    //                 }, 0);
    //                 this.checkLoading(this.allFilteredInvoices, this.allInvoices);
    //                 this.checkLoading(this.allFilteredPaidInvoices, this.allPaidInvoices);
    //                 this.SocketService.syncUpdates('invoiceObject', this.allInvoices);
    //                 this.SocketService.syncUpdates('invoicePaidObject', this.allPaidInvoices);
    //             });
    //             this.http.get('/api/invoices/parent/'+this.currentUser.person._id+'/'+this.pageNumber).subscribe((invoiceObject) => {
    //                 this.allParentnvoices = invoiceObject;
    //                 this.SocketService.syncUpdates('parentInvoiceObject', this.allInvoices);
    //             });
    //             this.http.get('/api/classes/teacher/'+this.currentUser.person._id).subscribe((classObject) => {
    //                 this.allClasses = classObject;
    //                 this.SocketService.syncUpdates('classObject', this.allClasses);
    //             });
    //         } else {
    //             this.http.get('/api/invoices/parent/'+this.currentUser.person._id+'/'+this.pageNumber).subscribe((invoiceObject) => {
    //                 this.allInvoices = [];
    //                 this.allTotalInvoices = invoiceObject;
    //                 this.allPaidInvoices = [];
    //                 invoiceObject.forEach(invoiceElement => {
    //                     var type = this.showSchoolInvoices ? 'School' : 'Parent';
    //                     if (invoiceElement.type == type + ' Invoice') {
    //                         if (invoiceElement.status == 'PAID') {
    //                             this.allPaidInvoices.push(invoiceElement);
    //                             this.allFilteredPaidInvoices.push(invoiceElement);
    //                         } else {
    //                             this.allInvoices.push(invoiceElement);
    //                             this.allFilteredInvoices.push(invoiceElement);
    //                         }
    //                     }
    //                 });
    //                 this.SocketService.syncUpdates('invoiceObject', this.allParentInvoices);
    //             });
    //         }
    //     }, error => {
    //         if (error.statusText=='Unauthorized') {
    //             this.Router.navigateByUrl('/login');
    //         }
    //     });
    // }

    // reset() {
    //     this.allImages = [];
    //     this.allInvoices = [];
    //     this.allTotalInvoices = [];
    //     this.allPaidInvoices = [];
    //     this.allClasses = [];
    //     this.allSemesterClasses = [];
    //     this.allCalcPerClass = [];
    //     this.allCalcSemesterClasses = [];
    //     this.allMonthlyClasses = [];
    //     this.allMultiKidDiscount = [];
    //     this.allRolls = [];
    //     this.allSchools = [];
    //     this.allStudents = [];
    //     this.allPeople = [];
    //     this.allBillings = [];
    //     this.newInvoice = {};
    //     this.allGeneratedInvoices = [];
    //     this.allSchoolInvoices = [];
    //     this.allGeneratedDiscountInvoices = [];
    //     this.schoolInvoicing = {};
    //     this.allThisParentChildrenGroupForClasses = [];
    //     this.thisParentChildrenGroupForClass = {};
    //     this.pageSettings = {
    //         loading: false,
    //         modifying: false,
    //         currentObject: {}
    //     };
    //     this.iinvoiceImagesInvoiceData = [];
    //     this.imageUploadProgress = [];
    //     this.selectedInvoiceObject = [];
    //     this.loadArray = [];
    //     this.pageNumber = 1;
    //     this.displayImages = false;
    //     this.imagetype = "";
    //     this.showInvoice = false;
    //     this.showPaid = false;
    //     this.showClasses = false;
    //     this.showSelectAll = false;
    //     this.saveLoading = false;
    //     this.showAddPayment = true;
    //     this.showAddDiscount = true;
    //     this.showAddFee = true;
    //     this.showSchoolInvoices = false;
    //     this.billingLoading = false;
    //     this.billReady = false;
    //     this.getUserData();
    // }

    // checkLoadDone(status?) {
    //     setTimeout(() => {
    //         if (this.loadArray.length < 1) {
    //             if (status) {
    //                 this.billingLoading = false;
    //                 this.saveLoading = false;
    //                 // this.reset();
    //                 this.messageService('Error Creating Invoices!', 'Error!', 'error');
    //             } else {
    //                 this.billingLoading = false;
    //                 this.saveLoading = false;
    //                 // this.reset();
    //                 this.messageService('Invoices Created!', 'Success!', 'success');
    //                 this.databaseLoadingNumber = 0;
    //                 this.databaseLoadingEstimatedTime = 0;
    //             }
    //         }
    //     }, 300);
    // }

    // checkLoadDoneDeleteAll() {
    //     if (this.loadArray.length < 1) {
    //         setTimeout(() => {
    //             this.searchMonthText = '';
    //             this.searchCorporationText = '';
    //             this.searchLoading = false;
    //             this.getUserData();
    //             this.messageService('Invoices have been deleted.', 'Success!', 'success');
    //         }, 1000);
    //     }
    // }

   

    // buildSchoolInvoices() {
    //     this.searchLoading = true;
    //     setTimeout(() => {
    //         this.allInvoices = [];
    //         this.allPaidInvoices = [];
    //         this.allFilteredInvoices = [];
    //         this.allFilteredPaidInvoices = [];
    //         this.totalBalances = 0.00;
    //         this.totalPaidBalances = 0.00;
    //         this.http.get('/api/invoices/').subscribe((invoiceObject) => {
    //             invoiceObject.forEach(invoiceElement => {
    //                 var type = this.showSchoolInvoices ? 'School' : 'Parent';
    //                 if (invoiceElement.type == type + ' Invoice') {
    //                     if (invoiceElement.status == 'PAID') {
    //                         this.allPaidInvoices.push(invoiceElement);
    //                         this.allFilteredPaidInvoices.push(invoiceElement);
    //                     } else {
    //                         this.allInvoices.push(invoiceElement);
    //                         this.allFilteredInvoices.push(invoiceElement);
    //                     }
    //                 }
    //             });
    //             this.totalBalances = this.allFilteredInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.totalPaidBalances = this.allFilteredPaidInvoices.reduce((a, b) => {
    //                 return +a + +b.total;
    //             }, 0);
    //             this.checkLoading(this.allFilteredInvoices, this.allInvoices);
    //             this.checkLoading(this.allFilteredPaidInvoices, this.allPaidInvoices);
    //         });
    //     }, 300);
    // }

    // generateSchoolInvoice() {
    //     this.billingLoading = true;
    //     this.corporationInvoiceGroup = {};
    //     this.corporationGroup = {};
    //     this.allGeneratedSchoolInvoices = [];
    //     // this.http.get('/api/invoices/'+this.pageNumber).subscribe((invoiceObject) => {
    //     this.http.get('/api/invoices/').subscribe((invoiceObject) => {
    //         invoiceObject.forEach(invoiceElement => {
    //             if (invoiceElement.type == 'Parent Invoice' && invoiceElement.class) {
    //                 // if (invoiceElement.status == 'PAID') {
    //                     var classFoundIndex = -1;
    //                     classFoundIndex = this.allClasses.map((classElement) => classElement._id).indexOf(invoiceElement.class._id);
    //                     if (classFoundIndex != -1) {
    //                         if (!this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation]) {
    //                             this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation] = [];
    //                         }
    //                         if (!this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id]) {
    //                             this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id] = [];
    //                         }
    //                         if (!this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id][this.allClasses[classFoundIndex]._id]) {
    //                             this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id][this.allClasses[classFoundIndex]._id] = [];
    //                         }
    //                         if (!this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id][this.allClasses[classFoundIndex]._id][invoiceElement.billdate]) {
    //                             this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id][this.allClasses[classFoundIndex]._id][invoiceElement.billdate] = [];
    //                         }
    //                         this.corporationInvoiceGroup[this.allClasses[classFoundIndex].corporation][invoiceElement.school._id][this.allClasses[classFoundIndex]._id][invoiceElement.billdate].push(invoiceElement);
    //                     }
    //                 // }
    //             }
    //         });
    //         this.allReadyToRunInvoices = [];
    //         Object.keys(this.corporationInvoiceGroup).forEach(corporationIndex => {
    //             var allCorporationInvoices = [];
    //             Object.keys(this.corporationInvoiceGroup[corporationIndex]).forEach(schoolIndex => {
    //                 var allSchoolInvoices = [];
    //                 Object.keys(this.corporationInvoiceGroup[corporationIndex][schoolIndex]).forEach(classIndex => {
    //                     var allClassInvoices = [];
    //                     Object.keys(this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex]).forEach(monthIndex => {
    //                         var classInvoice = {
    //                             id: '',
    //                             corporation: corporationIndex,
    //                             billtype: this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex][monthIndex][0].billtype,
    //                             billdate: '',
    //                             type: 'School Invoice',
    //                             status: 'UNPAID',
    //                             parents: '',
    //                             students: '',
    //                             invoices: '',
    //                             school: this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex][monthIndex][0].school,
    //                             class: this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex][monthIndex][0].class,
    //                             teacher: this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex][monthIndex][0].teacher,
    //                             quantity: 0,
    //                             tuition: this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex][monthIndex][0].tuition,
    //                             total: 0,
    //                             classes: '',
    //                             teachers: '',
    //                             tuitions: ''
    //                         };
    //                         this.corporationInvoiceGroup[corporationIndex][schoolIndex][classIndex][monthIndex].forEach(invoiceElement => {
    //                             var students = '';
    //                             invoiceElement.children.forEach(student => {
    //                                 students += ('_CH-'+student._id)
    //                             });
    //                             classInvoice.id = 'S-' + schoolIndex + '_CO-' + corporationIndex + '_CL-' + classIndex + '_month-' + monthIndex;
    //                             classInvoice.billdate = invoiceElement.billdate;
    //                             classInvoice.total += +invoiceElement.total;
    //                             classInvoice.quantity += +invoiceElement.quantity;
    //                             classInvoice.parents += ('_P-'+invoiceElement.parent._id);
    //                             classInvoice.students += students;
    //                             classInvoice.invoices += ('_INV['+invoiceElement._id+']');
    //                         });
    //                         allClassInvoices.push(classInvoice);
    //                     });
    //                     allSchoolInvoices.push(allClassInvoices);
    //                 });
    //                 allCorporationInvoices.push(allSchoolInvoices);
    //             });
    //             this.allReadyToRunInvoices.push(allCorporationInvoices);
    //         });
    //         this.allReadyToRunInvoices.forEach(corporationInvoiceGroup => {
    //             corporationInvoiceGroup.forEach(schoolInvoiceGroup => {
    //                 schoolInvoiceGroup.forEach(classGroup => {
    //                     classGroup.forEach(invoiceElement => {
    //                         if (!this.corporationGroup[invoiceElement.corporation]) {
    //                             this.corporationGroup[invoiceElement.corporation] = [];
    //                         }
    //                         if (!this.corporationGroup[invoiceElement.corporation][invoiceElement.school._id]) {
    //                             this.corporationGroup[invoiceElement.corporation][invoiceElement.school._id] = [];
    //                         }
    //                         if (!this.corporationGroup[invoiceElement.corporation][invoiceElement.school._id][invoiceElement.billdate]) {
    //                             this.corporationGroup[invoiceElement.corporation][invoiceElement.school._id][invoiceElement.billdate] = [];
    //                         }
    //                         this.corporationGroup[invoiceElement.corporation][invoiceElement.school._id][invoiceElement.billdate].push(invoiceElement);
    //                     });
    //                 });
    //             });
    //         });
    //         var totalNewInvoicesReadyGroup = [];
    //         Object.keys(this.corporationGroup).forEach(corporationInvoiceGroup => {
    //             var readyInvoiceCorporationGroupNew = [];
    //             Object.keys(this.corporationGroup[corporationInvoiceGroup]).forEach(schoolInvoiceGroup => {
    //                 var readyInvoiceSchoolGroupNew = [];
    //                 Object.keys(this.corporationGroup[corporationInvoiceGroup][schoolInvoiceGroup]).forEach(monthGroup => {
    //                     var invoice = this.corporationGroup[corporationInvoiceGroup][schoolInvoiceGroup][monthGroup][0];
    //                     this.corporationGroup[corporationInvoiceGroup][schoolInvoiceGroup][monthGroup].forEach(invoiceElement => {
    //                         invoice.id = 'S-' + schoolInvoiceGroup + '_CO-' + corporationInvoiceGroup + '_month-' + monthGroup;
    //                         invoice.billdate = invoiceElement.billdate;
    //                         invoice.total += +invoiceElement.total;
    //                         invoice.quantity += +invoiceElement.quantity;
    //                         invoice.parents += invoiceElement.parents;
    //                         invoice.students += invoiceElement.students;
    //                         invoice.invoices += invoiceElement.invoices;
    //                         invoice.class = '';
    //                         invoice.classes += ('_C'+invoiceElement.class._id);
    //                         invoice.teacher = '';
    //                         invoice.teachers += ('_T'+invoiceElement.teacher._id);
    //                         invoice.tuition = '';
    //                         invoice.tuitions += ('_T'+invoiceElement.tuition);
    //                     });
    //                     // readyInvoiceSchoolGroupNew.push(invoice);
    //                     totalNewInvoicesReadyGroup.push(invoice);
    //                 });
    //                 // readyInvoiceCorporationGroupNew.push(readyInvoiceSchoolGroupNew);
    //             });
    //             // totalNewInvoicesReadyGroup.push(readyInvoiceCorporationGroupNew);
    //         });
    //         // console.log(totalNewInvoicesReadyGroup);
    //         if (totalNewInvoicesReadyGroup.length > 0) {
    //             totalNewInvoicesReadyGroup.forEach(invoiceElement => {
    //                 var newLoad = {
    //                     id: invoiceElement.id
    //                 };
    //                 this.loadArray.push(newLoad);
    //                 this.http.post('/api/invoices/', invoiceElement).subscribe(invoiceObject => {
    //                     var newLoadIndex = -1;
    //                     newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceObject.id);
    //                     if (newLoadIndex != -1) {
    //                         this.loadArray.splice(newLoadIndex, 1);
    //                     }
    //                     this.checkLoadDone();
    //                 }, error => {
    //                     console.log(error);
    //                     var newLoadIndex = -1;
    //                     newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceElement.id);
    //                     if (newLoadIndex != -1) {
    //                         this.loadArray.splice(newLoadIndex, 1);
    //                     }
    //                     this.checkLoadDone();
    //                 });
    //             });
    //         } else {
    //             this.checkLoadDone();
    //         }
    //     });
    // }

    // checkBillingReady() {
    //     if (!this.billReady) {
    //         setTimeout(() => {
    //             this.checkBillingReady();
    //         }, 1000)
    //     } else {
    //         this.runBilling();
    //     }
    // }

    // runBilling() {
    //     this.billingLoading = true;
    //     setTimeout(() => {
    //         if (!this.billReady) {
    //             this.checkBillingReady();
    //             this.saveLoading = true;
    //             this.allGeneratedInvoices = [];
    //             this.allGeneratedDiscountInvoices = [];
    //             this.schoolInvoicing = {};
    //             this.allThisParentChildrenGroupForClasses = [];
    //             this.thisParentChildrenGroupForClass = {};
    //             this.allClasses.forEach(classElement => {
    //                 if (classElement.rollapproved == 'true' && classElement.frequency == 'perclass') {
    //                     this.http.get('/api/rolls/class/' + classElement._id).subscribe((rollObject) => {
    //                         this.allRolls = rollObject;
    //                         if (this.allRolls.length>0) {
    //                             this.newDate1 = new Date(new Date(this.allRolls[0].day).toDateString()).getTime();
    //                             this.newDate2 = new Date(new Date(this.today).toDateString()).getTime();
    //                             if (this.newDate1 == this.newDate2) {
    //                                 this.showOldRoll = true;
    //                                 this.selectedRoll = this.allRolls[0];
    //                                 this.changeDate(this.allRolls[0]._id);
    //                             }
    //                             this.filterRollSummary = [];
    //                             var studentGroup = {};
    //                             this.allRolls.forEach(rollElement => {
    //                                 if (new Date(rollElement.day).getMonth() == new Date().getMonth()) {
    //                                     this.filterRollSummary.push(rollElement);
    //                                     rollElement.rollitems.forEach(studentElement => {
    //                                         if (!studentGroup[studentElement.student._id]) {
    //                                             studentGroup[studentElement.student._id] = [];
    //                                         }
    //                                         studentGroup[studentElement.student._id].push(studentElement);
    //                                     });
    //                                 }
    //                             });
    //                             classElement.students.forEach(studentElement => {
    //                                 studentElement.totalhere = 0;
    //                                 studentElement.totallate = 0;
    //                                 studentElement.totalmissing = 0;
    //                                 studentElement.totalnouniform = 0;
    //                                 studentElement.totalclasses = this.allRolls.length;
    //                             });
    //                             Object.keys(studentGroup).forEach(student => {
    //                                 var totalhere = 0;
    //                                 var totallate = 0;
    //                                 var totalmissing = 0;
    //                                 var totalnouniform = 0;
    //                                 studentGroup[student].forEach(rollItemElement => {
    //                                     if (rollItemElement.here) {
    //                                         totalhere = +totalhere + +1;
    //                                     }
    //                                     if (rollItemElement.late) {
    //                                         totallate = +totallate + +1;
    //                                     }
    //                                     if (rollItemElement.missing) {
    //                                         totalmissing = +totalmissing + +1;
    //                                     }
    //                                     if (rollItemElement.nouniform) {
    //                                         totalnouniform = +totalnouniform + +1;
    //                                     }
    //                                 });
    //                                 classElement.students.forEach(studentElement => {
    //                                     if (studentElement._id == student) {
    //                                         studentElement.totalhere = totalhere;
    //                                         studentElement.totallate = totallate;
    //                                         studentElement.totalmissing = totalmissing;
    //                                         studentElement.totalnouniform = totalnouniform;
    //                                         studentElement.totalclasses = this.allRolls.length;
    //                                     }
    //                                 });
    //                             });
    //                             this.billReady = true;
    //                         }
    //                     });
    //                 }
    //                 if (classElement.rollapproved) {
    //                     var schoolGroup = {};
    //                     if (!this.schoolInvoicing[classElement._id]) {
    //                         this.schoolInvoicing[classElement._id] = [];
    //                     }
    //                     ;
    //                     classElement.students.forEach(function(student) {
    //                         if (!student.school) {
    //                             console.log(student);
    //                         } else {
    //                             if (!schoolGroup[student.school._id]) {
    //                                 schoolGroup[student.school._id] = [];
    //                             }
    //                             if (!schoolGroup[student.school._id][student.parent._id]) {
    //                                 schoolGroup[student.school._id][student.parent._id] = [];
    //                             }
    //                             schoolGroup[student.school._id][student.parent._id].push(student);
    //                         }
    //                     })
    //                     this.schoolInvoicing[classElement._id] = schoolGroup;
    //                     this.billReady = true;
    //                 }
    //             });
    //         } else {
    //             Object.keys(this.schoolInvoicing).forEach(classIndex => {
    //                 Object.keys(this.schoolInvoicing[classIndex]).forEach(schoolIndex => {
    //                     // var schoolFoundIndex = -1;
    //                     // schoolFoundIndex = this.allSchools.map((schoolElement) => schoolElement._id).indexOf(schoolIndex);
    //                     // if (schoolFoundIndex != -1) {
    //                     //     console.log(this.allSchools[schoolFoundIndex].name);
    //                     // }
    //                     // var allSchoolInvoices = [];
    //                     Object.keys(this.schoolInvoicing[classIndex][schoolIndex]).forEach(parentIndex => {
    //                         var parentFoundIndex = -1;
    //                         parentFoundIndex = this.allPeople.map((parentElement) => parentElement._id).indexOf(parentIndex);
    //                         var parentFound = parentIndex;
    //                         if (parentFoundIndex != -1) {
    //                             parentFound = this.allPeople[parentFoundIndex];
    //                             this.thisParentChildrenGroupForClass = {
    //                                 parentId: parentFound._id,
    //                                 children: []
    //                             };
    //                         }
    //                         var classFoundIndex = -1;
    //                         classFoundIndex = this.allClasses.map((classElement) => classElement._id).indexOf(classIndex);
    //                         var tuitionFound = 0;
    //                         if (classFoundIndex != -1) {
    //                             tuitionFound = this.allClasses[classFoundIndex].tuition;
    //                         }
    //                         var childrenList = '';
    //                         var stopAdd = false;
    //                         this.schoolInvoicing[classIndex][schoolIndex][parentIndex].forEach((childElement, childElementIndex) => {
    //                             if (classFoundIndex != -1) {
    //                                 if (this.allClasses[classFoundIndex].corporation == 'balletvirtuosa') {
    //                                     // console.log(this.thisParentChildrenGroupForClass);
    //                                     if (this.thisParentChildrenGroupForClass.children) {
    //                                         // console.log(childElement.parent._id);
    //                                         this.thisParentChildrenGroupForClass.children.push(childElement._id);
    //                                     }
    //                                 }
    //                                 tuitionFound = this.allClasses[classFoundIndex].tuition;
    //                             }
    //                             if (!childElement.parent) {
    //                                 console.log(childElement);
    //                             }
    //                             childElement.parent = childElement.parent._id
    //                             if (parentFound && parentFound.length && parentFound.length > 0 && parentFound.forEach) {
    //                                 parentFound.forEach(parentElement => {
    //                                     parentElement.invoices.forEach(invoiceElement => {
    //                                         if (invoiceElement.school == schoolIndex) {
    //                                             if (invoiceElement.class == classIndex) {
    //                                                 var childOnInvoiceIndex = -1;
    //                                                 childOnInvoiceIndex = invoiceElement.children.map((childElement) => childElement._id).indexOf(childElement._id);
    //                                                 if (childOnInvoiceIndex != -1) {
    //                                                     this.schoolInvoicing[classIndex][schoolIndex][parentIndex].splice(childElementIndex, 1);
    //                                                     stopAdd = true;
    //                                                 }
    //                                             }
    //                                         }
    //                                     });
    //                                 });
    //                             }
    //                             if (!stopAdd) {
    //                                 if (childrenList == '') {
    //                                     childrenList = childElement._id;
    //                                 } else {
    //                                     childrenList = childrenList + '_' + childElement._id;
    //                                 }
    //                             }
    //                         });
    //                         this.allThisParentChildrenGroupForClasses.push(this.thisParentChildrenGroupForClass);
    //                         if (this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length > 0) {
    //                             var thismonth = (new Date().getMonth() + 1);
    //                             var startmonth = (new Date(this.allClasses[classFoundIndex].startdate).getMonth() + 1);
    //                             var endmonth = (new Date(this.allClasses[classFoundIndex].enddate).getMonth() + 1);
    //                             var today = new Date().getTime();
    //                             var startDay = new Date(this.allClasses[classFoundIndex].startdate).getTime();
    //                             var endDay = new Date(this.allClasses[classFoundIndex].enddate).getTime();
    //                             var totalMonthDiff = 0;
    //                             var monthDiff = 0;
    //                             if (today >= startDay) {
    //                                 monthDiff = 1;
    //                                 if (today <= endDay && this.allClasses[classFoundIndex].frequency == 'perclass') {
    //                                     monthDiff = (new Date(new Date()).getFullYear() - new Date(this.allClasses[classFoundIndex].startdate).getFullYear()) * 12;
    //                                     monthDiff -= new Date(this.allClasses[classFoundIndex].startdate).getMonth();
    //                                     monthDiff += new Date(new Date()).getMonth();
    //                                 } else {
    //                                     monthDiff = (new Date(this.allClasses[classFoundIndex].enddate).getFullYear() - new Date(this.allClasses[classFoundIndex].startdate).getFullYear()) * 12;
    //                                     monthDiff -= new Date(this.allClasses[classFoundIndex].startdate).getMonth();
    //                                     monthDiff += new Date(this.allClasses[classFoundIndex].enddate).getMonth();
    //                                 }
    //                                 totalMonthDiff = (new Date(this.allClasses[classFoundIndex].enddate).getFullYear() - new Date(this.allClasses[classFoundIndex].startdate).getFullYear()) * 12;
    //                                 totalMonthDiff -= new Date(this.allClasses[classFoundIndex].startdate).getMonth();
    //                                 totalMonthDiff += new Date(this.allClasses[classFoundIndex].enddate).getMonth();
    //                                 totalMonthDiff = totalMonthDiff <= 0 ? 1 : +totalMonthDiff + +1;
    //                                 monthDiff = monthDiff <= 0 ? 1 : +monthDiff + +1;
    //                             }
    //                             if (this.allClasses[classFoundIndex].frequency == 'perclass') {
    //                                 var numberOfTimes = 0;
    //                                 for (let i = 0; i < monthDiff; i++) {
    //                                     var monthAddNumber = (new Date(this.allClasses[classFoundIndex].startdate).getMonth() + +i);
    //                                     if (monthAddNumber % 12 == 0) {
    //                                         numberOfTimes++;
    //                                     }
    //                                     if (monthAddNumber >= 12) {
    //                                         for (let times = 0; times < numberOfTimes; times++) {
    //                                             monthAddNumber -= 12;
    //                                         }
    //                                     }
    //                                     var totalClasses = 0;
    //                                     var totalVisitedClasses = 0;
    //                                     this.schoolInvoicing[classIndex][schoolIndex][parentIndex].forEach(student => {
    //                                         if (student.totalclasses) {
    //                                             totalClasses += +student.totalclasses;
    //                                         }
    //                                         if (student.totalhere) {
    //                                             totalVisitedClasses += +student.totalhere;
    //                                         }
    //                                     });
    //                                     var parentInvoice = {
    //                                         id: 'S-' + schoolIndex + '_CL-' + classIndex + '_P-' + parentIndex + '_month-' + this.monthNames[monthAddNumber] + '_CH-' + childrenList,
    //                                         billtype: 'perclass',
    //                                         billdate: this.monthNames[monthAddNumber],
    //                                         type: 'Parent Invoice',
    //                                         status: 'UNPAID',
    //                                         parent: parentIndex,
    //                                         children: this.schoolInvoicing[classIndex][schoolIndex][parentIndex],
    //                                         school: schoolIndex,
    //                                         class: this.allClasses[classFoundIndex]._id,
    //                                         teacher: this.allClasses[classFoundIndex].teacher._id,
    //                                         quantity: totalClasses,
    //                                         tuition: tuitionFound,
    //                                         total: totalClasses * tuitionFound
    //                                     };
    //                                     // var schoolInvoice = {
    //                                     //     id: 'S-' + schoolIndex + '_CL-' + classIndex + '_P-' + parentIndex + '_month-' + this.monthNames[monthAddNumber] + '_CH-' + childrenList + '_SchoolInvoice',
    //                                     //     billtype: 'perclass',
    //                                     //     billdate: this.monthNames[monthAddNumber],
    //                                     //     type: 'School Invoice',
    //                                     //     status: 'UNPAID',
    //                                     //     parent: parentIndex,
    //                                     //     children: this.schoolInvoicing[classIndex][schoolIndex][parentIndex],
    //                                     //     school: schoolIndex,
    //                                     //     class: this.allClasses[classFoundIndex]._id,
    //                                     //     teacher: this.allClasses[classFoundIndex].teacher._id,
    //                                     //     quantity: totalVisitedClasses,
    //                                     //     tuition: tuitionFound,
    //                                     //     total: totalVisitedClasses * tuitionFound
    //                                     // };
    //                                     // allSchoolInvoices.push(schoolInvoice);
    //                                     var foundIndex = -1;
    //                                     foundIndex = this.allTotalInvoices.map((item) => item.id).indexOf(parentInvoice.id);
    //                                     if (foundIndex == -1) {
    //                                         this.allGeneratedInvoices.push(parentInvoice);
    //                                     }
    //                                 }
    //                             }
    //                             if (this.allClasses[classFoundIndex].frequency == 'monthly') {
    //                                 var numberOfTimes = 0;
    //                                 for (let i = 0; i < monthDiff; i++) {
    //                                     var monthAddNumber = (new Date(this.allClasses[classFoundIndex].startdate).getMonth() + +i);
    //                                     if (monthAddNumber % 12 == 0) {
    //                                         numberOfTimes++;
    //                                     }
    //                                     if (monthAddNumber >= 12) {
    //                                         for (let times = 0; times < numberOfTimes; times++) {
    //                                             monthAddNumber -= 12;
    //                                         }
    //                                     }
    //                                     var totalClasses = 0;
    //                                     var totalVisitedClasses = 0;
    //                                     this.schoolInvoicing[classIndex][schoolIndex][parentIndex].forEach(student => {
    //                                         totalClasses += +student.totalclasses;
    //                                         totalVisitedClasses += +student.totalhere;
    //                                     });
    //                                     var parentInvoice = {
    //                                         id: 'S-' + schoolIndex + '_CL-' + classIndex + '_P-' + parentIndex + '_month-' + this.monthNames[monthAddNumber] + '_CH-' + childrenList,
    //                                         billtype: 'month',
    //                                         billdate: this.monthNames[monthAddNumber],
    //                                         type: 'Parent Invoice',
    //                                         status: 'UNPAID',
    //                                         parent: parentIndex,
    //                                         children: this.schoolInvoicing[classIndex][schoolIndex][parentIndex],
    //                                         school: schoolIndex,
    //                                         class: this.allClasses[classFoundIndex]._id,
    //                                         teacher: this.allClasses[classFoundIndex].teacher._id,
    //                                         quantity: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length,
    //                                         tuition: tuitionFound,
    //                                         total: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length * tuitionFound
    //                                     };
    //                                     var foundIndex = -1;
    //                                     foundIndex = this.allTotalInvoices.map((item) => item.id).indexOf(parentInvoice.id);
    //                                     if (foundIndex == -1) {
    //                                         this.allGeneratedInvoices.push(parentInvoice);
    //                                     }
    //                                 }
    //                             }
    //                             if (this.allClasses[classFoundIndex].frequency == 'dividedsemester') {
    //                                 var numberOfTimes = 0;
    //                                 for (let i = 0; i < monthDiff; i++) {
    //                                     var monthAddNumber = (new Date(this.allClasses[classFoundIndex].startdate).getMonth() + +i);
    //                                     if (monthAddNumber % 12 == 0) {
    //                                         numberOfTimes++;
    //                                     }
    //                                     if (monthAddNumber >= 12) {
    //                                         for (let times = 0; times < numberOfTimes; times++) {
    //                                             monthAddNumber -= 12;
    //                                         }
    //                                     }
    //                                     var totalClasses = 0;
    //                                     var totalVisitedClasses = 0;
    //                                     this.schoolInvoicing[classIndex][schoolIndex][parentIndex].forEach(student => {
    //                                         totalClasses += +student.totalclasses;
    //                                         totalVisitedClasses += +student.totalhere;
    //                                     });
    //                                     var parentInvoice = {
    //                                         id: 'S-' + schoolIndex + '_CL-' + classIndex + '_P-' + parentIndex + '_dividedmonth-' + this.monthNames[monthAddNumber] + '_CH-' + childrenList,
    //                                         billtype: 'dividedmonth',
    //                                         billdate: this.monthNames[monthAddNumber],
    //                                         type: 'Parent Invoice',
    //                                         status: 'UNPAID',
    //                                         parent: parentIndex,
    //                                         children: this.schoolInvoicing[classIndex][schoolIndex][parentIndex],
    //                                         school: schoolIndex,
    //                                         class: this.allClasses[classFoundIndex]._id,
    //                                         teacher: this.allClasses[classFoundIndex].teacher._id,
    //                                         quantity: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length,
    //                                         tuition: totalMonthDiff > 0 ? tuitionFound / totalMonthDiff : tuitionFound,
    //                                         total: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length * (totalMonthDiff > 0 ? tuitionFound / totalMonthDiff : tuitionFound)
    //                                     };
    //                                     var foundIndex = -1;
    //                                     foundIndex = this.allTotalInvoices.map((item) => item.id).indexOf(parentInvoice.id);
    //                                     if (foundIndex == -1) {
    //                                         this.allGeneratedInvoices.push(parentInvoice);
    //                                     }
    //                                 }
    //                             }
    //                             if (this.allClasses[classFoundIndex].frequency == 'multikiddiscount') {
    //                                 var numberOfTimes = 0;
    //                                 for (let i = 0; i < monthDiff; i++) {
    //                                     var monthAddNumber = (new Date(this.allClasses[classFoundIndex].startdate).getMonth() + +i);
    //                                     if (monthAddNumber % 12 == 0) {
    //                                         numberOfTimes++;
    //                                     }
    //                                     if (monthAddNumber >= 12) {
    //                                         for (let times = 0; times < numberOfTimes; times++) {
    //                                             monthAddNumber -= 12;
    //                                         }
    //                                     }
    //                                     var totalClasses = 0;
    //                                     var totalVisitedClasses = 0;
    //                                     this.schoolInvoicing[classIndex][schoolIndex][parentIndex].forEach(student => {
    //                                         totalClasses += +student.totalclasses;
    //                                         totalVisitedClasses += +student.totalhere;
    //                                     });
    //                                     // console.log('gotHere');
    //                                     // console.log(schoolIndex);
    //                                     var parentInvoice = {
    //                                         id: 'S-' + schoolIndex + '_CL-' + classIndex + '_P-' + parentIndex + '_divideddiscountmonth-' + this.monthNames[monthAddNumber] + '_CH-' + childrenList,
    //                                         billtype: 'divideddiscountmonth',
    //                                         billdate: this.monthNames[monthAddNumber],
    //                                         type: 'Parent Invoice',
    //                                         status: 'UNPAID',
    //                                         parent: parentIndex,
    //                                         children: this.schoolInvoicing[classIndex][schoolIndex][parentIndex],
    //                                         school: schoolIndex,
    //                                         class: this.allClasses[classFoundIndex]._id,
    //                                         teacher: this.allClasses[classFoundIndex].teacher._id,
    //                                         quantity: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length,
    //                                         tuition: totalMonthDiff > 0 ? tuitionFound / totalMonthDiff : tuitionFound,
    //                                         dividednumber: totalMonthDiff,
    //                                         total: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length
    //                                     };
    //                                     var foundIndex = -1;
    //                                     foundIndex = this.allTotalInvoices.map((item) => item.id).indexOf(parentInvoice.id);
    //                                     if (foundIndex == -1) {
    //                                         this.allGeneratedDiscountInvoices.push(parentInvoice);
    //                                     }
    //                                 }
    //                             }
    //                             if (this.allClasses[classFoundIndex].frequency == 'semester') {
    //                                 var parentInvoice = {
    //                                     id: 'S-' + schoolIndex + '_CL-' + classIndex + '_P-' + parentIndex + '_semester-' + this.monthNames[startmonth - 1] + '_' + this.monthNames[endmonth - 1] + '_CH-' + childrenList,
    //                                     billtype: 'semester',
    //                                     billdate: this.monthNames[startmonth - 1] + '_' + this.monthNames[endmonth - 1],
    //                                     type: 'Parent Invoice',
    //                                     status: 'UNPAID',
    //                                     parent: parentIndex,
    //                                     children: this.schoolInvoicing[classIndex][schoolIndex][parentIndex],
    //                                     school: schoolIndex,
    //                                     class: this.allClasses[classFoundIndex]._id,
    //                                     teacher: this.allClasses[classFoundIndex].teacher._id,
    //                                     quantity: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length,
    //                                     tuition: tuitionFound,
    //                                     total: this.schoolInvoicing[classIndex][schoolIndex][parentIndex].length * tuitionFound
    //                                 };
    //                                 var foundIndex = -1;
    //                                 foundIndex = this.allTotalInvoices.map((item) => item.id).indexOf(parentInvoice.id);
    //                                 if (foundIndex == -1) {
    //                                     this.allGeneratedInvoices.push(parentInvoice);
    //                                 }
    //                             }
    //                         }
    //                     });
    //                     // if (allSchoolInvoices.length > 0) {
    //                     //     var newSchoolInvoice = {
    //                     //         id: allSchoolInvoices[0].id,
    //                     //         billtype: 'perclass',
    //                     //         billdate: allSchoolInvoices[0].billdate,
    //                     //         type: 'School Invoice',
    //                     //         status: 'UNPAID',
    //                     //         parents: '',
    //                     //         students: '',
    //                     //         school: allSchoolInvoices[0].school,
    //                     //         class: allSchoolInvoices[0].class,
    //                     //         teacher: allSchoolInvoices[0].teacher,
    //                     //         quantity: 0,
    //                     //         tuition: allSchoolInvoices[0].tuition,
    //                     //         total: 0
    //                     //     };
    //                     //     allSchoolInvoices.forEach(schoolInvoice => {
    //                     //         var students = '';
    //                     //         schoolInvoice.children.forEach(student => {
    //                     //             students += ('_CH-'+student._id)
    //                     //         });
    //                     //         newSchoolInvoice.total += schoolInvoice.total;
    //                     //         newSchoolInvoice.quantity += schoolInvoice.quantity;
    //                     //         newSchoolInvoice.parents += ('_P-'+schoolInvoice.parent);
    //                     //         newSchoolInvoice.students += students;
    //                     //     });
    //                     //     this.allSchoolInvoices.push(newSchoolInvoice);
    //                     // }
    //                 });
    //             });
    //             // var schoolInvoiceGroup = {};
    //             // this.allSchoolInvoices.forEach(invoiceElement => {
    //             //     var classFoundIndex = -1;
    //             //     classFoundIndex = this.allClasses.map((classElement) => classElement._id).indexOf(invoiceElement.class);
    //             //     if (classFoundIndex != -1 && this.allClasses[classFoundIndex].corporation == 'highlandtaekwondoacademy') {
    //             //         if (!schoolInvoiceGroup[invoiceElement.school]) {
    //             //             schoolInvoiceGroup[invoiceElement.school] = [];
    //             //         }
    //             //         schoolInvoiceGroup[invoiceElement.school].push(invoiceElement);
    //             //     } else {
    //             //         var foundIndex = -1;
    //             //         foundIndex = this.allTotalInvoices.map((item) => item.id).indexOf(invoiceElement.id);
    //             //         if (foundIndex == -1) {
    //             //             this.allGeneratedInvoices.push(invoiceElement);
    //             //         }
    //             //     }
    //             // });
    //             // if(schoolInvoiceGroup) {
    //             //     Object.keys(schoolInvoiceGroup).forEach(schoolIndex => {
    //             //         var newSchoolInvoice = {
    //             //             id: 'S-' + schoolIndex + '_CL-highlandtaekwondoacademy_month-' + schoolInvoiceGroup[schoolIndex][0].billdate,
    //             //             billtype: 'perclass',
    //             //             billdate: schoolInvoiceGroup[schoolIndex][0].billdate,
    //             //             type: 'School Invoice',
    //             //             status: 'UNPAID',
    //             //             parents: '',
    //             //             students: '',
    //             //             school: schoolInvoiceGroup[schoolIndex][0].school,
    //             //             class: schoolInvoiceGroup[schoolIndex][0].class,
    //             //             teacher: schoolInvoiceGroup[schoolIndex][0].teacher,
    //             //             quantity: 0,
    //             //             tuition: schoolInvoiceGroup[schoolIndex][0].tuition,
    //             //             total: 0
    //             //         };
    //             //         schoolInvoiceGroup[schoolIndex].forEach(schoolInvoice => {
    //             //             newSchoolInvoice.total += schoolInvoice.total;
    //             //             newSchoolInvoice.quantity += schoolInvoice.quantity;
    //             //             newSchoolInvoice.parents += schoolInvoice.parents;
    //             //             newSchoolInvoice.students += schoolInvoice.students;
    //             //         });
    //             //         this.allGeneratedInvoices.push(newSchoolInvoice);
    //             //     });
    //             // }
    //             if (this.allGeneratedDiscountInvoices.length > 0) {
    //                 var allNewGroupToFixErrors = {};
    //                 this.allThisParentChildrenGroupForClasses.forEach(groupElement => {
    //                     if (groupElement.children.length >= 1) {
    //                         if (!allNewGroupToFixErrors[groupElement.parentId]) {
    //                             allNewGroupToFixErrors[groupElement.parentId] = [];
    //                         }
    //                         groupElement.children.forEach(child => {
    //                             allNewGroupToFixErrors[groupElement.parentId].push(child);
    //                         });
    //                     }
    //                 });
    //             }
    //             // console.log(allNewGroupToFixErrors);
    //             if (this.allGeneratedDiscountInvoices.length > 0) {
    //                 this.allGeneratedDiscountInvoices.forEach((invoiceElement, index) => {
    //                     if (index <= 50) {
    //                         var totalDiscountQty = 0;
    //                         for (let [key, value] of Object.entries(allNewGroupToFixErrors)) {
    //                             if (key == invoiceElement.parent) {
    //                                 totalDiscountQty = value.length;
    //                                 invoiceElement.discountNumber = value.length;
    //                             }
    //                         }
    //                         // this.allThisParentChildrenGroupForClasses.forEach(groupElement => {
    //                         //     if (invoiceElement.parent == groupElement.parentId) {
    //                         //         console.log(invoiceElement);
    //                         //     }
    //                         //     if (groupElement.children.length > 0) {
    //                         //         if (invoiceElement.parent == groupElement.parentId) {
    //                         //             totalDiscountQty = +totalDiscountQty + +groupElement.children.length;
    //                         //         }
    //                         //     }
    //                         // });
    //                         var discount = 0;
    //                         if (totalDiscountQty >= 2 && totalDiscountQty <= 4) {
    //                             discount = 5;
    //                         }
    //                         if (totalDiscountQty >= 5 && totalDiscountQty <= 9) {
    //                             discount = 10;
    //                         }
    //                         if (totalDiscountQty >= 10) {
    //                             discount = 15;
    //                         }
    //                         discount = invoiceElement.dividednumber > 0 ? +discount / +invoiceElement.dividednumber : discount;
    //                         if (discount < .01) {
    //                             discount = 0;
    //                         }
    //                         invoiceElement.total = +(+invoiceElement.total * +invoiceElement.tuition);
    //                         var newLoad = {
    //                             id: invoiceElement.id
    //                         };
    //                         this.loadArray.push(newLoad);
    //                         if (discount) {
    //                             this.http.post('/api/invoices/', invoiceElement).subscribe(invoiceObject => {
    //                                 var newLoadIndex = -1;
    //                                 newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceObject.id);
    //                                 if (newLoadIndex != -1) {
    //                                     this.loadArray.splice(newLoadIndex, 1);
    //                                 }
    //                                 invoiceObject.discountAmount = discount;
    //                                 invoiceObject.discountDescription = totalDiscountQty + ' enrollments with Ballet Virtuosa';
    //                                 for (let i = 0; i < invoiceElement.quantity; i++) {
    //                                     this.saveDiscount(invoiceObject, 'checkLoad');
    //                                 }
    //                             }, error => {
    //                                 console.log(error);
    //                                 var newLoadIndex = -1;
    //                                 newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceElement.id);
    //                                 if (newLoadIndex != -1) {
    //                                     this.loadArray.splice(newLoadIndex, 1);
    //                                 }
    //                                 this.checkLoadDone();
    //                             });
    //                         } else {
    //                             this.http.post('/api/invoices/', invoiceElement).subscribe(invoiceObject => {
    //                                 var newLoadIndex = -1;
    //                                 newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceObject.id);
    //                                 if (newLoadIndex != -1) {
    //                                     this.loadArray.splice(newLoadIndex, 1);
    //                                 }
    //                                 this.checkLoadDone();
    //                             }, error => {
    //                                 console.log(error);
    //                                 var newLoadIndex = -1;
    //                                 newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceElement.id);
    //                                 if (newLoadIndex != -1) {
    //                                     this.loadArray.splice(newLoadIndex, 1);
    //                                 }
    //                                 this.checkLoadDone();
    //                             });
    //                         }
    //                     }
    //                 });
    //             }
    //             if (this.allGeneratedInvoices.length > 0) {
    //                 this.allGeneratedInvoices.forEach((invoiceElement, index) => {
    //                     if (index <= 50) {
    //                         var newLoad = {
    //                             id: invoiceElement.id
    //                         };
    //                         this.loadArray.push(newLoad);
    //                         this.http.post('/api/invoices/', invoiceElement).subscribe(invoiceObject => {
    //                             var newLoadIndex = -1;
    //                             newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceObject.id);
    //                             if (newLoadIndex != -1) {
    //                                 this.loadArray.splice(newLoadIndex, 1);
    //                             }
    //                             this.checkLoadDone();
    //                         }, error => {
    //                             console.log(error);
    //                             var newLoadIndex = -1;
    //                             newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceElement.id);
    //                             if (newLoadIndex != -1) {
    //                                 this.loadArray.splice(newLoadIndex, 1);
    //                             }
    //                             this.checkLoadDone();
    //                         });
    //                     }
    //                 });
    //             }
    //         }
    //     }, 1000);
    // }

    // addInvoice() {
    //     if (this.newInvoice.total) {
    //         if (this.invoiceImagesInvoiceData) {
    //             if (this.invoiceImagesInvoiceData.length >= 1) {
    //                 for (let index = 0; index < this.invoiceImagesInvoiceData.length; index++) {
    //                     if (this.newInvoice.image) {
    //                         this.newInvoice.image.push(this.invoiceImagesInvoiceData[index]._id);
    //                     } else {
    //                         this.newInvoice.image = [];
    //                         this.newInvoice.image.push(this.invoiceImagesInvoiceData[index]._id);
    //                     }
    //                 }
    //             }
    //         }
    //         if (this.newInvoice._id) {
    //             this.http.put('/api/invoices/' + this.newInvoice._id, this.newInvoice).subscribe(invoiceObject => {
    //                 this.getUserData();
    //                 this.clearout();
    //                 this.messageService('Invoice has been modified.', 'Success!', 'success');
    //             });
    //         } else {
    //             if (!this.currentUser.type=='all' || this.currentUser.type=='hr') {
    //                 this.newInvoice.owner = this.currentUser.person;
    //             }
    //             this.http.post('/api/invoices', this.newInvoice).subscribe(invoiceObject => {
    //                 const locationIndex = this.allInvoices.map((item) => item._id).indexOf(invoiceObject._id);
    //                 if (locationIndex !== -1) {
    //                     this.allInvoices[locationIndex].image = this.newInvoice.image;
    //                 }
    //                 this.getUserData();
    //                 this.clearout();
    //                 this.messageService('Invoice has been added.', 'Success!', 'success');
    //             });
    //         }
    //     }
    // }

    // saveDiscount(invoice, checkload?) {
    //     var newDiscountObject = {
    //         invoice: invoice._id,
    //         class: invoice.class._id,
    //         owner: this.currentUser._id,
    //         parent: invoice.parent._id,
    //         school: invoice.school._id,
    //         teacher: invoice.teacher._id,
    //         amount: invoice.discountAmount,
    //         description: invoice.discountDescription
    //     };
    //     this.http.post('/api/discounts/', newDiscountObject).subscribe(discountObject => {
    //         invoice.discounts.push(discountObject);
    //         invoice.total = +invoice.total - +discountObject.amount;
    //         if (checkload) {
    //             var newLoad = {
    //                 id: invoice.id
    //             };
    //             this.loadArray.push(newLoad);
    //         }
    //         this.http.put('/api/invoices/' + invoice._id, invoice).subscribe(invoiceObject => {
    //             invoice = invoiceObject;
    //             if (checkload) {
    //                 var newLoadIndex = -1;
    //                 newLoadIndex = this.loadArray.map((item) => item.id).indexOf(invoiceObject.id);
    //                 if (newLoadIndex != -1) {
    //                     this.loadArray.splice(newLoadIndex, 1);
    //                 }
    //                 this.checkLoadDone();
    //             } else {
    //                 // this.showAddDiscount = false;
    //                 this.messageService('Discount has been saved.', 'Success!', 'success');
    //             }
    //         });
    //     });
    // }

    // savePayment(invoice) {
    //     var newPaymentObject = {
    //         invoice: invoice._id,
    //         class: invoice.class._id,
    //         owner: this.currentUser._id,
    //         parent: invoice.parent._id,
    //         school: invoice.school._id,
    //         teacher: invoice.teacher._id,
    //         amount: invoice.paymentAmount,
    //         description: invoice.paymentDescription
    //     };
    //     this.http.post('/api/payments/', newPaymentObject).subscribe(paymentObject => {
    //         invoice.payments.push(paymentObject);
    //         invoice.total = +invoice.total - +paymentObject.amount;
    //         this.http.put('/api/invoices/' + invoice._id, invoice).subscribe(invoiceObject => {
    //             invoice = invoiceObject;
    //             // this.showAddPayment = false;
    //             this.messageService('Payment has been saved.', 'Success!', 'success');
    //         });
    //     });
    // }

    // saveFee(invoice) {
    //     var newFeeObject = {
    //         invoice: invoice._id,
    //         class: invoice.class._id,
    //         owner: this.currentUser._id,
    //         parent: invoice.parent._id,
    //         school: invoice.school._id,
    //         teacher: invoice.teacher._id,
    //         amount: invoice.feeAmount,
    //         description: invoice.feeDescription
    //     };
    //     this.http.post('/api/costs/', newFeeObject).subscribe(feeObject => {
    //         invoice.fees.push(feeObject);
    //         invoice.total = +invoice.total + +feeObject.amount;
    //         this.http.put('/api/invoices/' + invoice._id, invoice).subscribe(invoiceObject => {
    //             invoice = invoiceObject;
    //             // this.showAddFee = false;
    //             this.messageService('Fee has been saved.', 'Success!', 'success');
    //         });
    //     });
    // }

    // deleteInvoice(invoiceObject) {
    //     // console.log(this.allTotalInvoices.length);
    //     // this.allTotalInvoices.forEach(element => {
    //     //     this.http.delete(`/api/invoices/${element._id}`).subscribe(() => {
    //     //         // this.getUserData();
    //     //         // this.messageService('Invoice has been deleted.', 'Success!', 'success');
    //     //     });
    //     // });
    //     this.searchMonthText = '';
    //     this.searchCorporationText = '';
    //     this.http.delete(`/api/invoices/${invoiceObject._id}`).subscribe(() => {
    //         this.getUserData();
    //         this.messageService('Invoice has been deleted.', 'Success!', 'success');
    //     });
    // }

    // confirmDeleteAllSelected() {
    //     this.messageService('Are you sure you want to delete all selected invoices?', 'Are you sure?', 'deleteall', '', 'invoiceall');
    // }

    // confirmDelete(invoiceObject, type) {
    //     this.messageService('Are you sure you want to delete this ' + type + '?', 'Are you sure?', 'delete', invoiceObject, type);
    // }

    // confirmRemoveClass(classObject) {
    //     this.messageService('Are you sure you want to remove this class?', 'Are you sure?', 'remove', classObject, 'class');
    // }

    // viewInvoice(invoiceObject) {
    //     this.showInvoice = true;
    //     this.selectedInvoiceObject = invoiceObject;
    // }

    // removeFromClass(thisClassObject) {
    //     const locationIndex = this.selectedInvoiceObject.classes.map((classItem) => classItem._id).indexOf(thisClassObject._id);
    //     if (locationIndex !== -1) {
    //         this.selectedInvoiceObject.classes.splice(locationIndex, 1);
    //     }
    //     this.http.put('/api/invoices/' + this.selectedInvoiceObject._id, this.selectedInvoiceObject).subscribe(invoiceObject => {
    //         this.getUserData();
    //         this.showClasses = false;
    //         this.messageService('Class has been removed.', 'Success!', 'success');
    //     });
    // }

    // addToClass(thisClassObject) {
    //     const locationIndex = this.selectedInvoiceObject.classes.map((classItem) => classItem._id).indexOf(thisClassObject._id);
    //     if (locationIndex == -1) {
    //         this.selectedInvoiceObject.classes.push(thisClassObject);
    //     }
    //     this.selectedInvoiceObject.classes.forEach(classObject => {
    //         const locationIndex = classObject.invoices.map((invoiceItem) => invoiceItem._id).indexOf(this.selectedInvoiceObject._id);
    //         if (locationIndex == -1) {
    //             classObject.invoices.push(this.selectedInvoiceObject._id);
    //             this.http.put('/api/classes/' + classObject._id, classObject).subscribe(classObject => {
    //                 console.log(classObject)
    //             });
    //         }
    //     });
    //     this.http.put('/api/invoices/' + this.selectedInvoiceObject._id, this.selectedInvoiceObject).subscribe(invoiceObject => {
    //         this.getUserData();
    //         this.showClasses = false;
    //         this.messageService('Class has been added.', 'Success!', 'success');
    //     });
    // }

    // chooseImage(type) {
    //     this.imagetype = type;
    //     this.displayImages = !this.displayImages;
    //     this.getImages();
    // }

    // getImages() {
    //     this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
    //         this.allImages = images;
    //         this.SocketService.syncUpdates('image', this.allImages);
    //     });
    // }

    // selectImage(image) {
    //     this.newInvoice.image = image;
    //     this.displayImages = false;
    // }

    // runSelectAll() {
    //     this.showSelectAll = true;
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     this.allFilteredInvoices.forEach((el)=>{el.checked = true;});
    // }

    // runUnSelectAll() {
    //     this.showSelectAll = true;
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     this.allFilteredInvoices.forEach((el)=>{el.checked = false;});
    // }

    // runDeleteAllChecked() {
    //     this.searchLoading = true;
    //     this.searchMonthText = '';
    //     this.searchCorporationText = '';
    //     var selectedInvoices = this.allInvoices.filter(obj => {return obj.checked === true;});
    //     selectedInvoices.forEach((element, index) => {
    //         var newLoad = {
    //             id: element.id
    //         };
    //         this.loadArray.push(newLoad);
    //         this.http.delete(`/api/invoices/${element._id}`).subscribe(() => {
    //             var newLoadIndex = -1;
    //             newLoadIndex = this.loadArray.map((item) => item.id).indexOf(element.id);
    //             if (newLoadIndex != -1) {
    //                 this.loadArray.splice(newLoadIndex, 1);
    //             }
    //             this.checkLoadDoneDeleteAll();
    //             this.databaseLoadingNumber -= +1;
    //         });
    //     });
    // }

    // closeRunSelectAll() {
    //     var newArray = [];
    //     this.showSelectAll = false;
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     this.allInvoices = newArray;
    // }

    // checkMarkedLoadingDone() {
    //     if (this.allMarkedPaidLoader.length<=0) {
    //         setTimeout(() => {
    //             this.searchLoading = false;
    //         }, 300);
    //     }
    // }

    // runMarkPaid() {
    //     this.searchLoading = true;
    //     var selectedInvoices = this.allFilteredInvoices.filter(obj => {return obj.checked === true;});
    //     var partialPaymentInvoices = this.allFilteredInvoices.filter(obj => {return obj.checked === false;});
    //     var readyToSavePaid = [];
    //     selectedInvoices.forEach(element => {
    //         element.status = "PAID";
    //         this.allPaidInvoices.push(element);
    //         readyToSavePaid.push(element);
    //     });
    //     readyToSavePaid.forEach(invoiceElement => {
    //         var invoiceFoundIndex = -1;
    //         invoiceFoundIndex = this.allInvoices.map((itemElement) => itemElement._id).indexOf(invoiceElement._id);
    //         if (invoiceFoundIndex != -1) {
    //             this.allInvoices.splice(invoiceFoundIndex, 1);
    //         }
    //         this.allMarkedPaidLoader.push(invoiceElement._id);
    //         this.http.put('/api/invoices/' + invoiceElement._id, invoiceElement).subscribe(invoiceObject => {
    //             var invoiceFoundInLoaderIndex = -1;
    //             invoiceFoundInLoaderIndex = this.allMarkedPaidLoader.map((item) => item).indexOf(invoiceObject._id);
    //             if (invoiceFoundInLoaderIndex !== -1) {
    //                 this.allMarkedPaidLoader.splice(invoiceFoundInLoaderIndex, 1);
    //                 this.checkMarkedLoadingDone();
    //             }
    //         });
    //     });
    //     this.allInvoices.forEach((el)=>{el.checked = false;});
    //     this.allFilteredInvoices.forEach((el)=>{el.checked = false;});
    //     this.showSelectAll = false;
    // }

    // setThisCheck(invoiceObject, status) {
    //     invoiceObject.checked = status;
    // }

    // clearout() {
    //     this.newInvoice = {};
    //     this.pageSettings.modifying = false;
    //     this.displayImages = false;
    // }

    // messageService(message, title?, type?, item?, objectType?) {
    //     if (type === 'delete') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, delete it!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 if (objectType === 'invoice') {
    //                     if (this.currentUser.type=='all') {
    //                         this.deleteInvoice(item);
    //                     } else {
    //                         this.messageService('Only an admin can delete an invoice.', 'Error!', 'error');
    //                     }
    //                 }
    //                 if (objectType === 'fee') {
    //                     this.deleteCost(item);
    //                 }
    //             }
    //         })
    //     } else if (type === 'deleteall') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, delete them!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 if (objectType === 'invoiceall') {
    //                     if (this.currentUser.type=='all') {
    //                         this.runDeleteAllChecked();
    //                     } else {
    //                         this.messageService('Only an admin can delete an invoice.', 'Error!', 'error');
    //                     }
    //                 }
    //                 if (objectType === 'fee') {
    //                     this.deleteCost(item);
    //                 }
    //             }
    //         })
    //     } else if (type === 'error') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'ok'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 // this.deleteChild(item);
    //                 // this.messageService('Your child has been deleted.', 'Success!', 'success');
    //             }
    //         })
    //     } else if (type === 'remove') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '##292b2c',
    //             confirmButtonText: 'Yes, remove it!'
    //         }).then((result) => {
    //             if (result.value === true) {
    //                 this.removeFromClass(item);
    //                 this.messageService('The class has been removed.', 'Success!', 'success');
    //             }
    //         })
    //     } else if (type === 'success') {
    //         Swal.fire({
    //             title: title,
    //             text: message,
    //             icon: 'success',
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //     } else {
    //         Swal.fire(message);
    //     }
    // }
}