import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { AllFormsComponent } from './allforms.component';
import { SocketService } from '../../../components/socket/socket.service';
import { UploadImageModule } from '../../account/uploadimage/uploadimage.module';
import { PipesModule } from '../../../components/pipe/pipesModule';

export const ROUTES: Routes = [
    { path: 'allforms', component: AllFormsComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        UploadImageModule,
        PipesModule
    ],
    declarations: [
        AllFormsComponent,
    ],
    providers: [
        SocketService,
    ],
    exports: [
        AllFormsComponent,
    ],
})
export class AllFormsModule {}
