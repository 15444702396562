import {
    NgModule,
    ApplicationRef,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {
    removeNgStyles,
    createNewHosts,
    createInputTransfer,
} from '@angularclass/hmr';

import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { CalendarModule } from './calendar/calendar.module';
import { DirectivesModule } from '../components/directives.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
import { ImageModule } from './admin/image/image.module';
import { UploadImageModule } from './account/uploadimage/uploadimage.module';
import { SchoolModule } from './admin/school/school.module';
import { RunInvoiceModule } from './admin/runinvoice/runinvoice.module';
import { ExhibitModule } from './admin/exhibit/exhibit.module';
import { TeacherModule } from './admin/teacher/teacher.module';
import { ChatModule } from './admin/chat/chat.module';
import { ClassModule } from './admin/class/class.module';
import { ProgramsModule } from './programs/programs.module';
import { AllFormsModule } from './admin/allforms/allforms.module';
import { AllPickupsModule } from './admin/allpickups/allpickups.module';
import { AllInvoicesModule } from './admin/allinvoices/allinvoices.module';
import { InvoicesModule } from './admin/invoices/invoices.module';
import { ChildModule } from './account/child/child.module';
import { PersonModule } from './admin/person/person.module';

export function tokenGetter() {
    return localStorage.getItem('id_token');
}

const appRoutes: Routes = [{ path: '',
    redirectTo: '/home',
    pathMatch: 'full'
}];

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
            }
        }),

        // RouterModule.forRoot(appRoutes, { enableTracing: process.env.NODE_ENV === 'development' }),
        RouterModule.forRoot(appRoutes, { enableTracing: false }),
        MainModule,
        CalendarModule,
        DirectivesModule,
        AccountModule,
        AdminModule,
        ImageModule,
        UploadImageModule,
        SchoolModule,
        RunInvoiceModule,
        ExhibitModule,
        TeacherModule,
        ChatModule,
        ClassModule,
        ProgramsModule,
        AllFormsModule,
        AllPickupsModule,
        AllInvoicesModule,
        InvoicesModule,
        ChildModule,
        PersonModule,
    ],
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(appRef: ApplicationRef) {
        this.appRef = appRef;
    }

    hmrOnInit(store) {
        if(!store || !store.state) return;
        console.log('HMR store', store);
        console.log('store.state.data:', store.state.data);
        // inject AppStore here and update it
        // this.AppStore.update(store.state)
        if('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        Reflect.deleteProperty(store, 'state');
        Reflect.deleteProperty(store, 'restoreInputValues');
    }

    hmrOnDestroy(store) {
        var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = {data: 'yolo'};
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }

    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        Reflect.deleteProperty(store, 'disposeOldHosts');
        // anything you need done the component is removed
    }
}
