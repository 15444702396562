// @flow
import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';

type User = {
    name: string;
    email: string;
    password: string;
};

@Component({
    selector: 'login',
    template: require('./login.html'),
    styles: [require('./login.scss')]
})
export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = {login: undefined};
    error = {
        message: ""
    };
    submitted = false;
    AuthService;
    Router;


    static parameters = [AuthService, Router];
    constructor(_AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }

    login(form) {
        if (form.invalid) {
            this.error.message = "Please enter an email and password.";
            return
        }

        return this.AuthService.login({
            email: this.user.email,
            password: this.user.password
        }).then(() => {
            // Logged in, redirect to home
            this.Router.navigateByUrl('/home');
        }).catch(err => {
            console.log(err);
            this.error = err.error;
            // this.errors.login = err.json().message;
        });
    }
}
