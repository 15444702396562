import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'chat',
    template: require('./chat.html'),
    styles: [require('./chat.scss')],
})
export class ChatComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allChats = [];
    newChat = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    chatImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        this.getAllChatrooms();
        // this.generateChatRooms();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('chat');
    }

    generateChatRooms() {
        this.http.get('/api/classes/').subscribe((classes) => {
            classes.forEach(classElement => {
                var newChatRoom = {
                    name: classElement.name,
                    classid: classElement._id,
                    info: 'class generated',
                    active: true
                };
                this.http.post('/api/chatrooms/', newChatRoom).subscribe((chat) => {
                    console.log('created chat ' + chat.name);
                }); 
            });
        });
    }

    getAllChatrooms() {
        return this.http.get('/api/chatrooms/').subscribe((chat) => {
            // chat.forEach(chatroom => {
            //     this.http.delete('/api/chatrooms/' + chatroom._id).subscribe((chat) => {
            //     });
            // })
            

            this.allChats = chat;
            this.SocketService.syncUpdates('chat', this.allChats);
        });
    }

    deleteMessage(message, chatroom) {
        var messageFoundIndex = -1;
        console.log(chatroom);
        messageFoundIndex = chatroom.messages.map((messageElement) => messageElement._id).indexOf(message._id);
        if (messageFoundIndex != -1) {
            chatroom.messages.splice(messageFoundIndex, 1);
        }
        this.http.put('/api/chatrooms/' + chatroom._id, chatroom).subscribe((chat) => {
            this.getAllChatrooms();
        });
        this.http.delete('/api/messages/' + message._id).subscribe((message) => {
        });
    }

    saveChatroom(chatroom, message) {
        this.http.put('/api/chatrooms/' + chatroom._id, chatroom).subscribe((chat) => {
            this.clearout();
            this.getAllChatrooms();
            this.messageService('Chat has been ' + message + '.', 'Success!', 'success');
        });
    }

    editChat(chat) {
        this.pageSettings.modifying = true; 
        this.newChat = chat;
        this.newChat.chatroom = this.newChat._id ? this.newChat._id : '';
    }

    addChat() {
        if (this.newChat.message) {
            if (!this.newChat.chatroom) {
                var newChatRoom = {
                    name: this.newChat.chatroomname ? this.newChat.chatroomname : 'new chatroom',
                    info: 'user created',
                    active: true
                };
                this.http.post('/api/chatrooms/', newChatRoom).subscribe((chat) => {
                    this.newChat.chatroom = chat._id;
                    var thisMessage = {
                        message: this.newChat.message,
                        chatroom: this.newChat.chatroom, 
                        from: this.AuthService.getCurrentUserSync().name,
                        fromid: this.AuthService.getCurrentUserSync()._id,
                    };
                    this.http.post('/api/messages/', thisMessage).subscribe(message => {
                        chat.messages.push(message);
                        this.saveChatroom(chat, 'added');
                    });
                });
            } else {
                var chatroomFoundIndex = -1;
                chatroomFoundIndex = this.allChats.map((chatroomElement) => chatroomElement._id).indexOf(this.newChat.chatroom);
                if (chatroomFoundIndex != -1) {
                    var thisChatroom = this.allChats[chatroomFoundIndex];
                    var thisMessage = {
                        message: thisChatroom.message,
                        chatroom: thisChatroom.chatroom, 
                        from: this.AuthService.getCurrentUserSync().name,
                        fromid: this.AuthService.getCurrentUserSync()._id,
                    };
                    this.http.post('/api/messages/', thisMessage).subscribe(message => {
                        thisChatroom.messages.push(message);
                        this.saveChatroom(thisChatroom, 'added');
                    });
                }
            }
        } else {
            console.log('Message is required');
        }
    }

    deleteChat(chat) {
        return this.http.delete(`/api/chatrooms/${chat._id}`).subscribe(() => {
            this.getAllChatrooms();
            this.messageService('The chat has been deleted.', 'Success!', 'success');
        });
    }

    confirmDelete(chat) {
        this.messageService('Are you sure you want to delete this chat?', 'Are you sure?', 'delete', chat);
    }

    chooseImage(type) {
        this.imagetype = type;
        this.displayImages = !this.displayImages;
        this.getImages();
    }

    getImages() {
        return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync().person._id).subscribe((images) => {
            this.allImages = images;
            this.SocketService.syncUpdates('image', this.allImages);
        });
    }

    selectImage(image) {
        this.newChat.image = image;
        this.displayImages = false;
    }

    clearout() {
        this.newChat = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteChat(item);
                    // this.messageService('The chat has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
