/*eslint no-process-env:0*/

export const env = process.env.NODE_ENV;
export const port = process.env.PORT || 9000;
// List of user roles
export const userRoles = ['guest', 'user', 'admin'];
export const userTypes = ['none', 'student', 'parent', 'teacher', 'hr', 'all'];

export default {
    env,
    port,
    userRoles,
    userTypes
};
