import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAge',
  pure: false
})

export class GetAge implements PipeTransform {

    constructor() {}

    transform(birthday: any, args?: any): any {
        if (args === 'getAge') {
            return this.getAge(birthday);
        } else {
            return birthday;
        }
    }

    getAge(birthday) {
        var today = new Date();
        var birthDate = new Date(birthday);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
  
}
