import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNormalTime',
  pure: false
})

export class GetNormalTime implements PipeTransform {

    constructor() {}

    transform(time: any, args?: any): any {
        if (args === 'getNormalTime') {
            return this.getNormalTime(time);
        } else {
            return time;
        }
    }

    getNormalTime(time) {
        if (time) {
            var hours = time.substring(0, time.indexOf(':'));
            var mins = time.substring(time.length, time.indexOf(':') + 1);
            var suffix = (hours >= 12) ? 'PM' : 'AM';
            hours = (hours > 12) ? hours -12 : hours;
            hours = (hours == '00') ? 12 : hours;
            var time = hours + ':' + mins + ' ' + suffix;
            return time;
        } else {
            return time;
        }
    }
}
