import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'getSafeUrl',
  pure: false
})

export class GetSafeUrl implements PipeTransform {

    // constructor(private sanitizer: DomSanitizer) {}
    constructor() {}

    transform(url: any, type: string): any {
        return this.getSafeUrl(url, type);
    }

    getSafeUrl(url, type) {
        switch (type) {
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(url);
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(url);
            case 'script': return this.sanitizer.bypassSecurityTrustScript(url);
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(url);
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(url);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
  
}