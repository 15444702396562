import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTimeLength',
  pure: false
})

export class GetTimeLength implements PipeTransform {

    constructor() {}

    transform(time: any, args?: any, endtime?: any): any {
        if (args === 'getTimeLength') {
            return this.getTimeLength(time, endtime);
        } else {
            return time;
        }
    }

    getTimeLength(time, endtime) {
        if (time && endtime) {
            var time_start = new Date();
            var time_end = new Date();
            var value_start = time.split(':');
            var value_end = endtime.split(':');
            time_start.setHours(value_start[0], value_start[1], 0, 0);
            time_end.setHours(value_end[0], value_end[1], 0, 0);
            var millisecondTime = time_end - time_start;
            var ms = millisecondTime % 1000;
            millisecondTime = (millisecondTime - ms) / 1000;
            var secs = millisecondTime % 60;
            millisecondTime = (millisecondTime - secs) / 60;
            var mins = millisecondTime % 60;
            var hrs = (millisecondTime - mins) / 60;
            let viewHours = '';
            let viewMinutes = '';
            if (hrs <= 0) {
                viewHours = '';
            } else {
                if (hrs > 1) {
                    viewHours = hrs + 'Hours';
                } else {
                    viewHours = hrs + 'Hour';
                }
            }
            if (mins <= 0) {
                viewMinutes = '';
            } else {
                if (mins > 1) {
                    viewMinutes = mins + 'Mins';
                } else {
                    viewMinutes = mins + 'Min';
                }
            }
            if (viewHours && viewMinutes) {
                time = viewHours + ' & ' + viewMinutes;
            }
            if (viewHours && !viewMinutes) {
                time = viewHours;
            }
            if (!viewHours && viewMinutes) {
                time = viewMinutes;
            }
            if (!viewHours && !viewMinutes) {
                time = '';
            }
            return time;
        } else {
            return time;
        }
    }
  
}
